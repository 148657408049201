import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import BaseTabsLayout from '@/components/Shared/TabsLayout/BaseTabsLayout';
import { TabConfig } from '@/components/Shared/TabsLayout/typings';
import { ROUTES } from '@/shared/constants';
import AlarmsTable from '@/components/Fleet/AlarmsAndErrors/Alarms/AlarmsTable';
import ErrorsTable from '@/components/Fleet/AlarmsAndErrors/Errors/ErrorsTable';
import { useGetFleetQuery } from '@/redux/api/fleet/fleetApiSlice';
import React, { useCallback, useContext, useReducer } from 'react';
import { FiltersContext } from '../FiltersContext';
import { fleetAlarmsErrorsReducer } from '../utils';
import { useNavigate } from 'react-router';
import { getPath } from '@/shared/utils';
import useCulliganDialog from '@/hooks/useCulliganDialog';
import IssueDetails from '@/components/Shared/AlarmDialog/IssueDetails';
import { FleetOverview } from '../typings';
import { FleetItem } from '@culligan-iot/domain/culligan/api/device/fleet';

type Action =
  | { type: 'SET_DIALOG_OPEN'; payload: boolean }
  | { type: 'SET_SELECTED_ISSUE'; payload: FleetItem['alarms'][number] | FleetOverview.Error | null };

interface State {
  dialogOpen: boolean;
  selectedIssue: FleetItem['alarms'][number] | FleetOverview.Error | null;
}

const initialState: State = {
  dialogOpen: false,
  selectedIssue: null,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_DIALOG_OPEN':
      return { ...state, dialogOpen: action.payload };
    case 'SET_SELECTED_ISSUE':
      return { ...state, selectedIssue: action.payload };
    default:
      console.error('Invalid Panel Action');
      return state;
  }
};

const isErrorEntity = (
  issue: FleetItem['alarms'][number] | FleetOverview.Alarm | FleetOverview.Error
): issue is FleetOverview.Error => 'errorName' in issue;

const emptyDevices: ReadonlyArray<FleetItem> = [];

const AlarmsAndErrorsPanel = ({ pageSize = 5 }: { pageSize?: number }) => {
  const { t } = useTranslation();
  const filters = useContext(FiltersContext);
  const { isLoading, isFetching, isError, data } = useGetFleetQuery(filters);
  const [state, dispatch] = useReducer(reducer, initialState);

  const devices = data?.data ?? emptyDevices;

  const { alarms, errors } = devices.reduce(fleetAlarmsErrorsReducer, {
    alarms: [],
    errors: [],
  });
  const handleIssueDetailClick = useCallback(
    (id: string) => {
      const selectedIssue = alarms.find((a) => a.id === id) || errors.find((e) => e.id === id);
      dispatch({ type: 'SET_SELECTED_ISSUE', payload: selectedIssue ?? null });
      dispatch({ type: 'SET_DIALOG_OPEN', payload: true });
    },
    [alarms, errors]
  );

  const handleCloseDialog = useCallback(() => {
    dispatch({ type: 'SET_DIALOG_OPEN', payload: false });
  }, []);

  const { dialog } = useCulliganDialog({
    open: state.dialogOpen,
    handleClose: handleCloseDialog,
    tabs: [
      {
        id: 'issue',
        label: t(state.selectedIssue ? (isErrorEntity(state.selectedIssue) ? 'error' : 'alarm') : 'issue'),
        body: state.selectedIssue && <IssueDetails data={state.selectedIssue} isFleet={true} />,
      },
    ],
    styles: {
      bodyContainer: { p: 0, width: '25rem' },
    },
  });
  const renderDialog = useCallback(() => dialog, [dialog]);

  const navigate = useNavigate();
  const handleNavigateAlarmsAndErrors = useCallback(() => navigate(getPath('FLEET_ALARMS_ERRORS')), [navigate]);

  const action = useCallback(
    () => (
      <Button variant="text" onClick={handleNavigateAlarmsAndErrors}>
        {t('seeAll')}
      </Button>
    ),
    [handleNavigateAlarmsAndErrors, t]
  );

  const tabsConfig = [
    {
      id: ROUTES.ONETOOL_CHANNELS_ALARMS.fragment,
      label: t(ROUTES.ONETOOL_CHANNELS_ALARMS.fragment),
      ariaControls: true,
      useHash: true,
      ContentComponent: (
        <AlarmsTable
          items={alarms}
          isError={isError}
          kind={'overview'}
          isLoading={isLoading || isFetching}
          onDetailClick={handleIssueDetailClick}
          slots={{
            dialog: renderDialog,
          }}
          options={{
            pageSize: pageSize,
            defaultOrderByCollection: [
              {
                orderBy: 1,
                orderDirection: 'desc',
                sortOrder: 1,
              },
            ],
          }}
        />
      ),
    },
    {
      id: 'errors',
      label: t('errors'),
      ariaControls: true,
      useHash: true,
      ContentComponent: (
        <ErrorsTable
          items={errors}
          kind="overview"
          onDetailClick={handleIssueDetailClick}
          isLoading={isLoading || isFetching}
          slots={{
            dialog: renderDialog,
          }}
          options={{
            pageSize: pageSize,
            defaultOrderByCollection: [
              {
                orderBy: 0,
                orderDirection: 'desc',
                sortOrder: 1,
              },
            ],
          }}
        />
      ),
    },
  ] satisfies TabConfig[];

  return (
    <Box>
      <BaseTabsLayout config={tabsConfig} slots={{ action }} />
    </Box>
  );
};

export default React.memo(AlarmsAndErrorsPanel);
