import { ApiResult } from '@typings';
import apiSlice, { API_VERSION } from '../apiSlice';
import { ConsumableRes } from '@/components/OneTools/Channels/Consumables/typings';
import { CommonIsEntityUniqueBase, CommonIsEntityUniqueRes } from '@/components/OneTools/typings';

const URL_PREFIX = `${API_VERSION.v2}/system/consumables`;

export const ConsumablesApiSlice = apiSlice.enhanceEndpoints({ addTagTypes: ['Consumables'] }).injectEndpoints({
  endpoints: (builder) => ({
    getConsumables: builder.query<ApiResult<{ items: ConsumableRes[] }>, void>({
      query: () => URL_PREFIX + '/list',
      providesTags: ['Consumables'],
    }),
    getConsumable: builder.query<ApiResult<ConsumableRes>, string>({
      query: (id: string) => ({
        url: URL_PREFIX,
        params: { id },
      }),
      providesTags: ['Consumables'],
    }),
    isConsumableUnique: builder.query<ApiResult<CommonIsEntityUniqueRes>, CommonIsEntityUniqueBase>({
      query: (params: CommonIsEntityUniqueBase) => ({
        url: `${URL_PREFIX}/unique`,
        params,
      }),
    }),
  }),
  overrideExisting: 'throw',
});

export const {
  useLazyGetConsumablesQuery,
  useGetConsumablesQuery,
  useLazyGetConsumableQuery,
  useLazyIsConsumableUniqueQuery,
} = ConsumablesApiSlice;
