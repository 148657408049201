import useLoyaltyProgramsColumns from '@/components/OneTools/Channels/LoyaltyPrograms/useLoyaltyProgramsColumns';
import GenericExportTable from '@/components/Shared/Tables/GenericExportTable';
import useConfirmDialog from '@/hooks/useConfirmDialog';
import { useUpdateDeviceLoyaltyProgramMutation } from '@/redux/api/fleet/devicesApiSlice';
import { Device } from '@culligan-iot/domain/culligan/device/class/index';

import { LoyaltyProgram } from '@culligan-iot/domain/culligan/user/loyalty';
import { MaterialTableProps } from '@material-table/core';
import { t } from 'i18next';
import { Fragment } from 'react';

export default function LoyaltyProgramTable({
  items = [],
  isFleet,
  excludeColumns,
  id,
  ...props
}: { items?: LoyaltyProgram[]; isFleet: boolean; excludeColumns?: string[]; id: Device['id'] } & Omit<
  MaterialTableProps<any>,
  'columns' | 'data'
>) {
  const [updateLoyaltyProgram] = useUpdateDeviceLoyaltyProgramMutation();

  const handleDeleteLoyaltyProgram = (id: string) => {
    const updatedLoyaltyPrograms = items.reduce<string[]>((acc, cur) => {
      if (cur.id !== id) {
        acc.push(cur.id);
      }
      return acc;
    }, []);
    updateLoyaltyProgram({ serialNumber: id, loyaltyPrograms: updatedLoyaltyPrograms });
  };
  const { ConfirmDialog, confirm } = useConfirmDialog({
    title: t('delete') + ' ' + t('loyaltyProgram'),
    message: (extra: any) => t('deleteMessage', { entity: t('loyaltyProgram'), name: extra.name }) as string,
    onConfirm: (extra: any) => handleDeleteLoyaltyProgram(extra.id),
  });

  const columns = useLoyaltyProgramsColumns(excludeColumns, confirm);

  return (
    <Fragment>
      <GenericExportTable<LoyaltyProgram>
        {...props}
        title={t('loyalty_programs')}
        data={items}
        columns={columns}
        exportData={false}
        selection={false}
      />
      <ConfirmDialog />
    </Fragment>
  );
}
