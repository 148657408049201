import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useCallback, useContext, useMemo } from 'react';
import { MarkerInfo } from '@/components/Shared/Map/typings';
import { MARKER_TYPE } from '@/shared/constants';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import LoadingMap from './LoadingMap';
import CulliganMap from '@/components/Shared/Map';
import InfoPanel from '@/components/Shared/Map/InfoPanel';
import DeviceInfoPanelHeader from '@/components/Shared/Map/Device/InfoPanelHeader';
import { FiltersContext } from '../FiltersContext';

import DeviceMarker from '@/components/Shared/Map/Device/DeviceMarker';
import { pipe, Option, Number } from 'effect';
import { FleetItem } from '@culligan-iot/domain/culligan/api/device/fleet';
import { useGetFleetQuery } from '@/redux/api/fleet/fleetApiSlice';

const getQNumber = (FleetItem: FleetItem) => (FleetItem.vendor.code === 'qnc' ? FleetItem.vendor.qNumber : null);

const DevicesLocationMap = () => {
  const { t } = useTranslation();
  const filters = useContext(FiltersContext);
  const { isError, isLoading, data } = useGetFleetQuery(filters);
  const response = pipe(
    Option.fromNullable(data?.data),
    Option.getOrElse(() => [])
  );
  const devices = useMemo(() => response || [], [response]);
  const result = useMemo(
    () =>
      devices?.reduce(
        (
          acc: {
            devicesWithCoordinates: FleetItem[];
            devicesWithoutCoordinates: Omit<FleetItem, 'detectedLocation'>[];
          },
          device: FleetItem
        ) => {
          if (
            device.providedLocation &&
            Number.isNumber(device.providedLocation.lat) &&
            !isNaN(device.providedLocation.lat) &&
            Number.isNumber(device.providedLocation.lon) &&
            !isNaN(device.providedLocation.lon)
          ) {
            acc.devicesWithCoordinates.push(device);
            return acc;
          } else if (
            device.detectedLocation &&
            Number.isNumber(device.detectedLocation.lat) &&
            !isNaN(device.detectedLocation.lat) &&
            Number.isNumber(device.detectedLocation.lon) &&
            !isNaN(device.detectedLocation.lon) &&
            Number.isNumber(device.detectedLocation.accuracy) &&
            !isNaN(device.detectedLocation.accuracy)
          ) {
            acc.devicesWithCoordinates.push(device);
            return acc;
          } else {
            acc.devicesWithoutCoordinates.push(device);
            return acc;
          }
        },
        {
          devicesWithCoordinates: [],
          devicesWithoutCoordinates: [],
        }
      ),
    [devices]
  );

  const markers: MarkerInfo[] = useMemo(() => {
    return result?.devicesWithCoordinates?.map((fd: FleetItem) => ({
      /** Type guard isValidNumber asserted on reduction. */
      // FIXME - Better type of lat and lon, nulls should be excluded devicesWithCoordinates for coordinate fields
      position: fd.providedLocation
        ? {
            lat: fd.providedLocation.lat as number,
            lng: fd.providedLocation.lon as number,
            accuracy: undefined,
          }
        : {
            lat: fd.detectedLocation?.lat as number,
            lng: fd.detectedLocation?.lon as number,
            accuracy: fd.detectedLocation?.accuracy as number,
          },
      title: fd.constructor.Device.label || '',
      description: fd.id,
      type: MARKER_TYPE.DEVICE,
      deviceQNumber: getQNumber(fd),
      manufacturingSerialNumber: fd.manufacturingSerialNumber ?? null,
      extra: {
        serialNumber: fd.id,
        name: fd.constructor.Device.label,
        model: fd.model,
        id: fd.id,
        lat: fd.detectedLocation?.lat,
        lon: fd.detectedLocation?.lon,
        status: { errors: fd?.errors?.length || 0, alarms: fd?.alarms.length || 0 },
      },
    }));
  }, [result?.devicesWithCoordinates]);

  const decodeMarkerInfo = useCallback(
    (marker: MarkerInfo) => ({
      ...marker.position,
    }),
    []
  );

  const SafeMapBody = withErrorLoadingManagement(() => (
    <Box position="relative" height="400px" overflow="hidden" borderRadius={1}>
      <CulliganMap
        data={markers}
        decode={decodeMarkerInfo}
        createMarker={(props) => <DeviceMarker {...props} />}
        slots={{
          InfoPanel: (props) => (
            <InfoPanel
              {...props}
              slots={{
                Header: (props) => (
                  <DeviceInfoPanelHeader {...props} title={props.marker.title} subtitle={props.marker.description} />
                ),
              }}
            />
          ),
        }}
        noDataMessage={t('noDevicesFound') as string}
        id="devicesLocationMap"
      />
    </Box>
  ));

  return <SafeMapBody isError={isError} isLoading={isLoading} LoadingComponent={<LoadingMap />} />;
};

export default DevicesLocationMap;
