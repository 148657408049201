import {
  AutocompleteRenderOptionState,
  Box,
  Button,
  Dialog,
  DialogTitle,
  Paper,
  Skeleton,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StoreFilterConfig, StoreFilterOption } from '@/components/Shared/Filters/typings';
import { lazy, Suspense, useCallback, useMemo, useRef, useState } from 'react';
import { APP_ROUTE_ID, KIND, ROUTES, Kind } from '@/shared/constants';
import { ShoppingCartCheckout } from '@mui/icons-material';
import ConsumablesShoppingDialog from '@/components/Fleet/Consumables/ConsumablesPanel/ConsumableShoppingDialog';
import NavigationTabsLayout from '@/components/Shared/TabsLayout/NavigationTabsLayout';
import { useGetConsumablesFiltersQuery } from '@/redux/api/fleet/devicesWithConsumablesApiSlice';
import { TabConfig } from '@/components/Shared/TabsLayout/typings';
import { Outlet, useNavigate } from 'react-router';
import useCurrentTab from '@/hooks/useCurrentTab';
import { useQueryStringFilters } from '@/hooks/useQueryStringFilters';
import { FleetConsumables } from '@/components/Fleet/Consumables/ConsumablesPanel/typings';
import { getPath, toZeroBasedIndex, wrapInRange } from '@/shared/utils';
import {
  useLazyGetUsersQuery,
  UsersFilterRequest,
  UsersFilterResponse,
} from '@/redux/api/businessAnalysis/dispensingStatisticsApiSlice';
import { fleetApiSlice } from '@/redux/api/fleet/fleetApiSlice';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { capitalize } from 'effect/String';
import { pipe, Option } from 'effect';
const StoreFilters = lazy(() => import('@/components/Shared/Filters/StoreFilters'));

const Consumables = () => {
  const { t } = useTranslation();
  const tableRef = useRef<FleetConsumables.Table>();

  const { getQueryFilters, setQueryFilters, upsertQueryFilters, deleteQueryFilters } = useQueryStringFilters<
    FleetConsumables.DecodedFilters,
    FleetConsumables.EncodedFilters
  >({
    encode: FleetConsumables.encodeFilters,
    decode: FleetConsumables.decodeFilters,
  });

  const [noDeviceWithLowConsumables, setNoDeviceWithLowConsumables] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<Kind>(FleetConsumables.INITIAL_KIND);
  const [devices, setDevices] = useState<FleetConsumables.Device[]>([]);
  const [selectedDevices, setSelectedDevices] = useState<FleetConsumables.Device[]>([]);

  const queryFilters = getQueryFilters();
  const {
    data: filters,
    isLoading: isLoadingFilters,
    isFetching: isPendingFilters,
  } = useGetConsumablesFiltersQuery(Object.values(FleetConsumables.DeviceFieldKeys));

  const navigate = useNavigate();

  const maybeModel = useMemo(
    () =>
      queryFilters?.model
        ? filters?.data?.identities
            ?.filter((identity) => identity.id === queryFilters?.model)
            .map((identity) => ({ label: `${identity.name} (${identity.id})`, optionId: identity.id }))
            .at(0)
        : undefined,
    [filters?.data?.identities, queryFilters?.model]
  );

  const maybeBrand = useMemo(
    () =>
      queryFilters?.brand
        ? filters?.data?.brands
            ?.filter((b) => b.id === queryFilters?.brand)
            .map((b) => ({ label: b.name, optionId: b.id }))
            .at(0)
        : undefined,
    [filters?.data?.brands, queryFilters?.brand]
  );

  const resetTable = useCallback(() => {
    tableRef?.current?.onQueryChange({
      page: toZeroBasedIndex(FleetConsumables.INITIAL_PAGE),
      pageSize: FleetConsumables.INITIAL_PAGE_SIZE,
    });
  }, []);

  const handleApplyFilters = useCallback(
    (filters: Map<FleetConsumables.FilterKey, string>) => {
      setQueryFilters([
        ...Array.from(filters, ([key, value]) => ({ key, value })),
        { key: FleetConsumables.DeviceFilterKeys.Page, value: FleetConsumables.INITIAL_PAGE },
        { key: FleetConsumables.DeviceFilterKeys.Size, value: FleetConsumables.INITIAL_PAGE_SIZE },
      ]);

      resetTable();
    },
    [setQueryFilters, resetTable]
  );

  const handleClearQueryFilters = useCallback(() => {
    const toClear = new Set(Object.values(FleetConsumables.DeviceFilterKeys));

    const toKeep = new Set([
      FleetConsumables.DeviceFilterKeys.Page,
      FleetConsumables.DeviceFilterKeys.Size,
      FleetConsumables.DeviceFilterKeys.OrderBy,
      FleetConsumables.DeviceFilterKeys.Direction,
    ]);

    deleteQueryFilters(Array.from(toClear.difference(toKeep)));
  }, [deleteQueryFilters]);

  const extractAutocompleteQueryParams = (query: string) => ({ query } as UsersFilterRequest);
  const [trigger] = fleetApiSlice.endpoints.getFleetCustomer.useLazyQuery();

  const fetchInitialCustomer = useCallback(async () => {
    if (!queryFilters.customer) {
      return;
    }

    const { customer } = await trigger({ customerId: queryFilters.customer }).unwrap();

    return pipe(
      customer,
      Option.fromNullable,
      Option.match({
        onNone: () => ({ label: '', optionId: '' }),
        onSome: (customer) => ({
          label: `${customer.firstName} ${customer.lastName}`,
          optionId: customer.id,
        }),
      })
    );
  }, [queryFilters.customer, trigger]);

  const shouldStartCustomerQuery = useCallback((query: string) => query.length >= 3 && /[a-zA-Z]/g.test(query), []);
  const handleParseCustomerReponse = useCallback(
    (data: { items: UsersFilterResponse[] }) =>
      data?.items?.map((item) => {
        const toSearchForMatches = data?.items.filter((user) => user.id !== item.id);
        const hasHomonym =
          toSearchForMatches?.length > 0 &&
          toSearchForMatches.findIndex(
            (user) =>
              user?.firstName?.toLowerCase() === item?.firstName?.toLowerCase() &&
              user?.lastName?.toLowerCase() === item?.lastName?.toLowerCase()
          ) !== -1;
        return { ...item, optionId: item?.id, label: `${item?.firstName} ${item?.lastName}`, hasHomonym };
      }),
    []
  );

  const renderAutocompleteCustomerOption = useCallback(
    (
      props: React.HTMLAttributes<HTMLLIElement>,
      option: StoreFilterOption & { email?: string | undefined; hasHomonym?: boolean | undefined },
      state: AutocompleteRenderOptionState
    ) => {
      const matches = match(option.label, state.inputValue, { insideWords: true });
      const labelWithQueryHighlight = parse(option.label, matches);

      return (
        <li {...props} key={option?.optionId}>
          <Box alignItems="center">
            <Box sx={{ wordWrap: 'break-word' }}>
              {labelWithQueryHighlight.map((part, index) => (
                <Box
                  key={`${index}-${part.text}`}
                  component="span"
                  sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                >
                  {part.text}
                </Box>
              ))}
              {option?.hasHomonym && (
                <Typography variant="body2" color="text.secondary">
                  {option?.['email']}
                </Typography>
              )}
            </Box>
          </Box>
        </li>
      );
    },
    []
  );

  const filterConfigs: StoreFilterConfig[] = useMemo(
    () => [
      {
        kind: 'autocomplete',
        id: FleetConsumables.DeviceFilterKeys.Model,
        label: t('deviceName'),
        defaultValue: maybeModel,
        options:
          filters?.data?.identities?.map((identity) => ({
            optionId: identity.id,
            value: identity.id,
            label: `${identity.name} (${identity.id})`,
          })) || [],
      },
      {
        kind: 'autocomplete',
        id: FleetConsumables.DeviceFilterKeys.Brand,
        label: t('businessUnit'),
        defaultValue: maybeBrand,
        options:
          filters?.data?.brands?.map((brand) => ({
            label: brand.name,
            value: brand.id,
            optionId: brand.id,
          })) || [],
      },
      {
        kind: 'asyncAutocomplete',
        id: 'customer',
        label: t('customer'),
        getInitialValue: fetchInitialCustomer,
        ...(filters?.data?.customer
          ? {
              defaultValue: {
                optionId: filters?.data?.customer.id,
                label: `${filters?.data?.customer?.firstName} ${filters?.data?.customer?.lastName}`,
              },
            }
          : {}),
        shouldFetch: shouldStartCustomerQuery,
        transformFn: handleParseCustomerReponse,
        lazyQueryHook: useLazyGetUsersQuery,
        getQueryParam: extractAutocompleteQueryParams,
        debounceTime: FleetConsumables.DEBOUNCE_TIME,
        renderOption: renderAutocompleteCustomerOption,
      },
      {
        kind: 'autocomplete',
        id: FleetConsumables.DeviceFilterKeys.Level,
        label: t('fillLevel'),
        defaultValue: queryFilters?.level && {
          label: t(`fillLevel${capitalize(queryFilters.level)}`),
          optionId: queryFilters.level,
        },
        options: FleetConsumables.LevelNames.literals.map((l) => ({
          optionId: l,
          label: t(`fillLevel${capitalize(l)}`),
        })),
      },
    ],
    [
      fetchInitialCustomer,
      filters?.data?.brands,
      filters?.data?.customer,
      filters?.data?.identities,
      handleParseCustomerReponse,
      maybeBrand,
      maybeModel,
      queryFilters?.level,
      renderAutocompleteCustomerOption,
      shouldStartCustomerQuery,
      t,
    ]
  );

  const FiltersSkeleton = useMemo(
    () => (
      <Paper sx={{ padding: 2, marginBottom: '12px', display: 'flex', gap: '12px', height: 150 }}>
        {[...Array(filterConfigs.length)].map((_, index) => (
          <Skeleton key={index} variant="rounded" width="25%" height={50} />
        ))}
      </Paper>
    ),
    [filterConfigs.length]
  );

  const [shoppingDialogDetails, setShoppingDialogDetails] = useState<FleetConsumables.ShoppingDialogDetails>({
    devices: [],
    consumableType: 'all',
  });

  const handleFillLowItemsByAllTypes = () => {
    const devicesWithLowConsumablesFilterFn = ({ consumables }: FleetConsumables.Device) =>
      consumables.some(
        (consumable: any) =>
          wrapInRange(consumable.current, consumable.subset?.rangeExhaust || 0, consumable.subset?.rangeFullCapacity) <=
          20
      );

    const devicesWithLowConsumables = devices.filter(devicesWithLowConsumablesFilterFn);
    const selectedDevicesWithLowConsumables = selectedDevices.filter(devicesWithLowConsumablesFilterFn);

    if (selectedDevices.length > 0) {
      setShoppingDialogDetails({
        devices: selectedDevicesWithLowConsumables,
        consumableType: activeTab,
      });
    } else {
      setShoppingDialogDetails({
        devices: devicesWithLowConsumables,
        consumableType: 'all',
      });
    }

    setNoDeviceWithLowConsumables(!devicesWithLowConsumables.length);
  };

  const onNavigateFactory = useCallback(
    (kind: Kind) => () => {
      setSelectedDevices([]);
      setActiveTab(kind);
      resetTable();

      const params = Object.entries(queryFilters)
        .filter(
          ([key]) =>
            key !== FleetConsumables.TablePaginationKeys.Page && key !== FleetConsumables.TablePaginationKeys.Size
        )
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&');

      navigate(
        [
          `${getPath(`FLEET_CONSUMABLES_${kind.toUpperCase()}` as APP_ROUTE_ID)}?${params}`,
          `${FleetConsumables.TablePaginationKeys.Size}=${FleetConsumables.INITIAL_PAGE_SIZE}`,
          `${FleetConsumables.TablePaginationKeys.Page}=${FleetConsumables.INITIAL_PAGE}`,
        ].join('&'),
        { preventScrollReset: true }
      );
    },
    [navigate, queryFilters, resetTable]
  );

  const tabsConfig = useMemo<TabConfig[]>(
    () =>
      Object.values(KIND.enums).map((kind) => ({
        id: kind,
        label: t(kind),
        ariaControls: true,
        useHash: false,
        onNavigate: onNavigateFactory(kind),
      })),
    [onNavigateFactory, t]
  );

  const { index } = useCurrentTab(tabsConfig);

  return (
    <Box>
      {index !== 0 && (
        <Suspense fallback={FiltersSkeleton}>
          {isLoadingFilters || isPendingFilters ? (
            FiltersSkeleton
          ) : (
            <StoreFilters
              filterConfigs={filterConfigs}
              onFiltersApplied={handleApplyFilters}
              onFiltersCleared={handleClearQueryFilters}
            />
          )}
        </Suspense>
      )}

      <Box display="flex" justifyContent="space-between" mb={2}>
        <Typography variant="h4" marginBottom={'1rem'}>
          {t(ROUTES.ONETOOL_CHANNELS_CONSUMABLES.fragment)}
        </Typography>
        <Button
          sx={{ alignSelf: 'center' }}
          variant="contained"
          startIcon={<ShoppingCartCheckout />}
          onClick={() => handleFillLowItemsByAllTypes()}
        >
          {t('fillLowItems')}
        </Button>
      </Box>

      {index !== 0 && <NavigationTabsLayout config={tabsConfig} defaultIndex={index - 1} />}

      <Outlet
        context={{
          filters: queryFilters,
          upsertQueryFilters,
          deleteQueryFilters,
          resetTable,
          tableRef,
          setDevices,
          setSelectedDevices,
        }}
      />

      {shoppingDialogDetails.devices.length > 0 && (
        <ConsumablesShoppingDialog
          devices={shoppingDialogDetails.devices}
          onClose={() => setShoppingDialogDetails({ devices: [], consumableType: 'all' })}
          consumableType={shoppingDialogDetails.consumableType}
        />
      )}

      <Dialog open={noDeviceWithLowConsumables} onClose={() => setNoDeviceWithLowConsumables(false)}>
        <DialogTitle>No devices with consumables levels lower than 20</DialogTitle>
      </Dialog>
    </Box>
  );
};

export default Consumables;
