import DevicePanel from '@/components/Fleet/Devices/DevicePanel';
import { useContext } from 'react';
import { DeviceContext } from './DeviceContext';
import { useGetDeviceConnectionHistoryQuery } from '@/redux/api/fleet/devicesApiSlice';
import { useGetUserQuery } from '@/redux/api/usersApiSlice';

export default function Overview() {
  const context = useContext(DeviceContext);
  const connectionHistory = useGetDeviceConnectionHistoryQuery(context?.data?.id || '', { skip: !context?.data });
  const userResult = useGetUserQuery(context?.data?.ownerId || '', {
    skip: !context?.data,
  });

  if (!context) {
    return <></>;
  }

  const { data, isError, isLoading, error } = context;

  return (
    <DevicePanel
      device={data}
      customer={userResult.data?.data}
      connectionHistory={connectionHistory.data?.data?.items || []}
      isLoading={isLoading}
      isError={isError}
      error={error}
    />
  );
}
