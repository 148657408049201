/* eslint-disable @typescript-eslint/no-redeclare */
/* eslint-disable no-redeclare */

import { StatusT } from '@typings';
import { isNotEmptyList } from '@/shared/validations';
import apiSlice, { API_VERSION } from '../apiSlice';
import { Either, Schema as S } from 'effect';
import { queryStringOfList } from '@/shared/utils';
import { FleetItem } from '@culligan-iot/domain/culligan/api/device/fleet';

const FilterKeys = {
  brands: 'brands',
  models: 'models',
  status: 'status',
  levels: 'levels',
} as const;
type FilterKey = (typeof FilterKeys)[keyof typeof FilterKeys];

const FilterData = S.Union(
  S.TaggedStruct(FilterKeys.brands, { brands: S.Array(S.Struct({ id: S.String, name: S.String })) }),
  S.TaggedStruct(FilterKeys.models, { models: S.Array(S.Struct({ id: S.String, name: S.String })) }),
  S.TaggedStruct(FilterKeys.status, { status: S.Array(S.Literal('connected', 'disconnected')) }),
  S.TaggedStruct(FilterKeys.levels, {
    levels: S.Array(S.Literal('lessThan20', '20To40', '40To60', '60To80', 'moreThan80')),
  })
);

export const GetFiltersResponse = S.Struct({
  success: S.Boolean,
  data: S.Array(FilterData),
});

export const FleetCustomer = S.Struct({
  customer: S.Struct({
    id: S.String,
    firstName: S.String,
    lastName: S.String,
  }),
});

const GetFleetResponse = S.Struct({
  success: S.Boolean,
  data: S.Array(FleetItem),
});

type GetFiltersParams = { keys: Array<FilterKey> };
type GetCustomerParams = { customerId: string };

const decodeApiFleetApiResponse = S.decodeUnknownEither(GetFleetResponse, { errors: 'all' });
const decodeGetFiltersResponse = S.decodeUnknownEither(GetFiltersResponse, { errors: 'all' });
const decodeFleetCustomer = S.decodeUnknownEither(FleetCustomer, { errors: 'all' });

type FleetOverviewParams = {
  customer?: string;
  model?: string;
  status?: StatusT;
  brand?: string;
};

export const fleetApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFleet: builder.query<typeof GetFleetResponse.Type, FleetOverviewParams>({
      query: (params) => {
        const url = `${API_VERSION.v2}/fleet`;
        if (params && Object.keys(params).length > 0) {
          return {
            url,
            params,
          };
        }
        return url;
      },
      transformResponse: (response) => {
        return decodeApiFleetApiResponse(response).pipe(Either.getOrThrowWith((error) => error));
      },
    }),
    getFleetFilters: builder.query<typeof GetFiltersResponse.Type, GetFiltersParams>({
      query: (params) => {
        const list = params?.keys;
        if (isNotEmptyList(list)) {
          return { url: `${API_VERSION.v2}/fleet/filters?${queryStringOfList('keys')(list)}` };
        }
        return { url: `${API_VERSION.v2}/fleet/filters` };
      },
      transformResponse: (response) => {
        return decodeGetFiltersResponse(response).pipe(Either.getOrThrowWith((error) => error));
      },
    }),
    getFleetCustomer: builder.query<typeof FleetCustomer.Type, GetCustomerParams>({
      query: (params) => {
        return {
          url: `${API_VERSION.v2}/fleet/customer`,
          params,
        };
      },
      transformResponse: (response) => {
        return decodeFleetCustomer(response).pipe(Either.getOrThrowWith((error) => error));
      },
    }),
  }),
  overrideExisting: 'throw',
});

export const { useGetFleetQuery, useGetFleetCustomerQuery, useGetFleetFiltersQuery } = fleetApiSlice;
