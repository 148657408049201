import { parseDecodedTelemetries } from '@/shared/domain/telemetry';
import {
  Telemetry,
  NumberTelemetry,
  Sensor,
  BooleanTelemetry,
} from '@culligan-iot/domain/culligan/device/capability/telemetry';
import { Device } from '@culligan-iot/domain/culligan/device/class/index';
import { Schema } from 'effect';

export type SensorWithTelemetry = ReturnType<typeof parseDecodedTelemetries>[number];

export type SensorWithNumberTelemetry = SensorWithTelemetry & {
  sensor: Extract<Sensor, { _tag: 'Number' }>;
  telemetry?: NumberTelemetry;
};
export type SensorWithBooleanTelemetry = SensorWithTelemetry & {
  sensor: Extract<Sensor, { _tag: 'Boolean' }>;
  telemetry?: BooleanTelemetry;
};

export type DeviceTelemetries = {
  lastUpdate?: number;
  sensorsWithTelemetry?: SensorWithTelemetry[];
};

export const isBooleanTelemetry = Schema.is(BooleanTelemetry);
export const isNumberTelemetry = Schema.is(NumberTelemetry);

export type DeviceHistoryTelemetryRequest = {
  model: Device['model'];
  dateRangeEnd: number;
  dateRangeStart: number;
  deviceId: Device['id'];
  keys: string[]; // TODO: Enforce a more restrictive type.
};

export type HistoryTelemetry = {
  lastUpdate: number;
} & {
  [key in string]?: { value: Telemetry['value'] };
};

export const ITEM_TYPE = {
  SINGLE: 'single',
  GROUP: 'group',
} as const;

export type SensorWithTelemetryGroup = {
  tag: Telemetry['constructor']['tags'][number];
  items: SensorWithTelemetry[];
};

export type SingleMergedSensorWithTelemetry = {
  id: string;
  name: string;
  type: 'single';
  sensor: Sensor;
  telemetry?: Telemetry;
  items: undefined;
};

export type MergedSensorWithTelemetryGroup = {
  id: string | undefined;
  name: string | undefined;
  sensor: undefined;
  telemetry: undefined;
  type: 'group';
  items?: SingleMergedSensorWithTelemetry[];
};

export type MergedTelemetry = SingleMergedSensorWithTelemetry | MergedSensorWithTelemetryGroup;
