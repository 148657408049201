import Area, { AREA_DESIGN_TOKENS, AreaBody, AreaHeader, DataBoundAreaRow } from '@/components/Shared/Card/Area';
import { Subtitle, Title } from '@/components/Shared/Card/Area/Text';
import { LogLevelCommandPayload } from '@/redux/api/admin/deviceCommandsApiSlice';

import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LogLevel as LogLevelT } from '@culligan-iot/domain/culligan/device/class/base';
import AreaButton from '@/components/Shared/Card/Area/AreaButton';
import TypographyWithTooltip from '@/components/Shared/Tooltip';
import { Device } from '@culligan-iot/domain/culligan/device/class/index';
import { useLogLevels } from './useLogLevel';
function LogLevel({
  device,
  postLogLevel,
  isLoadingLogLevel,
  isInLoadingState,
  requestLogLevel,
}: {
  device: Device;
  postLogLevel: MutationTrigger<LogLevelCommandPayload & any>;
  isLoadingLogLevel: boolean;
  isPollingLogLevel: boolean;
  isInLoadingState: boolean;
  requestLogLevel?: LogLevelT;
}) {
  const { t } = useTranslation();
  const availableLogLevels = useLogLevels(device);
  const { internalActionGap } = AREA_DESIGN_TOKENS;

  return (
    <DataBoundAreaRow
      sx={{ display: 'grid', gridTemplate: 'auto / repeat(6, 1fr)', gap: internalActionGap, width: '100%' }}
    >
      <Area
        sx={{
          width: '100%',
          gridColumnStart: '1',
          gridColumnEnd: 7 - availableLogLevels.length,
          borderRadius: 1,
        }}
      >
        <AreaHeader>
          <TypographyWithTooltip
            anchorText={t('logLevel')}
            slots={{
              AnchorTextTypography: Subtitle,
            }}
            tooltipText={t('logLevelTooltip')}
          />
        </AreaHeader>
        <AreaBody>
          <Title>{device.state.logLevel}</Title>
        </AreaBody>
      </Area>
      {availableLogLevels
        .filter((level) => level.value !== device.state.logLevel)
        .map((level) => (
          <AreaButton
            sx={{ borderRadius: 1 }}
            key={level.value}
            Icon={level.icon}
            color={level.color}
            isLoading={isLoadingLogLevel && requestLogLevel === level.value}
            disabled={[
              ...(level.disabled ? [{ disabled: level.disabled, reason: level.reason }] : []),
              { disabled: isInLoadingState, reason: t('pendingCommands') as string },
              { disabled: device.connectionStatus.online === false, reason: t('loglevelWhileOffline') as string },
            ]}
            handler={() =>
              postLogLevel({
                serialNumber: device.id,
                logLevel: level.value,
              })
            }
            text={level.label}
          />
        ))}
    </DataBoundAreaRow>
  );
}
export default React.memo(LogLevel);
