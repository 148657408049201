import { Box } from '@mui/material';
import { yellow } from '@mui/material/colors';
import React from 'react';

export default function ExpirationProgress({
  expirationPercentage,
  expirationPercentageAsString,
}: {
  expirationPercentage: number;
  expirationPercentageAsString: string;
}) {
  return (
    <Box
      sx={(theme) => ({
        background: `linear-gradient(90deg, ${theme.palette.success.light} 0%, ${yellow[500]} 51.5%,
		  ${theme.palette.warning.light} 75.5%, ${theme.palette.error.light} 100%)`,
        height: '10px',
        borderRadius: 3,
        marginTop: 1,
        position: 'relative',
        '&:after': {
          content: '""',
          backgroundColor: theme.palette.background.default,
          width: '21px',
          height: '21px',
          position: 'absolute',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          left: `clamp(0%, ${expirationPercentageAsString},100%)`,
          borderRadius: '100%',
          border: `1px solid hsl(${100 - (expirationPercentage || 0)} 61% 41% / 1)`,
          transition: 'all 300ms ease-in-out',
        },
      })}
    />
  );
}
