import { ApiResult } from '@typings';
import apiSlice, { API_VERSION } from '../apiSlice';
import { ConsumableSubsetReq, ConsumableSubsetRes } from '@/components/OneTools/Channels/ConsumableSubsets/typings';
import { CommonIsEntityUniqueBase, CommonIsEntityUniqueRes } from '@/components/OneTools/typings';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { dispatchPendingToOperationsSlice } from '../dispatchPendingToOperations';
import { ROUTES } from '@/shared/constants';

const URL_PREFIX = `${API_VERSION.v2}/system/consumableSubsets`;

export const ConsumableSubsetsApiSlice = apiSlice
  .enhanceEndpoints({ addTagTypes: ['ConsumableSubsets'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getConsumableSubsets: builder.query<ApiResult<{ items: ConsumableSubsetRes[] }>, void | null>({
        query: () => URL_PREFIX + '/list',
        providesTags: ['ConsumableSubsets'],
      }),
      getConsumableSubset: builder.query<ApiResult<ConsumableSubsetRes>, string>({
        query: (id: string) => ({
          url: URL_PREFIX,
          params: { id },
        }),
        providesTags: ['ConsumableSubsets'],
      }),
      isConsumableSubsetUnique: builder.query<ApiResult<CommonIsEntityUniqueRes>, CommonIsEntityUniqueBase>({
        query: (params: CommonIsEntityUniqueBase) => ({
          url: `${URL_PREFIX}/unique`,
          params,
        }),
      }),
      postConsumableSubset: builder.mutation<ApiResult<ConsumableSubsetRes>, ConsumableSubsetReq>({
        query: (body: ConsumableSubsetReq) => ({
          url: URL_PREFIX,
          method: 'POST',
          body: body,
        }),
        invalidatesTags: ['ConsumableSubsets'],
        onQueryStarted: (arg, api) => {
          dispatchPendingToOperationsSlice(api, {
            url_prefix: URL_PREFIX,
            method: 'POST',
            subject: arg.name,
            entity: ROUTES.ONETOOL_CHANNELS_CONSUMABLE_SUBSETS.fragment,
          });
        },
      }),
      putConsumableSubset: builder.mutation<ApiResult<ConsumableSubsetRes>, { id: string; body: ConsumableSubsetReq }>({
        query: ({ id, body }: { id: string; body: ConsumableSubsetReq }) => ({
          url: URL_PREFIX,
          method: 'PUT',
          body: body,
          params: { id },
        }),
        invalidatesTags: ['ConsumableSubsets'],
        onQueryStarted: (arg, api) => {
          dispatchPendingToOperationsSlice(api, {
            url_prefix: URL_PREFIX,
            method: 'PUT',
            subject: arg.body.name,
            entity: ROUTES.ONETOOL_CHANNELS_CONSUMABLE_SUBSETS.fragment,
          });
        },
      }),
      patchConsumableSubset: builder.mutation<
        ApiResult<ConsumableSubsetRes>,
        { id: string; body: Partial<ConsumableSubsetReq>; name: string }
      >({
        query: ({ id, body }) => ({
          url: URL_PREFIX,
          method: 'PATCH',
          body: body,
          params: { id },
        }),
        invalidatesTags: ['ConsumableSubsets'],
        onQueryStarted: (arg, api) => {
          dispatchPendingToOperationsSlice(api, {
            url_prefix: URL_PREFIX,
            method: 'PATCH',
            subject: arg.name,
            entity: ROUTES.ONETOOL_CHANNELS_CONSUMABLE_SUBSETS.fragment,
          });
        },
      }),
      deleteConsumableSubset: builder.mutation<ApiResult<null>, { id: string; name: string }>({
        query: ({ id }: { id: string; name: string }) => ({
          url: URL_PREFIX,
          method: 'DELETE',
          params: { id },
        }),
        invalidatesTags: ['ConsumableSubsets'],
        onQueryStarted: (arg, api) => {
          dispatchPendingToOperationsSlice(api, {
            url_prefix: URL_PREFIX,
            method: 'DELETE',
            subject: arg.name,
            entity: ROUTES.ONETOOL_CHANNELS_CONSUMABLE_SUBSETS.fragment,
          });
        },
      }),
      uploadCoverImage: builder.mutation<{ success: true; error: null }, FormData>({
        async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
          const mime = _arg.get('mime') as string;
          const ext = mime.split('/')[1];
          const signedURLResponse = await fetchWithBQ({
            url: URL_PREFIX + '/coverImage/uploadURL',
            params: {
              id: _arg.get('id'),
              ext,
            },
          });
          if (signedURLResponse.error) return { error: signedURLResponse.error as FetchBaseQueryError };
          let signedURL;
          if (signedURLResponse.data) {
            signedURL = (signedURLResponse.data as ApiResult<{ url: string }>).data?.url;
          }
          const result = await fetchWithBQ({
            url: signedURL || '',
            method: 'PUT',
            headers: {
              'Content-Type': mime,
              'x-ms-blob-type': 'BlockBlob', // mandatory for file upload in Azure
            },
            body: _arg.get('coverImage'),
          });
          return result.meta?.response?.status !== 200
            ? { data: { success: true, error: null } }
            : { error: result.error as FetchBaseQueryError };
        },
        invalidatesTags: ['ConsumableSubsets'],
      }),
    }),
    overrideExisting: 'throw',
  });

export const {
  useLazyGetConsumableSubsetsQuery,
  useGetConsumableSubsetsQuery,
  useLazyGetConsumableSubsetQuery,
  useLazyIsConsumableSubsetUniqueQuery,
  usePostConsumableSubsetMutation,
  usePutConsumableSubsetMutation,
  usePatchConsumableSubsetMutation,
  useDeleteConsumableSubsetMutation,
  useUploadCoverImageMutation,
} = ConsumableSubsetsApiSlice;
