import { isBooleanTelemetry, isNumberTelemetry, SensorWithTelemetry, SensorWithTelemetryGroup } from '../typings';
import { Box, Typography } from '@mui/material';
import TelemetryNumberCard from './TelemetryNumberCard';
import TelemetryBooleanCard from './TelemetryBooleanCard';
import Section from '@/components/Shared/Section';
import { useTranslation } from 'react-i18next';
import TelemetryGroupCard from './TelemetryGroupCard';
import { CulliganCard } from '@/components/Shared/Card';
import { Sensor } from '@culligan-iot/domain/culligan/device/capability/telemetry';

const getTelemetryCard = (sensorWithTelemetry: SensorWithTelemetry) =>
  Sensor.$match(sensorWithTelemetry.sensor, {
    Number: (numberSensor) => (
      <CulliganCard key={sensorWithTelemetry.id} sx={{ aspectRatio: '1/1', maxHeight: '300px' }}>
        <TelemetryNumberCard
          sensorWithTelemetry={{
            id: sensorWithTelemetry.id,
            sensor: numberSensor,
            telemetry: isNumberTelemetry(sensorWithTelemetry.telemetry) ? sensorWithTelemetry.telemetry : undefined,
          }}
        />
      </CulliganCard>
    ),
    Boolean: (booleanSensor) => (
      <CulliganCard key={sensorWithTelemetry.id} sx={{ aspectRatio: '1/1', maxHeight: '300px' }}>
        <TelemetryBooleanCard
          sensorWithTelemetry={{
            id: sensorWithTelemetry.id,
            sensor: booleanSensor,
            telemetry: isBooleanTelemetry(sensorWithTelemetry.telemetry) ? sensorWithTelemetry.telemetry : undefined,
          }}
        />
      </CulliganCard>
    ),
  });

export default function CurrentDataSection({
  sensorsWithoutGroup,
  sensorsGroups,
}: {
  sensorsWithoutGroup: SensorWithTelemetry[];
  sensorsGroups: SensorWithTelemetryGroup[];
}) {
  const { t } = useTranslation();

  return (
    <Section sx={{ '@media print': { display: 'none' } }}>
      <Typography variant="h5" mb={4}>
        {t('mostRecentData')}
      </Typography>
      <Box display="flex" flexDirection={'column'} gap={2}>
        <Box display="flex" gap={2} flexWrap={'wrap'}>
          {sensorsWithoutGroup.map(getTelemetryCard)}
        </Box>
        <Box display="flex" sx={{ flexDirection: { xs: 'column', md: 'row' }, flexWrap: 'wrap' }} gap={2}>
          {sensorsGroups?.length
            ? sensorsGroups?.map((group) => (
                <TelemetryGroupCard key={group.tag} title={group.tag} sensorGroup={group.items} />
              ))
            : null}
        </Box>
      </Box>
    </Section>
  );
}
