import { ApiResult } from '@typings';
import apiSlice, { API_VERSION } from '../apiSlice';
import {
  HistoryEventRequest,
  HistoryEventGroupedByDay,
} from '@/components/Fleet/Devices/DevicePanel/EventsPanel/typings';

export const EventsApiSlice = apiSlice.enhanceEndpoints({ addTagTypes: ['FleetEvents', 'Fleet'] }).injectEndpoints({
  endpoints: (builder) => ({
    getHistoryEvents: builder.query<ApiResult<{ items: HistoryEventGroupedByDay[] }>, HistoryEventRequest>({
      query: (params) => {
        const queryParams = new URLSearchParams(
          Object.keys(params).map((key) => [key, String(params[key as keyof typeof params])])
        );
        return {
          url: `${API_VERSION.v2}/fleet/events/?${queryParams.toString()}`,
        };
      },
      providesTags: ['FleetEvents'],
    }),
  }),
  overrideExisting: 'throw',
});

export const { useLazyGetHistoryEventsQuery } = EventsApiSlice;
