import { pipe } from 'effect';
import { FleetOverview } from './typings';
import { FleetItem } from '@culligan-iot/domain/culligan/api/device/fleet';
import { SeverityT } from '@typings';
import { isDate } from 'effect/Predicate';

export type FGInitialState = {
  withoutTelemetry: number;
  byIdentities: Map<FleetItem['model'], FleetOverview.DeviceSummary>;
  alerts: { none: number; priority: number; postponable: number };
};

export type FleetOverviewAlarm = FleetItem['alarms'][number] & {
  deviceId: FleetItem['id'];
  deviceName: string;
  severity: SeverityT;
};

export type AEInitialState = {
  alarms: FleetOverviewAlarm[];
  errors: FleetOverview.Error[];
};

export const _AEInitialState: AEInitialState = {
  alarms: [],
  errors: [],
};

export const buildFleetGeneralState = (): FGInitialState => ({
  withoutTelemetry: 0,
  byIdentities: new Map<FleetItem['model'], FleetOverview.DeviceSummary>(),
  alerts: { none: 0, priority: 0, postponable: 0 },
});

export const fleetGeneralReducer = (state: FGInitialState, device: FleetItem): FGInitialState =>
  pipe(
    { state, device },
    ({ state, device }) => withAlerts(state, device),
    ({ state, device }) => withIdentity(state, device),
    ({ state, device }) => withMissingTelemetry(state, device),
    ({ state }) => state
  );

export const fleetAlarmsErrorsReducer = (state: AEInitialState, device: FleetItem) =>
  pipe(
    { state, device },
    ({ state, device }) => withAlarms(state, device),
    ({ state, device }) => withErrors(state, device),
    ({ state }) => state
  );

const withErrors = (state: AEInitialState, device: FleetItem) => {
  if (device.errors && device.errors?.length) {
    const mappedErrors = device.errors
      .map((e) => {
        if (
          e &&
          typeof e === 'object' &&
          'errorName' in e &&
          typeof e.errorName === 'string' &&
          'id' in e &&
          typeof e.id === 'string' &&
          'time' in e &&
          isDate(e.time)
        ) {
          return {
            deviceId: device.id,
            deviceName: device.constructor.Device.label,
            name: e.errorName,
            id: e.id,
            time: e.time,
          };
        } else {
          return undefined;
        }
      })
      .filter((e) => e !== undefined);
    state.errors.push(...mappedErrors);
    return { state, device };
  }
  return { state, device };
};

const withAlarms = (state: AEInitialState, device: FleetItem) => {
  if (device.alarms && device.alarms?.length) {
    const mappedAlarms = device.alarms.map((a) => {
      return {
        ...a,
        deviceId: device.id,
        deviceName: device.constructor.Device.label,
        severity: a.constructor.severity,
      };
    });
    state.alarms.push(...mappedAlarms);
    return { state, device };
  }
  return { state, device };
};

const withAlerts = (state: FGInitialState, device: FleetItem) => {
  if (device.errors?.length) state.alerts.priority++;
  if (device.alarms?.length) state.alerts.postponable++;
  if (device.errors?.length === 0 && device.alarms?.length === 0) state.alerts.none++;
  return { state, device };
};

const withMissingTelemetry = (state: FGInitialState, device: FleetItem) => {
  if (!device.constructor.Device.sensors) state.withoutTelemetry++;
  return { state, device };
};

const withIdentity = (state: FGInitialState, device: FleetItem) => {
  const _model = state.byIdentities.get(device.model);
  if (_model) state.byIdentities.set(device.model, { ..._model, count: _model.count + 1 });
  else
    state.byIdentities.set(device.model, {
      name: device.constructor.Device.label,
      id: device.id,
      model: device.model,
      count: 1,
    });
  return { state, device };
};

export const sortDevices = (devices: FleetOverview.DeviceSummary[], sortBy: 'amount' | 'name', sortAsc: boolean) => {
  const compareNumbers = (a: number, b: number) => (sortAsc ? a - b : b - a);
  const compareStrings = (a: string, b: string) => (sortAsc ? a.localeCompare(b) : b.localeCompare(a));

  const sortFunctions = {
    amount: (a: FleetOverview.DeviceSummary, b: FleetOverview.DeviceSummary) => compareNumbers(a.count, b.count),
    name: (a: FleetOverview.DeviceSummary, b: FleetOverview.DeviceSummary) => compareStrings(a.name, b.name),
  };

  return [...devices].sort(sortFunctions[sortBy]);
};
