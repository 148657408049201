import Area, { AreaBody, AreaHeader } from '@/components/Shared/Card/Area';
import { Body, Subtitle } from '@/components/Shared/Card/Area/Text';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

export default function ConsumableExpiration({ expiresAt }: { expiresAt?: Date }) {
  const { t } = useTranslation();
  return (
    <Area sx={{ flexGrow: 1, height: 'auto' }}>
      <AreaHeader sx={{ zIndex: 1, position: 'relative' }}>
        <Subtitle>{t('expiration')}</Subtitle>
      </AreaHeader>
      <AreaBody sx={{ zIndex: 1, position: 'relative' }}>
        <Body>{expiresAt instanceof Date ? dayjs(expiresAt).format('YYYY/MM/DD') : 'N/A'}</Body>
      </AreaBody>
    </Area>
  );
}
