import { Tooltip, TooltipProps as MuiTooltipProps } from '@mui/material';

type TooltipProps = {
  tooltipTitle: MuiTooltipProps['title'];
};

const withTooltip = <TProps extends JSX.IntrinsicAttributes>(Component: (args: TProps) => JSX.Element | null) => {
  const WithTooltip = (props: TProps & TooltipProps) => {
    return (
      <Tooltip
        title={props.tooltipTitle}
        arrow
        placement="top"
        componentsProps={{
          tooltip: {
            sx: {
              borderRadius: 2,
              padding: 1,
            },
          },
        }}
      >
        <Component {...props} />
      </Tooltip>
    );
  };

  return WithTooltip;
};

export default withTooltip;
