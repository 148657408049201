import { Device } from '@culligan-iot/domain/culligan/device/class/index';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from '../store';
import { DeviceCommandsApiSlice } from '../api/admin/deviceCommandsApiSlice';
import { OTAApiSlice } from '../api/admin/otaApiSlice';
import { FleetTelemetryApiSlice } from '../api/fleet/telemetryApiSlice';
import { OTAReq } from '@/components/OneTools/Ecosystem/OTA/typings';
import { getPowerProfileFromCommand } from '@/shared/validations';
import { LogLevel, OperatingMode, PowerProfile } from '@culligan-iot/domain/culligan/device/class/base';
import { Command, COMMANDS } from '@/shared/constants';

type RequestedOTACommand = {
  command: Extract<Command, 'ota.update'>;
  payload: OTAReq['version'];
};
type RequestedOperatingModeCommand = {
  command: Extract<Command, 'operating_mode.set'>;
  payload: Extract<OperatingMode, 'Disabled' | 'Standard'>;
};
type RequestedPowerProfileCommand = {
  command: Extract<Command, 'power.profile.sleep'> | Extract<Command, 'power.profile.standard'>;
  payload: PowerProfile;
};
type RequestedLogLevelCommand = {
  command: Extract<Command, 'log.set'>;
  payload: LogLevel;
};
type RequestedTelemetryUpdateCommand = {
  command: Extract<Command, 'telemetry.get'>;
};
type RequestedRebootCommand = {
  command: Extract<Command, 'reboot'>;
};

type RequestedCommmand =
  | RequestedOTACommand
  | RequestedOperatingModeCommand
  | RequestedPowerProfileCommand
  | RequestedLogLevelCommand
  | RequestedTelemetryUpdateCommand
  | RequestedRebootCommand;

type DeviceState = {
  online: boolean;
  model: Device['model'];
  pendingCommands: Array<RequestedCommmand>;
  operatingMode: OperatingMode;
};

type DevicesState = {
  [serialNumber: Device['id']]: DeviceState;
};
const reboot = 'reboot';
const initialState = {};

const deviceSlice = createSlice({
  name: 'devicesSlice',
  initialState: initialState,
  reducers: {
    addDevice: (state: DevicesState, action: PayloadAction<{ serialNumber: Device['id']; payload: DeviceState }>) => {
      const serialNumber = action.payload.serialNumber;
      return { ...state, [serialNumber]: action.payload.payload };
    },
    upsertDevice: (
      state: DevicesState,
      action: PayloadAction<{ serialNumber: Device['id']; payload: DeviceState }>
    ) => {
      const hasDevice = state[action.payload.serialNumber] != null;
      const serialNumber = action.payload.serialNumber;
      return hasDevice
        ? {
            ...state,
            [serialNumber]: { ...state[serialNumber], ...action.payload.payload },
          }
        : { ...state, [serialNumber]: action.payload.payload };
    },
    addPendingCommand: (
      state: DevicesState,
      action: PayloadAction<{ serialNumber: Device['id']; command: RequestedCommmand }>
    ) => {
      const serialNumber = action.payload.serialNumber;
      const command = action.payload.command;
      const device = state[serialNumber];
      return {
        ...state,
        [serialNumber]: {
          ...device,
          pendingCommands: [command, ...device.pendingCommands],
        },
      };
    },
    removePendingCommand: (
      state: DevicesState,
      action: PayloadAction<{
        serialNumber: Device['id'];
        command: Command;
      }>
    ) => {
      const serialNumber = action.payload.serialNumber;
      const command = action.payload.command;
      const device = state[serialNumber];
      return {
        ...state,
        [serialNumber]: {
          ...device,
          pendingCommands: device.pendingCommands.filter((c) => c.command !== command),
        },
      };
    },
  },
  extraReducers(builder) {
    builder.addMatcher(OTAApiSlice.endpoints.postOTAJob.matchPending, (state: DevicesState, action) => {
      const { targetFilterParams, version } = action.meta.arg.originalArgs;
      const serialNumber = targetFilterParams.serialNumbers[0];
      const requestedCommand: RequestedOTACommand = { command: COMMANDS.OTA_UPDATE, payload: version };
      state[serialNumber].pendingCommands = [...new Set([requestedCommand, ...state[serialNumber].pendingCommands])];
    });
    builder.addMatcher(OTAApiSlice.endpoints.postOTAJob.matchRejected, (state: DevicesState, action) => {
      const { targetFilterParams } = action.meta.arg.originalArgs;
      const serialNumber = targetFilterParams.serialNumbers[0];
      state[serialNumber].pendingCommands = state[serialNumber].pendingCommands.filter(
        (c) => c.command !== COMMANDS.OTA_UPDATE
      );
    });
    builder.addMatcher(
      FleetTelemetryApiSlice.endpoints.requestTelemetryUpdate.matchPending,
      (state: DevicesState, action) => {
        const serialNumber: Device['id'] = action.meta.arg.originalArgs;
        const requestedCommand: RequestedTelemetryUpdateCommand = { command: COMMANDS.TELEMETRY_GET };
        state[serialNumber].pendingCommands = [...new Set([requestedCommand, ...state[serialNumber].pendingCommands])];
      }
    );
    builder.addMatcher(
      FleetTelemetryApiSlice.endpoints.requestTelemetryUpdate.matchRejected,
      (state: DevicesState, action) => {
        const serialNumber: Device['id'] = action.meta.arg.originalArgs;
        state[serialNumber].pendingCommands = state[serialNumber].pendingCommands.filter(
          (c) => c.command !== COMMANDS.TELEMETRY_GET
        );
      }
    );
    builder.addMatcher(
      DeviceCommandsApiSlice.endpoints.postLogLevelCommand.matchPending,
      (state: DevicesState, action) => {
        const { serialNumber, logLevel } = action.meta.arg.originalArgs;
        const requestedCommand: RequestedLogLevelCommand = { command: COMMANDS.LOG_SET, payload: logLevel };
        state[serialNumber].pendingCommands = [...new Set([requestedCommand, ...state[serialNumber].pendingCommands])];
      }
    );
    builder.addMatcher(
      DeviceCommandsApiSlice.endpoints.postLogLevelCommand.matchRejected,
      (state: DevicesState, action) => {
        const { serialNumber } = action.meta.arg.originalArgs;
        state[serialNumber].pendingCommands = state[serialNumber].pendingCommands.filter(
          (c) => c.command !== COMMANDS.LOG_SET
        );
      }
    );
    builder.addMatcher(
      DeviceCommandsApiSlice.endpoints.postOperatingModeCommand.matchPending,
      (state: DevicesState, action) => {
        const { serialNumber, operatingMode } = action.meta.arg.originalArgs;
        const requestedCommand = {
          command: 'operating_mode.set',
          payload: operatingMode,
        } as RequestedOperatingModeCommand;
        state[serialNumber].pendingCommands = [...new Set([requestedCommand, ...state[serialNumber].pendingCommands])];
      }
    );
    builder.addMatcher(
      DeviceCommandsApiSlice.endpoints.postOperatingModeCommand.matchRejected,
      (state: DevicesState, action) => {
        const { serialNumber } = action.meta.arg.originalArgs;
        state[serialNumber].pendingCommands = state[serialNumber].pendingCommands.filter(
          (c) => c.command !== 'operating_mode.set'
        );
      }
    );
    builder.addMatcher(
      DeviceCommandsApiSlice.endpoints.postPowerProfileCommand.matchPending,
      (state: DevicesState, action) => {
        const { serialNumber } = action.meta.arg.originalArgs;
        const command = action.meta.arg.originalArgs.powerProfile === 'Standard' ? COMMANDS.STANDARD : COMMANDS.SLEEP;
        const requestedCommand: RequestedPowerProfileCommand = {
          command: command,
          payload: getPowerProfileFromCommand(command),
        };
        state[serialNumber].pendingCommands = [...new Set([requestedCommand, ...state[serialNumber].pendingCommands])];
      }
    );
    builder.addMatcher(
      DeviceCommandsApiSlice.endpoints.postPowerProfileCommand.matchRejected,
      (state: DevicesState, action) => {
        const { serialNumber } = action.meta.arg.originalArgs;
        state[serialNumber].pendingCommands = state[serialNumber].pendingCommands.filter(
          (c) => c.command !== COMMANDS.SLEEP && c.command !== COMMANDS.STANDARD
        );
      }
    );
    builder.addMatcher(
      DeviceCommandsApiSlice.endpoints.postRebootCommand.matchPending,
      (state: DevicesState, action) => {
        const { serialNumber } = action.meta.arg.originalArgs;
        const requestedCommand: RequestedRebootCommand = {
          command: COMMANDS.REBOOT,
        };
        state[serialNumber].pendingCommands = [...new Set([requestedCommand, ...state[serialNumber].pendingCommands])];
      }
    );
    builder.addMatcher(
      DeviceCommandsApiSlice.endpoints.postRebootCommand.matchRejected,
      (state: DevicesState, action) => {
        const { serialNumber } = action.meta.arg.originalArgs;
        state[serialNumber].pendingCommands = state[serialNumber].pendingCommands.filter((c) => c.command !== reboot);
      }
    );
  },
});

export const selectDevice = createSelector(
  (state: AppState) => state.devices,
  (_: AppState, serialNumber: Device['id']) => serialNumber,
  (devices: DevicesState, serialNumber: Device['id']) => devices[serialNumber] || null
);
export const selectPendingCommands = createSelector(
  (state: AppState) => state.devices,
  (_: AppState, serialNumber: Device['id']) => serialNumber,
  (devices: DevicesState, serialNumber: Device['id']) => devices[serialNumber]?.pendingCommands || []
);
export const selectDeviceModel = createSelector(
  (state: AppState) => state.devices,
  (_: AppState, serialNumber: Device['id']) => serialNumber,
  (devices: DevicesState, serialNumber: Device['id']) => devices[serialNumber].model
);
export const selectPendingOperatingMode = createSelector(selectPendingCommands, (commands) =>
  commands.find((c) => c.command === COMMANDS.OPERATING_MODE_SET || c.command === COMMANDS.REBOOT)
);
export const selectPendingOTAUpdate = createSelector(selectPendingCommands, (commands) =>
  commands.find((c) => c.command === COMMANDS.OTA_UPDATE)
);
export const selectHasPendingOperatingMode = createSelector(selectPendingCommands, (commands) =>
  commands.some((c) => c.command === COMMANDS.OPERATING_MODE_SET || c.command === COMMANDS.REBOOT)
);
export const selectHasPendingOTAUpdate = createSelector(selectPendingCommands, (commands) =>
  commands.some((c) => c.command === COMMANDS.OTA_UPDATE)
);
export const selectHasPendingPowerProfile = createSelector(selectPendingCommands, (commands) =>
  commands.some((c) => c.command === COMMANDS.STANDARD || c.command === COMMANDS.SLEEP)
);
export const selectHasPendingLogLevel = createSelector(selectPendingCommands, (commands) =>
  commands.some((c) => c.command === COMMANDS.LOG_SET)
);
export const { addDevice, upsertDevice, addPendingCommand, removePendingCommand } = deviceSlice.actions;
export default deviceSlice.reducer;
