import { ApiResult } from '@typings';
import apiSlice, { API_VERSION } from '../apiSlice';
import { FilterAlarmRequest, FleetAlarm } from '@/components/Fleet/AlarmsAndErrors/typings';

export const AlarmsApiSlice = apiSlice.enhanceEndpoints({ addTagTypes: ['FleetAlarms', 'Fleet'] }).injectEndpoints({
  endpoints: (builder) => ({
    getFilteredAlarms: builder.query<
      ApiResult<{ itemsCount: number; pagesCount: number; page: number; items: FleetAlarm[] }>,
      FilterAlarmRequest
    >({
      query: (params) => {
        return {
          url: `${API_VERSION.v2}/fleet/alarms/filteredList`,
          params,
        };
      },
      providesTags: ['FleetAlarms'],
    }),
    getAlarmsFilters: builder.query<
      ApiResult<{ brands: { id: string; name: string }[]; identities: { id: string; name: string }[] }>,
      void
    >({
      query: () => {
        return {
          url: `${API_VERSION.v2}/fleet/alarms/filters`,
        };
      },
      providesTags: ['FleetAlarms'],
    }),
    getCustomer: builder.query<
      ApiResult<{ customer: { id: string; firstName: string; lastName: string } }>,
      { customerId: string }
    >({
      query: (params) => {
        return {
          url: `${API_VERSION.v2}/fleet/alarms/customer`,
          params,
        };
      },
      providesTags: ['FleetAlarms'],
    }),
  }),
  overrideExisting: 'throw',
});

export const {
  useGetFilteredAlarmsQuery,
  useGetAlarmsFiltersQuery,
  useLazyGetFilteredAlarmsQuery,
  useLazyGetCustomerQuery,
  useGetCustomerQuery,
} = AlarmsApiSlice;
