import { Alert, AlertTitle, Button, Collapse, colors, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RenderIf from '../RenderIf/RenderIf';
import { useSelector } from 'react-redux';
import { AppState } from '@/redux/store';
export default function ErrorMessage({ message, needsDetails }: { message?: string; needsDetails?: boolean }) {
  const { t } = useTranslation();
  const [expand, setExpand] = React.useState(false);
  const userRoles = useSelector((state: AppState) => state.auth.roles);
  const roleCanSeeErrorDetails = userRoles.includes('admin');
  const isDev = process.env.NODE_ENV === 'dev' || process.env.NODE_ENV === 'development';
  const shouldRenderDetails = needsDetails ? isDev || roleCanSeeErrorDetails : false;

  return (
    <Alert
      severity="error"
      sx={{ position: 'relative' }}
      elevation={0}
      action={
        needsDetails ? (
          <Button
            variant="outlined"
            color="error"
            onClick={() => setExpand(!expand)}
            endIcon={
              <ExpandMoreIcon
                sx={{
                  transition: 'transform 0.18s ease-in-out',
                  transform: expand ? 'rotate(180deg)' : 'rotate(0deg)',
                }}
              />
            }
          >
            {t('learnMore')}
          </Button>
        ) : null
      }
    >
      <AlertTitle>{'Oh snap! Looks like an unexpected error occurred while getting the data.'}</AlertTitle>
      {shouldRenderDetails && (
        <Typography variant="body2" sx={{ color: colors.red }}>
          {'Click on "Learn More" to see the error details.'}
        </Typography>
      )}

      <RenderIf condition={shouldRenderDetails}>
        <Collapse in={expand}>
          <pre style={{ whiteSpace: 'pre-wrap', fontSize: 'small', fontFamily: 'Roboto' }}>{message}</pre>
        </Collapse>
      </RenderIf>
    </Alert>
  );
}
