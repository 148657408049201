import DeviceConsumablesList from '@/components/Fleet/Devices/DevicePanel/ConsumablesPanel/DeviceConsumablesList';
import { useContext } from 'react';
import { DeviceContext } from './DeviceContext';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

export default function Consumables() {
  const { t } = useTranslation();
  const { consumables, data, slots, isLoading, isError, error } = useContext(DeviceContext);
  const navigate = useNavigate();

  return (
    <DeviceConsumablesList
      isDeviceConnected={data?.connectionStatus.online}
      consumables={consumables}
      slots={slots}
      actions={{
        flavors: {
          actionLabel: t('changeLabels'),
          onActionClick: () => navigate('/admin-tools'),
        },
      }}
      isLoading={isLoading}
      isError={isError}
      error={error}
    />
  );
}
