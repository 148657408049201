import CopyChip from '@/components/Shared/Chips/CopyChip';
import { Quench } from '@culligan-iot/domain/culligan/device/vendor/index';
import TagIcon from '@mui/icons-material/Tag';
import { Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function QuenchNumberChip({ vendor }: { vendor?: Quench.DeviceInfo }) {
  const { t } = useTranslation();
  const qNumber = vendor?.qNumber;

  return qNumber ? (
    <CopyChip sx={{ fontWeight: 'bold' }} label={qNumber} icon={<TagIcon sx={{ rotate: '270deg', width: '1rem' }} />} />
  ) : (
    <Chip icon={<TagIcon sx={{ rotate: '270deg', width: '1rem' }} />} label={t('qUnassigned')} size="small" />
  );
}
