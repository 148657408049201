import EventsPanel from '@/components/Fleet/Devices/DevicePanel/EventsPanel';
import { useContext } from 'react';
import { DeviceContext } from './DeviceContext';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';

const SafeEventsPanel = withErrorLoadingManagement(EventsPanel);

export default function Events() {
  const context = useContext(DeviceContext);

  if (!context) {
    return <></>;
  }

  return (
    <SafeEventsPanel
      deviceId={context?.data?.id}
      isError={context.isError}
      isLoading={context.isLoading}
      error={context.error}
    />
  );
}
