import ErrorsTable from '@/components/Fleet/AlarmsAndErrors/Errors/ErrorsTable';
import { Box } from '@mui/material';
import { useContext } from 'react';
import { DeviceContext } from './DeviceContext';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';

const SafeErrorTable = withErrorLoadingManagement(ErrorsTable);

const isError = (error: unknown): error is { id: string; time: number; errorName: string } => {
  return (
    typeof error === 'object' &&
    error !== null &&
    'id' in error &&
    typeof error.id === 'string' &&
    'time' in error &&
    typeof error.time === 'number' &&
    'errorName' in error &&
    error.errorName === 'string'
  );
};
export default function Errors() {
  const context = useContext(DeviceContext);

  if (!context) {
    return <></>;
  }
  const filteredErrors = context.data?.errors?.filter(isError) || [];

  return (
    <Box>
      <SafeErrorTable
        isError={context.isError}
        isLoading={context.isLoading}
        error={context.error}
        items={filteredErrors || []}
        kind={'device' as const}
      />
    </Box>
  );
}
