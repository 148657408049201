import GenericExportTable from '@/components/Shared/Tables/GenericExportTable';
import { useHistoryTelemetryColumns } from './useHistoryTelemetryColumns';
import { useTranslation } from 'react-i18next';
import { TelemetryFiltersState } from '../../../typings';
import { HistoryTelemetry, MergedTelemetry } from '../../typings';
import { ApiResult } from '@typings';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import { Telemetry } from '@culligan-iot/domain/culligan/device/capability/telemetry';

type HistoryTelemetryWithIdentity = {
  id: string;
  lastUpdate: number;
} & {
  [K in string]: K extends 'id' | 'lastUpdate'
    ? HistoryTelemetryWithIdentity[K]
    : { value: Telemetry['value'] } | undefined;
};

const isHistoryTelemetryWithIdentity = (item: unknown): item is HistoryTelemetryWithIdentity =>
  typeof item === 'object' && item !== null && 'id' in item;

const TelemetryHistoryTable = ({
  telemetryFilters,
  telemetries,
  data,
  status,
}: {
  telemetryFilters: TelemetryFiltersState;
  telemetries: MergedTelemetry[] | undefined;
  data: ApiResult<{ items: HistoryTelemetry[] }> | undefined;
  status: {
    isFetching: boolean;
    isLoading: boolean;
  };
}) => {
  const { t } = useTranslation();
  const { columns, isLoading: isLoadingColumns } = useHistoryTelemetryColumns(
    telemetries || [],
    data?.data?.items || []
  );

  return (
    <GenericExportTable<HistoryTelemetryWithIdentity>
      sx={{ minHeight: '500px', '@media print': { display: 'none' } }}
      isLoading={status.isLoading || status.isFetching || isLoadingColumns}
      title={t('telemetryHistory')}
      columns={
        (telemetryFilters?.key?.length || telemetryFilters?.tag?.length) && !isLoadingColumns && !status.isLoading
          ? columns
          : []
      }
      exportData={true}
      selection={false}
      data={
        (telemetryFilters?.key?.length || telemetryFilters?.tag?.length) && !status.isLoading
          ? (data?.data?.items ?? [])
              .filter((item): item is HistoryTelemetry => !!item)
              .map((item, index) => ({
                ...item,
                id: index.toString(),
              }))
              .filter((item): item is HistoryTelemetryWithIdentity => isHistoryTelemetryWithIdentity(item))
              .sort((a, b) => (b?.lastUpdate ?? 0) - (a?.lastUpdate ?? 0))
          : []
      }
      options={{
        pageSize: 20,
        sorting: true,
        pageSizeOptions: [20, 50, 100],
        headerStyle: {
          fontSize: '1rem',
          position: 'sticky',
          top: 0,
          paddingTop: '0.5rem',
          paddingBottom: '0.5rem',
          backgroundColor: 'rgb(242, 242, 242)',
          zIndex: 10,
        },
        maxBodyHeight: '500px',
        overflowY: 'scroll',
      }}
    />
  );
};
export const TelemetryDataTable = withErrorLoadingManagement(TelemetryHistoryTable);
