import { AreaRow } from '@/components/Shared/Card/Area';
import { Device } from '@culligan-iot/domain/culligan/device/class/index';
import { isExpirable } from '@culligan-iot/domain/culligan/device/capability/consumable';
import ConsumableIdentity from './ConsumableIdentity';
import ConsumableUsage from './ConsumableUsage';
import ConsumableExpiration from './ConsumableExpiration';
import { getRemainingUsagePercentage } from '@/shared/domain/consumables';
import { Option } from 'effect';

type ConsumableBodyProps = {
  expiresAt?: Date;
  usagePercentage: Option.Option<number>;
  subset: NonNullable<Device['consumables'][number]>['subset'];
};

function ConsumableBody({ expiresAt, subset, usagePercentage }: ConsumableBodyProps) {
  return (
    <AreaRow
      sx={{
        mb: '0.5rem',
        display: 'grid',
        gridTemplate: `auto / ${subset.constructor.expirable ? '1fr 1fr 1fr' : '1fr 2fr'}`,
      }}
    >
      <ConsumableIdentity id={subset.id} name={subset.name} />
      {subset.constructor.expirable && <ConsumableExpiration expiresAt={expiresAt} />}
      <ConsumableUsage usage={usagePercentage} />
    </AreaRow>
  );
}

export default function DeviceConsumableCardOverview({
  consumable,
}: {
  consumable: NonNullable<Device['consumables'][number]>;
}) {
  const subset = consumable.subset;
  const expiresAt = isExpirable(consumable.state) ? consumable.state.expiresAt : undefined;
  const eitherUsagePercentage = getRemainingUsagePercentage(consumable);

  return <ConsumableBody expiresAt={expiresAt} usagePercentage={eitherUsagePercentage} subset={subset} />;
}
