import { Device } from '@culligan-iot/domain/culligan/device/class/index';
import { LogLevel } from '@culligan-iot/domain/culligan/device/class/base';

import ChatIcon from '@mui/icons-material/Chat';
import FeedbackIcon from '@mui/icons-material/Feedback';
import SpeakerNotesOffIcon from '@mui/icons-material/SpeakerNotesOff';
import TryIcon from '@mui/icons-material/Try';

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Palette, useTheme } from '@mui/material';

type BaseLogLevel = {
  value: LogLevel;
  icon: React.ElementType;
  color: string;
  label: string;
};

// Define types for enabled and disabled log levels
type EnabledLogLevel = BaseLogLevel & { disabled: false };
type DisabledLogLevel = BaseLogLevel & { disabled: true; reason: string };

// Union type for all possible log level types
type LogLevelType = EnabledLogLevel | DisabledLogLevel;

export const useLogLevels = (device: Device) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const logLevels: LogLevelType[] = useMemo(
    () =>
      (
        [
          {
            value: 'Silent',
            icon: SpeakerNotesOffIcon,
            color: theme.palette.grey[800],
            label: t('Silent'),
            disabled: false,
          },
          {
            value: 'Debug',
            icon: ChatIcon,
            color: theme.palette.success.light,
            label: t('Debug'),
            disabled: false,
          },
          {
            value: 'Warning',
            icon: FeedbackIcon,
            color: theme.palette.warning.light,
            label: t('Warning'),
            disabled: false,
          },
          {
            value: 'Trace',
            icon: TryIcon,
            color: theme.palette.error.light,
            label: t('Trace'),
            disabled: false,
          },
        ] as const satisfies Array<{
          value: LogLevel;
          icon: React.ElementType;
          color: Palette[keyof Palette];
          label: string;
          disabled: boolean;
        }>
      )
        /*
         * FIXME: This is a workaround for a TypeScript limitation.
         * Right now the supported LogLevels are statically modeled at the moment of writing
         * with ONLY Silent supported on the 3 identities, so TS is treating "Silent"
         * as the only constant literal in the array of supported log levels.
         * While this is not true, because the supported logLevels can change. Therefore, we need to
         * check if the level is supported by the device and if not, disable it.
         * That is the reason for the type casting `as Array<LogLelevel>`.
         */
        .filter((level) => (device.constructor.logLevels as Array<LogLevel>).includes(level.value))
        .filter((level) => level.value !== device.state.logLevel),
    [
      theme.palette.grey,
      theme.palette.success.light,
      theme.palette.warning.light,
      theme.palette.error.light,
      t,
      device.constructor.logLevels,
      device.state.logLevel,
    ]
  );

  return logLevels;
};
