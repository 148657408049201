import { ReactNode } from 'react';
import ErrorMessage from './ErrorMessage';
import LoadingMessage from './LoadingMessage';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';
import { ApiFailureResult } from '@typings';
import { NotFoundError, ValidationError } from '@culligan-iot/domain/culligan/api/v2';
import { Schema } from 'effect';

function isErrorWithMessage(error: unknown): error is { data: { error: { message: string } } } {
  return (
    typeof error === 'object' &&
    error !== null &&
    'data' in error &&
    typeof error.data === 'object' &&
    error.data !== null &&
    'error' in error.data &&
    typeof error.data.error === 'object' &&
    error.data.error !== null &&
    'message' in error.data.error &&
    typeof error.data.error.message === 'string'
  );
}
const isValidationError = (error: unknown): error is { data: { error: ValidationError } } => {
  if (
    typeof error === 'object' &&
    error !== null &&
    'data' in error &&
    typeof error.data === 'object' &&
    error.data !== null &&
    'error' in error.data &&
    typeof error.data.error === 'object'
  ) {
    return Schema.is(ValidationError)(error.data.error!);
  }
  return false;
};
export const getErrorMessage = (
  error:
    | { data: { error: ApiFailureResult } }
    | { data: { error: ValidationError } }
    | { data: { error: NotFoundError } }
    | FetchBaseQueryError
    | SerializedError
    | string
    | undefined
): { message: string; needsDetails: boolean } => {
  switch (true) {
    case undefined:
      return { message: '', needsDetails: false };
    case typeof error === 'object' &&
      'stack' in error &&
      typeof error.stack === 'string' &&
      'message' in error &&
      typeof error.message === 'string':
      return { message: error.message, needsDetails: true };
    case typeof error === 'string':
      return { message: error, needsDetails: false };
    case isValidationError(error):
      return {
        message: error.data.error.message,
        needsDetails: true,
      };
    case isErrorWithMessage(error):
      return { message: error.data.error.message, needsDetails: true };
    default:
      return { message: 'Ops. An error occurred while fetching data', needsDetails: false };
  }
};

const withErrorLoadingManagement = (Component: React.FC | ((args: any) => JSX.Element | null)) => {
  const WithErrorLoadingManagement = ({
    isLoading,
    isError,
    error,
    LoadingComponent,
    ...props
  }: {
    isLoading: boolean;
    isError: boolean;
    error?: string | SerializedError | FetchBaseQueryError;
    LoadingComponent?: ReactNode;
    [k: string]: any;
  }) => {
    if (isLoading) {
      return LoadingComponent ? <LoadingMessage>{LoadingComponent}</LoadingMessage> : <LoadingMessage />;
    }

    if (isError) {
      const { message, needsDetails } = getErrorMessage(error);
      return <ErrorMessage message={message} needsDetails={needsDetails} />;
    }

    return (Component && <Component {...props} />) || null;
  };

  return WithErrorLoadingManagement;
};

export default withErrorLoadingManagement;
