import { Schema as S, Either } from 'effect';
import { _ApiResult, ApiResult } from '@typings';
import {
  DeviceConnectionHistoryItem,
  Device as LegacyDevice,
  FleetDevices,
  UpdateDeviceLoyaltyProgram,
} from '@/components/Fleet/Devices/DevicesPanel/typings';
import apiSlice, { API_VERSION } from '../apiSlice';
import { roleAwareQuery } from '@/redux/api/roleAwareQuery';
import { addDevice } from '@/redux/slices/deviceSlice';
import { Device } from '@culligan-iot/domain/culligan/device/class/index';
import { handleDecodeUnknownEither } from '@/redux/utils';
import { DeviceList, Device as DeviceListDevice } from '@culligan-iot/domain/culligan/api/device/deviceList';

type UpdateDeviceLoyaltyProgramRequest = {
  loyaltyPrograms: string[];
  serialNumber: string;
};

export type PaginatedResponse<T> = {
  itemsCount: number;
  pagesCount: number;
  page: number;
  items: readonly T[];
};

export const GetDeviceResponse = _ApiResult(Device).annotations({
  identifier: 'GetDeviceResponse',
  parseIssueTitle: () => 'Error parsing device data: ',
});

const decodeGetDeviceResponse = S.decodeUnknownEither(GetDeviceResponse, { errors: 'all' });

const id = (x: any) => x;
const decodeDeviceList = S.decodeUnknownEither(
  S.Struct({
    success: S.Boolean,
    data: DeviceList,
  })
);
const decodePaginatedDeviceList = S.decodeUnknownEither(
  S.Struct({
    success: S.Boolean,
    data: S.Struct({
      itemsCount: S.Int.pipe(S.nonNegative()),
      pagesCount: S.Int.pipe(S.nonNegative()),
      page: S.Int.pipe(S.nonNegative()),
      items: DeviceList,
    }),
  })
);

const devicesApiSlice = apiSlice
  .enhanceEndpoints({ addTagTypes: ['FleetDevices', 'Fleet', 'Device', 'FleetDevice', 'DevicesFilters'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getDevices: builder.query<ApiResult<{ items: LegacyDevice[] }>, void>({
        queryFn: roleAwareQuery('getDevices'),
        providesTags: ['FleetDevices', 'Fleet'],
      }),
      getDevice: builder.query<typeof GetDeviceResponse.Type, Device['id']>({
        query: (id) => `${API_VERSION.v2}/admin/device/registry?serialNumber=${id}`,
        transformResponse: (response) => decodeGetDeviceResponse(response).pipe(handleDecodeUnknownEither),
        providesTags: ['FleetDevices', 'FleetDevice', 'Fleet', 'Device'],
        onCacheEntryAdded: async (arg, { dispatch, cacheDataLoaded }) => {
          const result = await cacheDataLoaded;
          const device = result.data.success ? result.data.data : null;
          if (device) {
            dispatch(
              addDevice({
                serialNumber: arg,
                payload: {
                  model: device.model,
                  online: device.connectionStatus.online || false,
                  operatingMode: device.state.operatingMode,
                  pendingCommands: [],
                },
              })
            );
          }
        },
      }),
      getFilteredDevices: builder.query<ApiResult<DeviceList>, FleetDevices.DecodedFilters>({
        query: (params) => ({
          url: `${API_VERSION.v2}/fleet/devices/all`,
          params: params,
        }),
        transformResponse: (response) => decodeDeviceList(response).pipe(Either.getOrThrowWith(id)),
      }),
      getPaginatedFilteredDevices: builder.query<
        ApiResult<PaginatedResponse<DeviceListDevice>>,
        FleetDevices.DecodedFilters
      >({
        query: (params) => ({
          url: `${API_VERSION.v2}/fleet/devices`,
          params: params,
        }),
        transformResponse: (response) => decodePaginatedDeviceList(response).pipe(Either.getOrThrowWith(id)),
      }),
      getProtocolVersion: builder.query<ApiResult<{ swVersion: string }>, string>({
        queryFn: roleAwareQuery('getSwVersion', (arg) => ({
          params: { serialNumber: arg },
        })),
      }),
      getDeviceConnectionHistory: builder.query<ApiResult<{ items: DeviceConnectionHistoryItem[] }>, string>({
        queryFn: roleAwareQuery('getDeviceConnectionHistory', (arg) => ({
          params: { serialNumber: arg },
        })),
        providesTags: ['FleetDevices', 'Fleet'],
      }),
      updateDeviceLoyaltyProgram: builder.mutation<
        ApiResult<UpdateDeviceLoyaltyProgram>,
        UpdateDeviceLoyaltyProgramRequest
      >({
        queryFn: roleAwareQuery('patchUpdateLoyaltyPrograms', (arg: UpdateDeviceLoyaltyProgramRequest) => ({
          params: { serialNumber: arg.serialNumber },
          body: { loyaltyPrograms: arg.loyaltyPrograms },
          method: 'PATCH',
        })),
        invalidatesTags: ['FleetDevices'],
      }),
    }),
    overrideExisting: 'throw',
  });

export const {
  useGetDevicesQuery,
  useGetDeviceQuery,
  useGetFilteredDevicesQuery,
  useLazyGetDeviceQuery,
  useLazyGetPaginatedFilteredDevicesQuery,
  useLazyGetProtocolVersionQuery,
  useGetDeviceConnectionHistoryQuery,
  useUpdateDeviceLoyaltyProgramMutation,
} = devicesApiSlice;

export default devicesApiSlice;
