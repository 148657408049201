import { useMemo } from 'react';
import { Device } from '@culligan-iot/domain/culligan/device/class/index';

/**
 * The hook will try to obtain the informations about the consumable from the device if present.
 * Otherwise it will fallback to the slot static definition from the domain.
 * When the consumable it's not present, the vacant slot info will get retuned.
 * Since there can be two alternatives for a vacant slot, the name and id will be concatenated.
 * @param slot the slot static definition from the domain.
 * @param consumable the consumable object from the device if present.
 * @returns the name, id and category of the slot.
 *
 */
export const useSlotSpecs = (
  slot: Device['constructor']['slots'][number],
  consumable: Device['consumables'][number]
) => {
  const name = useMemo(
    () => (consumable ? consumable.subset.constructor.label : slot.map((item) => item.label).join(' / ')),
    [consumable, slot]
  );
  const id = useMemo(() => (consumable ? consumable.subset.id : slot.map((item) => item.id)), [consumable, slot]);
  const category = slot[0].category;

  return { name, id, category };
};
