import {
  AutoAwesome as AutoAwesomeIcon,
  Co2 as Co2Icon,
  PhotoFilter as PhotoFilterIcon,
  Flare as FlareIcon,
  // GasMeter as GasMeterIcon,
  // Science as ScienceIcon,
  // Sanitizer as SanitizerIcon,
  // Animation as AnimationIcon,
  // Opacity as OpacityIcon,
} from '@mui/icons-material';
import { ConsumableDefinition } from '@culligan-iot/domain/culligan/device/capability/consumable';

export const ACTION_STATUS = {
  Uninitialized: 'uninitialized',
  Loading: 'loading',
  Error: 'error',
  Success: 'success',
} as const;

export const consumableKindIcons = {
  'CO2 Tank': <Co2Icon />,
  Enhancement: <AutoAwesomeIcon />,
  Filter: <PhotoFilterIcon />,
  'UV Lamp': <FlareIcon />,
  // chemicals: <ScienceIcon />,
  // membranes: <AnimationIcon />,
  // ozone: <GasMeterIcon />,
  // sanitizationKit: <SanitizerIcon />,
  // waterBottles: <OpacityIcon />,
} satisfies { [Key in ConsumableDefinition['category']]: JSX.Element };
