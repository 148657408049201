import { Box, BoxProps, ButtonBase, CSSObject, Theme, alpha, styled, useTheme } from '@mui/material';
import { ReactNode, Ref, useState } from 'react';

const AREA_DESIGN_TOKENS = {
  gap: 1,
  outerEdgeBorderRadius: 8,
  innerEdgeBorderRadius: 2,
  internalActionGap: 0.25,
  buttonIconSize: '32px',
} as const;

type AreaContainerProps = {
  children?: ReactNode;
  paddingRenderer?: 'root' | 'section';
  disabled?: boolean;
  forwardRef?: Ref<unknown>;
  hoverLineColor?: string;
} & BoxProps;

interface HoverLineProps extends BoxProps {
  hover?: boolean;
  bg?: string;
}

const hoverInMixin = (theme: Theme, bg?: string): CSSObject => ({
  transition: theme.transitions.create(['background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.enteringScreen,
  }),
  backgroundColor: bg || theme.palette.primary.light,
});

const hoverOutMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create(['top'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: theme.palette.grey[500],
});

export const HoverLine = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'hover',
})<HoverLineProps>(({ theme, hover, bg }) => ({
  ...(hover && {
    '&': hoverInMixin(theme, bg),
  }),
  ...(!hover && {
    '&': hoverOutMixin(theme),
  }),
}));

export default function AreaContainer({
  children,
  sx,
  paddingRenderer = 'root',
  disabled = false,
  forwardRef,
  hoverLineColor,
  ...rest
}: AreaContainerProps) {
  const [hover, setHover] = useState(false);

  const AreaContainerBody = (
    <Box
      {...rest}
      {...(rest.onClick && paddingRenderer === 'root' && !disabled
        ? {
            onMouseEnter: () => setHover(true),
            onMouseLeave: () => setHover(false),
            onClick: (...args: any) => {
              typeof rest.onClick === 'function' && rest?.onClick(args);
              setHover(false);
            },
          }
        : {})}
      ref={forwardRef}
      data-disabled={disabled}
      sx={{
        p: Number(paddingRenderer === 'root' && 2),
        height: '100%',
        borderRadius: 2,
        backgroundColor: (theme) => theme.palette.background.grayShades[0],
        ...(paddingRenderer === 'root'
          ? {
              ...(rest.onClick ? { cursor: 'pointer', '& *': { cursor: 'pointer' } } : {}),
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              flexGrow: 1,
              flexBasis: 0,
            }
          : {}),
        ...sx,
      }}
    >
      {rest.onClick && paddingRenderer === 'root' && (
        <HoverLine
          sx={{
            width: '100%',
            height: '3px',
            borderRadius: '5px',
          }}
          hover={hover}
          bg={hoverLineColor}
        />
      )}
      {children}
    </Box>
  );

  return rest.onClick ? (
    <ButtonBase
      component="div"
      sx={{
        textAlign: 'unset',
        height: '100%',
        width: 'auto',
        '&:focus': { outline: 'none' },
        display: 'flex',
        gap: 1,
        flexGrow: 1,
        flexBasis: 0,
        borderRadius: 2,
        backgroundImage: 'none',
        ...sx,
      }}
    >
      {AreaContainerBody}
    </ButtonBase>
  ) : (
    AreaContainerBody
  );
}

const StatusAreaContainer = ({
  children,
  gradientVariant = 'linear',
  shade,
  hoverShade,
  hoverLineColor,
  forwardRef,
  ...rest
}: AreaContainerProps & {
  gradientVariant?: 'linear' | 'radial';
  shade: string | ((theme: Theme) => string);
  hoverShade?: string | ((theme: Theme) => string);
}) => {
  const theme = useTheme();
  const _shade = typeof shade === 'string' ? shade : shade(theme);
  const hoverShadeValue = hoverShade != null ? (typeof hoverShade === 'string' ? hoverShade : hoverShade(theme)) : null;
  const transparentShade = alpha(_shade, 0);
  const transparentHoverShade = hoverShadeValue != null ? alpha(hoverShadeValue, 0) : null;
  const opacityHoverShade = hoverShadeValue != null ? alpha(hoverShadeValue, 0.2) : null;
  const opacityShade = alpha(_shade, 0.2);

  const gradient = `${gradientVariant}-gradient(${
    gradientVariant === 'linear'
      ? `to right, ${transparentShade}, ${opacityShade}`
      : `85.17% 85.17% at 0% 0%, ${opacityShade}, ${transparentShade}`
  })`;
  const hoverGradient = `${gradientVariant}-gradient(${
    gradientVariant === 'linear'
      ? `to right, ${transparentHoverShade}, ${opacityHoverShade}`
      : `85.17% 85.17% at 0% 0%, ${opacityHoverShade}, ${transparentHoverShade}`
  })`;
  return (
    <AreaContainer
      {...rest}
      disabled={rest.disabled}
      hoverLineColor={hoverLineColor}
      forwardRef={forwardRef}
      sx={{
        backgroundImage: gradient,
        ...(hoverShade ? { '&:hover': { backgroundImage: !rest.disabled ? hoverGradient : '' } } : {}),
        transition: 'all 0.66s ease-in-out',
        ...rest.sx,
      }}
    >
      {children}
    </AreaContainer>
  );
};

const AreaHeader = (props: BoxProps) => {
  return <Box {...props} sx={{ display: 'flex', justifyContent: 'space-between', ...props.sx }} />;
};

const AreaBody = ({ forwardRef, ...rest }: BoxProps & { forwardRef?: Ref<unknown> }) => {
  return (
    <Box
      {...rest}
      ref={forwardRef}
      sx={{ flexGrow: 1, display: 'flex', justifyContent: 'end', flexDirection: 'column', ...rest.sx }}
    />
  );
};

const AreaRow = (props: BoxProps) => {
  return <Box {...props} sx={{ display: 'flex', width: '100%', flexWrap: 'wrap', gap: 1, ...props.sx }} />;
};

const DataBoundAreaRow = ({ children, sx, ...rest }: { children?: ReactNode; sx?: BoxProps['sx'] } & BoxProps) => {
  return (
    <Box
      {...rest}
      sx={{
        display: 'flex',
        padding: 0,
        overflow: 'hidden',
        gap: 0.25,
        borderRadius: 2,
        ...(sx ? sx : {}),
      }}
    >
      {children}
    </Box>
  );
};

export { AreaBody, AreaHeader, AreaRow, DataBoundAreaRow, StatusAreaContainer, AREA_DESIGN_TOKENS };
