import { ApiResult } from '@typings';
import apiSlice, { API_VERSION } from '../apiSlice';

type FilterOption = {
  id: string;
  name: string;
};

type CustomerFilterOption = {
  id: string;
  firstName: string;
  lastName: string;
};

type BusinessAnalysisFiltersRes = {
  brands: FilterOption[];
  models: FilterOption[];
  customer: CustomerFilterOption[];
};

type BusinessAnalysisFiltersReq = {
  customer?: string;
} | void;

const businessAnalysisFiltersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBusinessAnalysisFilters: builder.query<
      ApiResult<{ items: BusinessAnalysisFiltersRes }>,
      BusinessAnalysisFiltersReq
    >({
      query: (params) => {
        const searchParams = params && params.customer ? new URLSearchParams(params) : '';
        return `${API_VERSION.v2}/business-analysis/filters`.concat(searchParams ? `?${searchParams}` : '');
      },
    }),
  }),
  overrideExisting: 'throw',
});

export const { useGetBusinessAnalysisFiltersQuery } = businessAnalysisFiltersApiSlice;
