import { Column } from '@material-table/core';
import { Avatar, Box, Button, Chip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router';
import RelatedEntityLabel from '@/components/Shared/Chips/RelatedEntityChip';
import { FleetError } from '../typings';
import BlipChip from '@/components/Shared/Chips/BlipChip';

import { columnsBuilder, withExcludedColumns } from '@/components/Shared/Tables/utils';
import { TFunction } from 'i18next';
import { FleetOverview } from '../../Overview/typings';
import { useCallback } from 'react';
import { getPath } from '@/shared/utils';
import { DeviceError } from '../../Devices/DevicesPanel/typings';

interface UseErrorsColumnsProps {
  kind: 'fleet' | 'overview' | 'device';
  onDetailClick?: (id: string) => void;
  excludeColumns?: string[];
}

export default function useErrorColumns({
  kind,
  onDetailClick,
  excludeColumns,
}: UseErrorsColumnsProps): Array<Column<FleetError>> | Array<Column<FleetOverview.Error>> | Array<Column<DeviceError>> {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleNavigate = useCallback(
    (deviceId: string) => {
      navigate(getPath('FLEET_DEVICE', { deviceId }));
    },
    [navigate]
  );

  switch (kind) {
    case 'fleet':
      return withExcludedColumns(buildFleetErrorsColumns(t, handleNavigate, onDetailClick), excludeColumns);
    case 'overview':
      return withExcludedColumns(buildOverviewErrorsColumns(t, handleNavigate, onDetailClick), excludeColumns);
    case 'device':
      return withExcludedColumns(buildDeviceErrorsColumns(t, onDetailClick), excludeColumns);
  }
}

function buildFleetErrorsColumns(
  t: TFunction<'translation', undefined, 'translation'>,
  handleNavigateDevice: (deviceId: string) => void,
  handleDetail?: (id: FleetError['id']) => void
): Column<FleetError>[] {
  const builder = columnsBuilder<FleetError>();

  return builder
    .addColumn({
      title: t('date'),
      field: 'time',
      exportTransformer: (error) => dayjs(error.time).format('L'),
      render: (error) => (
        <>
          <Typography>{dayjs(error.time).format('L')}</Typography>
          <Typography variant="caption">{dayjs(error.time).fromNow()}</Typography>
        </>
      ),
    })
    .addColumn({
      title: t('name'),
      field: 'name',
      exportTransformer: (error) => error.name,
      render: (error) => (
        <>
          <Typography>{error.name}</Typography>
        </>
      ),
    })
    .addColumn({
      title: t('device'),
      field: 'deviceId',
      exportTransformer: (error) => error.deviceName,
      customFilterAndSearch: (filter, deviceError) => {
        return deviceError?.name!.indexOf(filter) !== -1;
      },
      render: (error) => (
        <RelatedEntityLabel
          text={error.deviceName || (t('device') as string)}
          onEntityClick={() => handleNavigateDevice(error.deviceId)}
        />
      ),
    })
    .addColumn({
      title: t('status'),
      exportTransformer: (error) => {
        const statusError = error.dismissed ? 'resolved' : 'active';
        return statusError;
      },
      field: 'dismissed',
      render: (error) => (
        <>
          {error.dismissed && (
            <Chip
              avatar={
                <Avatar sx={{ bgcolor: 'transparent' }}>
                  <Box
                    sx={(theme) => ({
                      height: '80%',
                      bgcolor: theme.palette.success.light,
                      width: '80%',
                      borderRadius: '50%',
                    })}
                  />
                </Avatar>
              }
              label={t('resolved')}
              size="small"
              color="success"
              variant="outlined"
            />
          )}
          {!error.dismissed && <BlipChip label={t('active')} size="small" color="error" variant="outlined" />}
          {error.dismissed && (
            <>
              <br />
              <Typography variant="caption">{dayjs(error.dismissedAt).fromNow()}</Typography>
            </>
          )}
        </>
      ),
    })
    .addColumn(
      typeof handleDetail === 'function'
        ? {
            title: t('actions'),
            render: (error) => (
              <Box display="flex" gap={1}>
                <Button variant="outlined" color="primary" onClick={() => handleDetail(error.id)}>
                  {t('view')}
                </Button>
              </Box>
            ),
            sorting: false,
            searchable: false,
          }
        : {}
    ).columns;
}

const buildOverviewErrorsColumns = (
  t: TFunction<'translation', undefined, 'translation'>,
  handleNavigateDevice: (deviceId: string) => void,
  handleDetail?: (id: FleetError['id']) => void
): Column<FleetOverview.Error>[] => {
  const builder = columnsBuilder<FleetOverview.Error>();
  return builder
    .addColumn({
      title: t('date'),
      field: 'time',
      exportTransformer: (error) => dayjs(error.time).format('L'),
      render: (error) => (
        <>
          <Typography>{dayjs(error.time).format('L')}</Typography>
          <Typography variant="caption">{dayjs(error.time).fromNow()}</Typography>
        </>
      ),
    })
    .addColumn({
      title: t('name'),
      field: 'name',
      searchable: true,
      exportTransformer: (error) => error.name,
      customFilterAndSearch: (filter, deviceError) =>
        deviceError.name?.toLowerCase().includes(filter?.toLowerCase()) || false,
      render: (error) => <Typography>{error.name}</Typography>,
    })
    .addColumn({
      title: t('device'),
      field: 'deviceId',
      searchable: true,
      exportTransformer: (error) => error.deviceName,
      customFilterAndSearch: (filter, deviceError) =>
        deviceError.deviceId?.toLowerCase().includes(filter?.toLowerCase()),
      render: (error) => (
        <RelatedEntityLabel
          text={error.deviceName || (t('device') as string)}
          onEntityClick={() => handleNavigateDevice(error.deviceId)}
        />
      ),
    })
    .addColumn({
      title: t('actions'),
      render: (error) => (
        <Box display="flex" gap={1}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => typeof handleDetail === 'function' && handleDetail(error.id)}
          >
            {t('view')}
          </Button>
        </Box>
      ),
      sorting: false,
      searchable: false,
    }).columns;
};

const buildDeviceErrorsColumns = (
  t: TFunction<'translation', undefined, 'translation'>,
  handleDetail?: (id: DeviceError['id']) => void
): Column<DeviceError>[] => {
  const builder = columnsBuilder<DeviceError>();
  return builder
    .addColumn({
      title: t('date'),
      field: 'time',
      exportTransformer: (error) => dayjs(error.time).format('L'),
      render: (error) => (
        <>
          <Typography>{dayjs(error.time).format('L')}</Typography>
          <Typography variant="caption">{dayjs(error.time).fromNow()}</Typography>
        </>
      ),
    })
    .addColumn({
      title: t('name'),
      field: 'errorName',
      searchable: true,
      exportTransformer: (error) => error.errorName,
      customFilterAndSearch: (filter, deviceError) =>
        deviceError.errorName?.toLowerCase().includes(filter?.toLowerCase()) || false,
      render: (error) => <Typography>{error.errorName}</Typography>,
    })
    .addColumn({
      title: t('status'),
      exportTransformer: (error) => t('active'),
      field: 'dismissed',
      render: (error) => (
        <>
          <BlipChip label={t('active')} size="small" color="error" variant="outlined" />
        </>
      ),
    }).columns;
};
