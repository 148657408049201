import { Box, Typography } from '@mui/material';
import { Fragment, useLayoutEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CurrentDataSection from './CurrentDataSection';
import HistoryDataSection from './HistoryDataSection';
import { SensorWithTelemetry } from './typings';
import Header from '@/components/Shared/Print/Header';
import { parseDeviceTelemetry } from './parseDeviceTelemetry';
import TelemetryProvider from './TelemetryProvider';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';

export const HISTORYDATASECTION_ID = 'historyTelemetryData';

function DeviceTelemetryPanel({ sensors }: { sensors: SensorWithTelemetry[] }) {
  const { t } = useTranslation();
  const { sensorGroups, sensorWithoutGroup, mergedSensors } = useMemo(() => parseDeviceTelemetry(sensors), [sensors]);

  useLayoutEffect(() => {
    const beforePrintListener = () => {
      document.body.id = 'print';
    };
    const afterPrintListener = () => {
      document.body.id = '';
    };

    if (window) {
      window.addEventListener('beforeprint', beforePrintListener);
      window.addEventListener('afterprint', afterPrintListener);
    }

    return () => {
      window.removeEventListener('beforeprint', beforePrintListener);
      window.removeEventListener('afterprint', afterPrintListener);
    };
  }, []);

  return sensors?.length ? (
    <Fragment>
      <Header />
      <TelemetryProvider mergedTelemetries={mergedSensors}>
        <CurrentDataSection sensorsWithoutGroup={sensorWithoutGroup} sensorsGroups={sensorGroups} />
        <HistoryDataSection />
      </TelemetryProvider>
    </Fragment>
  ) : (
    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
      <Typography variant="h6">{t('noTelemetryAvailable')}</Typography>
    </Box>
  );
}
export default withErrorLoadingManagement(DeviceTelemetryPanel);
