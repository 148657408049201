import { ApiResult } from '@typings';
import apiSlice, { API_VERSION } from '../apiSlice';
import { dispatchPendingToOperationsSlice } from '../dispatchPendingToOperations';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@/redux/store';
import { LogLevel, OperatingMode, PowerProfile } from '@culligan-iot/domain/culligan/device/class/base';
import { Command, COMMANDS } from '@/shared/constants';

const URL_PREFIX_QUERY = `${API_VERSION.v2}/admin/device`;
const reboot = 'reboot';
const logset = 'log.set';

type DeviceCommandPayload = { serialNumber: string };

export type OperatingModeCommandPayload = DeviceCommandPayload & {
  operatingMode: Extract<OperatingMode, 'Disabled' | 'Standard'>;
};
export type PowerProfileCommandPayload = DeviceCommandPayload & {
  powerProfile: PowerProfile;
};
export type RebootCommandPayload = DeviceCommandPayload;

export type LogLevelCommandPayload = DeviceCommandPayload & {
  logLevel: LogLevel;
};
export const selectPostOperatingModeCommand = createSelector(
  (state: RootState) => state.api.mutations,
  (mutations) => {
    return mutations;
  }
);

export const selectPostPowerProfileCommand = createSelector(
  (state: RootState) => state.api.mutations,
  (mutations) => {
    return mutations;
  }
);

export const selectRequestedLogLevel = createSelector(
  (state: RootState, requestId: string) =>
    (state.api.mutations[requestId]?.data as { requestedLevel: LogLevel })?.requestedLevel,
  (requestedLevel) => {
    return requestedLevel;
  }
);

export const DeviceCommandsApiSlice = apiSlice.enhanceEndpoints({ addTagTypes: ['Commands'] }).injectEndpoints({
  endpoints: (builder) => ({
    postOperatingModeCommand: builder.mutation<ApiResult<{ requestId: string }>, OperatingModeCommandPayload>({
      query: (body: OperatingModeCommandPayload) => ({
        url: `${URL_PREFIX_QUERY}/operating-mode?serialNumber=${body.serialNumber}`,
        method: 'PATCH',
        body: { operatingMode: body.operatingMode },
      }),
      onQueryStarted: (arg, api) => {
        dispatchPendingToOperationsSlice(api, {
          url_prefix: 'admin/device/command',
          method: 'PATCH',
          subject: arg.operatingMode,
          entity: arg.serialNumber,
        });
      },
      transformResponse(baseQueryReturnValue, _, arg) {
        return { ...(baseQueryReturnValue as any), command: COMMANDS.OPERATING_MODE_SET };
      },
    }),
    postRebootCommand: builder.mutation<ApiResult<{ requestId: string }>, RebootCommandPayload>({
      query: (body: RebootCommandPayload) => ({
        url: `${URL_PREFIX_QUERY}/reboot?serialNumber=${body.serialNumber}`,
        method: 'POST',
      }),
      onQueryStarted: (arg, api) => {
        dispatchPendingToOperationsSlice(api, {
          url_prefix: 'admin/device/command',
          method: 'POST',
          subject: reboot,
          entity: arg.serialNumber,
        });
      },
      transformResponse(baseQueryReturnValue, _, arg) {
        return { ...(baseQueryReturnValue as any), command: 'reboot' };
      },
    }),
    postPowerProfileCommand: builder.mutation<ApiResult<{ requestId: string }>, PowerProfileCommandPayload>({
      query: (body: PowerProfileCommandPayload) => ({
        url: `${URL_PREFIX_QUERY}/power-profile?serialNumber=${body.serialNumber}`,
        method: 'PATCH',
        body: { powerProfile: body.powerProfile },
      }),
      onQueryStarted: (arg, api) => {
        dispatchPendingToOperationsSlice(api, {
          url_prefix: 'admin/device/command',
          method: 'POST',
          subject: arg.powerProfile === 'Standard' ? 'power.profile.standard' : 'power.profile.sleep',
          entity: arg.serialNumber,
        });
      },
    }),
    postLogLevelCommand: builder.mutation<
      ApiResult<{
        command: Extract<Command, 'log.set'>;
        params: { level: LogLevel };
      }>,
      LogLevelCommandPayload
    >({
      query: (body: LogLevelCommandPayload) => ({
        url: `${URL_PREFIX_QUERY}/log-level?serialNumber=${body.serialNumber}`,
        method: 'PATCH',
        body: { logLevel: body.logLevel },
      }),
      onQueryStarted: (arg, api) => {
        dispatchPendingToOperationsSlice(api, {
          url_prefix: 'admin/device/command',
          method: 'POST',
          subject: logset,
          entity: arg.serialNumber,
        });
      },
    }),
    getDeviceOperatingModeAndPowerProfile: builder.query<
      ApiResult<{ operatingMode: OperatingMode; powerProfile: PowerProfile }>,
      string
    >({
      query: (serialNumber: string) => ({
        url: `${URL_PREFIX_QUERY}/operatingModeAndPowerProfile?serialNumber=${serialNumber}`,
        method: 'GET',
      }),
    }),
    getDeviceLogLevel: builder.query<ApiResult<{ logLevel: LogLevel }>, string>({
      query: (serialNumber: string) => ({
        url: `${URL_PREFIX_QUERY}/log-level?serialNumber=${serialNumber}`,
        method: 'GET',
      }),
    }),
  }),
  overrideExisting: 'throw',
});
export const {
  usePostPowerProfileCommandMutation,
  usePostOperatingModeCommandMutation,
  usePostRebootCommandMutation,
  useGetDeviceOperatingModeAndPowerProfileQuery,
  useLazyGetDeviceOperatingModeAndPowerProfileQuery,
  useLazyGetDeviceLogLevelQuery,
  usePostLogLevelCommandMutation,
} = DeviceCommandsApiSlice;
