import { _ApiResult, ApiResult } from '@typings';
import apiSlice, { API_VERSION } from '../apiSlice';
import { DeviceHistoryTelemetryRequest } from '@/components/Fleet/Devices/DevicePanel/TelemetryPanel/typings';
import { Schema } from 'effect';
import { Blackbox, Gbfc, Pelican2 } from '@culligan-iot/domain/culligan/device/class/index';
import { handleDecodeUnknownEither } from '@/redux/utils';

const Commands = {
  telemetry: ['get', 'post'],
} as const;

type CommandKeys = keyof typeof Commands;
type CommandValues = (typeof Commands)[CommandKeys][number];
export type CommandType = `${CommandKeys}.${CommandValues}`;

export type CommandResponse<CommandType> = {
  status: number;
  body: {
    success: boolean;
    data: {
      payload: {
        pVer: string;
        channel: 'command';
        command: CommandType;
        fingerprint: string;
      };
      result: {
        status: number;
        payload: {
          success: boolean;
        } & {
          [key in string]: boolean | string | number;
        };
      };
    };
  };
};

export type LatestTelemetryUpdate = typeof LatestTelemetryUpdate.Type;
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
const LatestTelemetryUpdate = Schema.Struct({ lastUpdate: Schema.Number }).annotations({
  identifier: 'LastUpdate',
  message: () => 'Expected unix timestamp',
});

const FilteredGbfcTelemetry = Gbfc.Telemetry.pipe(Schema.partial, Schema.extend(LatestTelemetryUpdate)).annotations({
  identifier: 'GbfcTelemetryKeys',
});

const FilteredBlackboxTelemetry = Blackbox.Telemetry.pipe(
  Schema.partial,
  Schema.extend(LatestTelemetryUpdate)
).annotations({
  identifier: 'BlackboxTelemetryKeys',
  message: () => 'Expected Blackbox Telemetry Keys',
});

const FilteredPelican2Telemetry = Pelican2.Telemetry.pipe(
  Schema.partial,
  Schema.extend(LatestTelemetryUpdate)
).annotations({
  identifier: 'Pelican2TelemetryKeys',
  message: () => 'Expected Pelican2 Telemetry Keys',
});

const GetFilteredTelemetryResponse = _ApiResult(
  Schema.Struct({
    items: Schema.Array(Schema.Union(FilteredGbfcTelemetry, FilteredBlackboxTelemetry, FilteredPelican2Telemetry)),
  })
).annotations({
  title: 'Get Filtered Telemetry Response',
  identifier: 'GetFilteredTelemetryResponse',
  parseIssueTitle: () => 'Failed to parse GetFilteredTelemetryResponse: ',
  message: () => 'Filtered telemetry response not as expected.',
});

const decodeGetFilteredTelemetryResponse = Schema.decodeUnknownEither(GetFilteredTelemetryResponse, {
  onExcessProperty: 'error',
});

const URL_PREFIX = `${API_VERSION.v2}/admin/device/telemetry`;

export const FleetTelemetryApiSlice = apiSlice
  .enhanceEndpoints({ addTagTypes: ['FleetTelemetry', 'Fleet'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getFilteredTelemetry: builder.query<typeof GetFilteredTelemetryResponse.Type, DeviceHistoryTelemetryRequest>({
        query: ({ model, ...params }) => {
          const queryParams = new URLSearchParams();
          queryParams.append('model', model);
          Object.keys(params).forEach((key) => {
            if (key !== 'keys') {
              queryParams.append(key, String(params[key as keyof Omit<DeviceHistoryTelemetryRequest, 'model'>]));
              return;
            }
          });
          return {
            url: `${API_VERSION.v2}/fleet/telemetry/history?${
              queryParams.toString() + params.keys.reduce((acc, key) => `${acc}&keys[]=${key}`, '')
            }`,
          };
        },
        transformResponse: (response) => decodeGetFilteredTelemetryResponse(response).pipe(handleDecodeUnknownEither),
        providesTags: ['FleetTelemetry'],
      }),
      requestTelemetryUpdate: builder.mutation<ApiResult<CommandResponse<CommandType>>, string>({
        query: (deviceId) => ({
          url: `${URL_PREFIX}/sendUpdateRequest`,
          method: 'POST',
          body: { serialNumber: deviceId },
        }),
        invalidatesTags: ['FleetTelemetry'],
      }),
      getLatestTelemetryUpdate: builder.query<ApiResult<LatestTelemetryUpdate>, string>({
        query: (serialNumber) => {
          return {
            url: `${URL_PREFIX}/lastUpdate?serialNumber=${serialNumber}`,
          };
        },
      }),
    }),
    overrideExisting: 'throw',
  });

export const {
  useLazyGetFilteredTelemetryQuery,
  useRequestTelemetryUpdateMutation,
  useLazyGetLatestTelemetryUpdateQuery,
} = FleetTelemetryApiSlice;
