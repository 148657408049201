import { useLazyGetHistoryEventsQuery } from '@/redux/api/fleet/eventsApiSlice';
import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import UTC from 'dayjs/plugin/utc';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { HistoryEventGroupedByDay } from './typings';
import Card from './Timeline/Card';
import useAccumulateData from '@/hooks/useAccumulateQuery';
import useDebounce from '@/hooks/useDebounce';
import LoadingMessage from '@/components/Shared/withErrorLoadingManagement/LoadingMessage';

const DEFAULT_RANGE = 20;
const TRIGGER_LOAD_MORE_THRESHOLD = 20;
dayjs.extend(UTC);

const handleScroll = (isLoading: boolean, handleLoadMore: Function) => {
  if (isLoading) {
    return;
  }
  const targetScrollY = (TRIGGER_LOAD_MORE_THRESHOLD * document.body.scrollHeight) / 100;
  if (window.scrollY + window.innerHeight >= document.body.scrollHeight - targetScrollY) {
    handleLoadMore();
  }
};

export default function EventsPanel({ deviceId }: { deviceId: string }) {
  const [dateRangeEnd, setDateRangeEnd] = useState(dayjs().utc().endOf('d').valueOf());
  const lastDateRangeEnd = useRef<number | undefined>(undefined);
  const [trigger, { isLoading, isError, isFetching }] = useLazyGetHistoryEventsQuery();
  const { list, add } = useAccumulateData<HistoryEventGroupedByDay>();
  const debouncedHandleScroll = useDebounce(
    () =>
      handleScroll(isLoading || isError || isFetching, () => {
        setDateRangeEnd((prev) => dayjs(prev).utc().subtract(DEFAULT_RANGE, 'd').endOf('d').valueOf());
      }),
    200
  );

  useLayoutEffect(() => {
    if (document) {
      document.addEventListener('scroll', debouncedHandleScroll);
      return () => {
        document.removeEventListener('scroll', debouncedHandleScroll);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function _trigger() {
      const _dateRangeEnd = dayjs(dateRangeEnd).utc();
      if (lastDateRangeEnd.current && lastDateRangeEnd.current === dateRangeEnd) {
        return;
      }

      if (lastDateRangeEnd.current !== dateRangeEnd) {
        lastDateRangeEnd.current = dateRangeEnd;
      }

      const getHistoryEventsQueryResponse = await trigger({
        deviceId,
        dateRangeEnd: _dateRangeEnd.valueOf(),
        dateRangeStart: _dateRangeEnd.subtract(DEFAULT_RANGE, 'd').startOf('d').valueOf(),
      });

      if (getHistoryEventsQueryResponse.error) {
        return;
      }

      if (!getHistoryEventsQueryResponse.data?.data?.items) {
        return;
      }

      add(getHistoryEventsQueryResponse.data?.data.items);
    }

    _trigger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRangeEnd]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0, width: '100%', pb: 4 }}>
      {list.map(({ day, totals, events }, index, arr) => (
        <Box display="grid" gap={2} gridTemplateColumns={'auto auto 1fr'} key={`timeline-item-${index}`}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Typography key={index}>{dayjs(day).format('L')}</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Box
              sx={{
                width: '3px',
                backgroundColor: (theme) => theme.palette.background.grayShades[2],
                flexGrow: 2,
                ...(index === 0
                  ? {
                      borderTopLeftRadius: '10px',
                      borderTopRightRadius: '10px',
                    }
                  : {}),
                borderBottomLeftRadius: '10px',
                borderBottomRightRadius: '10px',
              }}
            />
            <Box
              sx={{
                width: '40px',
                height: '40px',
                backgroundColor: (theme) => theme.palette.primary.light,
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{ color: (theme) => theme.palette.getContrastText(theme.palette.primary.light), fontWeight: '600' }}
              >
                {Object.values(totals).reduce((acc, tot) => acc + tot, 0)}
              </Typography>
            </Box>
            <Box
              sx={{
                width: '3px',
                backgroundColor: (theme) => theme.palette.background.grayShades[2],
                flexGrow: 2,
                ...(index === arr.length - 1
                  ? {
                      borderBottomLeftRadius: '10px',
                      borderBottomRightRadius: '10px',
                    }
                  : {}),
                borderTopLeftRadius: '10px',
                borderTopRightRadius: '10px',
              }}
            />
          </Box>
          <Box pt={index === 0 ? 0 : 2} pb={index === arr.length - 1 ? 0 : 2} sx={{ flexGrow: 2 }}>
            <Card data={{ day, totals, events }} />
          </Box>
        </Box>
      ))}
      <Box sx={{ pt: 4 }}>{(isLoading || isFetching) && <LoadingMessage />}</Box>
    </Box>
  );
}
