import GenericExportTable from '@/components/Shared/Tables/GenericExportTable';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import { FleetAlarm } from '../typings';
import { useTranslation } from 'react-i18next';
import { useAlarmColumns } from './useAlarmsColumn';
import useCulliganDialog from '@/hooks/useCulliganDialog';
import { Column, MaterialTableProps } from '@material-table/core';
import { ROUTES } from '@/shared/constants';
import { useMemo } from 'react';
import { FleetOverviewAlarm } from '../../Overview/utils';
import { Device } from '@culligan-iot/domain/culligan/device/class/index';

export const SafeTable = withErrorLoadingManagement(GenericExportTable<FleetAlarm | Device['alarms'][number]>);

export type AlarmKind = 'fleet' | 'device' | 'overview';

export type AlarmKindMap = {
  fleet: FleetAlarm;
  device: Device['alarms'][number];
  overview: FleetOverviewAlarm;
};

type PropVariants = {
  [K in AlarmKind]: {
    kind: K;
    items: readonly AlarmKindMap[K][];
    onDetailClick?: (deviceId: string) => void;
    slots?: {
      dialog: () => ReturnType<typeof useCulliganDialog>['dialog'];
    };
    excludeColumns?: string[];
    isLoading: boolean;
    isError: boolean;
    error?: unknown;
  } & Omit<MaterialTableProps<any>, 'data' | 'columns'>;
}[AlarmKind];

const areFleetColumns = (kind: AlarmKind, cols: readonly unknown[]): cols is Column<FleetAlarm>[] =>
  cols.filter((_) => kind === 'fleet').length > 0;
const areFleetItems = (kind: AlarmKind, items: readonly unknown[]): items is FleetAlarm[] =>
  Boolean(items.filter((_) => kind === 'fleet'));
const areDeviceColumns = (kind: AlarmKind, cols: readonly unknown[]): cols is Column<Device['alarms'][number]>[] =>
  cols.filter((_) => kind === 'device').length >= 0;
const areDeviceItems = (kind: AlarmKind, items: readonly unknown[]): items is Device['alarms'] =>
  items.filter((_) => kind === 'device').length >= 0;
const areOverviewColumns = (kind: AlarmKind, cols: readonly unknown[]): cols is Column<FleetOverviewAlarm>[] =>
  cols.filter((_) => kind === 'overview').length >= 0;
const areOverviewItems = (kind: AlarmKind, items: readonly unknown[]): items is FleetOverviewAlarm[] =>
  Boolean(items.filter((_) => kind === 'overview'));

export default function AlarmsTable(props: PropVariants) {
  const { t } = useTranslation();
  const columns = useAlarmColumns({
    kind: props.kind,
    onDetailClick: props.onDetailClick,
    excludeColumns: props?.excludeColumns,
  });

  const table = useMemo(() => {
    switch (true) {
      case areFleetColumns(props.kind, columns) && areFleetItems(props.kind, props.items):
        return (
          <GenericExportTable<FleetAlarm>
            {...props}
            title={t(ROUTES.ONETOOL_CHANNELS_ALARMS.fragment)}
            data={props.items}
            columns={columns}
            exportData={true}
            selection={true}
          />
        );
      case areDeviceColumns(props.kind, columns) && areDeviceItems(props.kind, props.items):
        return (
          <SafeTable
            {...props}
            title={t(ROUTES.ONETOOL_CHANNELS_ALARMS.fragment)}
            hasData={props.items.length > 0}
            data={
              props.items.map((item) => ({
                ...item,
                constructor: { ...item.constructor },
              })) || []
            }
            columns={columns}
            isError={props.isError}
            isLoading={props.isLoading}
            error={props?.error || undefined}
            exportData={true}
            selection={true}
          />
        );
      case areOverviewColumns(props.kind, columns) && areOverviewItems(props.kind, props.items): {
        return (
          <GenericExportTable<FleetOverviewAlarm>
            {...props}
            title={t(ROUTES.ONETOOL_CHANNELS_ALARMS.fragment)}
            data={props.items}
            columns={columns}
            exportData={true}
            selection={true}
          />
        );
      }
    }
  }, [props, columns, t]);

  return (
    <>
      {table}
      {props.slots && props.slots.dialog()}
    </>
  );
}
