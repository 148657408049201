/* eslint-disable no-redeclare */
import { Column } from '@material-table/core';
import { Avatar, Box, Button, Chip, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router';
import RelatedEntityLabel from '@/components/Shared/Chips/RelatedEntityChip';
import TagIcon from '@mui/icons-material/Tag';
import { FleetAlarm } from '../typings';
import { SEVERITY } from '@/shared/constants';
import BlipChip from '@/components/Shared/Chips/BlipChip';
import { DeviceAlarm } from '../../Devices/DevicesPanel/typings';

import TypographyWithTooltip from '@/components/Shared/Tooltip';
import { columnsBuilder, defaultSearchByDate, withExcludedColumns } from '@/components/Shared/Tables/utils';
import { TFunction } from 'i18next';
import { Fragment, useCallback } from 'react';
import { getPath } from '@/shared/utils';
import { AlarmKind } from './AlarmsTable';
import { FleetItem } from '@culligan-iot/domain/culligan/api/device/fleet';
import { FleetOverviewAlarm } from '../../Overview/utils';
import { Device } from '@culligan-iot/domain/culligan/device/class/index';
import { Alarm } from '@culligan-iot/domain/culligan/device/capability/alarm';

type UseAlarmColumnsProps<K extends AlarmKind> = {
  kind: K;
  onDetailClick?: (deviceId: string) => void;
  excludeColumns?: Array<string>;
};

export function useAlarmColumns<K extends AlarmKind>(
  props: UseAlarmColumnsProps<K>
): Column<Device['alarms'][number]>[] | Column<FleetAlarm>[] | Column<FleetOverviewAlarm>[] {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleNavigate = useCallback(
    (deviceId: string) => {
      navigate(getPath('FLEET_DEVICE', { deviceId }));
    },
    [navigate]
  );

  switch (props.kind) {
    case 'device':
      return withExcludedColumns(buildDeviceAlarmsColumns(t, props.onDetailClick), props.excludeColumns);
    case 'fleet':
      return withExcludedColumns(buildFleetAlarmsColumns(t, handleNavigate, props.onDetailClick), props.excludeColumns);
    case 'overview':
      return withExcludedColumns(
        buildFleetOverviewAlarmsColumns(t, handleNavigate, props.onDetailClick),
        props.excludeColumns
      );
    default:
      throw new Error(`Unsupported alarm kind: ${props.kind}`);
  }
}

const buildFleetAlarmsColumns = (
  t: TFunction<'translation', undefined, 'translation'>,
  handleNavigateDevice: (deviceId: string) => void,
  handleDetail?: (id: FleetAlarm['id']) => void
): Column<FleetAlarm>[] => {
  const builder = columnsBuilder<FleetAlarm>();

  builder
    .addColumn({
      title: <TypographyWithTooltip anchorText={t('severity')} tooltipText={t('severityTooltip')} />,
      field: 'severity',
      exportTransformer: (alarm) => (alarm.severity ? t(alarm.severity) : t('unknown')),
      customExport: (alarm) => {
        return alarm.severity ? t(alarm.severity) : t('unknown');
      },
      render: (alarm) => {
        if (!alarm.severity) {
          return (
            <Chip
              avatar={
                <Avatar sx={{ bgcolor: 'transparent' }}>
                  <Box
                    sx={(theme) => ({
                      height: '80%',
                      bgcolor: theme.palette.grey[600],
                      width: '80%',
                      borderRadius: '50%',
                    })}
                  />
                </Avatar>
              }
              label={t('unknown')}
              size="small"
              color="default"
              variant="filled"
            />
          );
        }

        return (
          <Chip
            avatar={
              <Avatar sx={{ bgcolor: 'transparent' }}>
                <Box
                  sx={(theme) => ({
                    height: '80%',
                    bgcolor:
                      alarm.severity === SEVERITY.CRITICAL ? theme.palette.warning.dark : theme.palette.caution.dark,
                    width: '80%',
                    borderRadius: '50%',
                  })}
                />
              </Avatar>
            }
            label={t(alarm.severity)}
            size="small"
            variant="filled"
            color={alarm.severity === SEVERITY.CRITICAL ? 'warning' : 'caution'}
          />
        );
      },
    })
    .addColumn({
      title: t('date'),
      field: 'time',
      exportTransformer: (alarm) => dayjs(alarm.time).format('L'),
      render: (alarm) => (
        <>
          <Typography>{dayjs(alarm.time).format('L')}</Typography>
          <Typography variant="caption">{dayjs(alarm.time).fromNow()}</Typography>
        </>
      ),
    })
    .addColumn({
      title: t('name'),
      field: 'name',
      exportTransformer: (alarm) => alarm.name || alarm.id,
      render: (alarm) => {
        return (
          <>
            <Typography>{alarm.name}</Typography>
            {alarm.alarmId && <Chip icon={<TagIcon />} label={alarm.alarmId} size="small" />}
          </>
        );
      },
    })
    .addColumn({
      title: t('device'),
      field: 'deviceId',
      exportTransformer: (alarm: FleetAlarm) => alarm.deviceName,
      render: (alarm: FleetAlarm) => (
        <RelatedEntityLabel
          text={alarm.deviceName || (t('device') as string)}
          onEntityClick={() => handleNavigateDevice(alarm.deviceId)}
        />
      ),
    })
    .addColumn({
      title: <TypographyWithTooltip anchorText={t('status')} tooltipText={t('statusTooltip')} />,
      field: 'dismissed',
      exportTransformer: (alarm) => {
        const statusAlarm = alarm.dismissed ? 'resolved' : 'active';
        return statusAlarm;
      },
      render: (alarm) => (
        <>
          {alarm.dismissed && (
            <Chip
              avatar={
                <Avatar sx={{ bgcolor: 'transparent' }}>
                  <Box
                    sx={(theme) => ({
                      height: '80%',
                      bgcolor: theme.palette.success.light,
                      width: '80%',
                      borderRadius: '50%',
                    })}
                  />
                </Avatar>
              }
              label={t('resolved')}
              size="small"
              color="success"
              variant="outlined"
            />
          )}
          {!alarm.dismissed && <BlipChip label={t('active')} size="small" color="error" variant="outlined" />}
          {alarm.dismissed && (
            <>
              <br />
              <Typography variant="caption">{dayjs(alarm.dismissedAt).fromNow()}</Typography>
            </>
          )}
        </>
      ),
    })
    .addColumn({
      title: t('actions'),
      render: (alarm: FleetAlarm | DeviceAlarm) => (
        <Box display="flex" gap={1}>
          <Button variant="outlined" color="primary" onClick={() => handleDetail && handleDetail(alarm.id)}>
            {t('view')}
          </Button>
        </Box>
      ),
      sorting: false,
      searchable: false,
    });
  return builder.columns;
};

const buildDeviceAlarmsColumns = (
  t: TFunction<'translation', undefined, 'translation'>,
  handleDetail?: (id: Alarm['id']) => void
): Column<Device['alarms'][number]>[] => {
  const builder = columnsBuilder<Device['alarms'][number]>();

  builder
    .addColumn({
      title: <TypographyWithTooltip anchorText={t('severity')} tooltipText={t('severityTooltip')} />,
      field: 'severity',
      exportTransformer: (alarm) => t(alarm.constructor.severity),
      customExport: (alarm) => {
        return t(alarm.constructor.severity);
      },
      render: (alarm) => {
        return (
          <Chip
            avatar={
              <Avatar sx={{ bgcolor: 'transparent' }}>
                <Box
                  sx={(theme) => ({
                    height: '80%',
                    bgcolor:
                      alarm.constructor.severity === 'critical'
                        ? theme.palette.warning.light
                        : theme.palette.caution.light,
                    width: '80%',
                    borderRadius: '50%',
                  })}
                />
              </Avatar>
            }
            label={t(alarm.constructor.severity)}
            size="small"
            color={alarm.constructor.severity === 'critical' ? 'warning' : 'caution'}
            variant="filled"
          />
        );
      },
    })
    .addColumn({
      title: t('reportingDate'),
      field: 'time',
      sorting: true,
      defaultSort: 'desc',
      exportTransformer: (alarm) => dayjs(alarm.time).format('L'),
      render: (alarm) => (
        <>
          <Typography>{dayjs(alarm.time).format('L')}</Typography>
          <Typography variant="caption">{dayjs(alarm.time).fromNow()}</Typography>
        </>
      ),
    })
    .addColumn({
      title: t('name'),
      field: 'name',
      exportTransformer: (alarm) => alarm.name,
      render: (alarm) => (
        <Fragment>
          <Tooltip title={alarm.constructor.description}>
            <Typography>{alarm.name}</Typography>
          </Tooltip>
          <Chip icon={<TagIcon />} label={alarm.id} size="small" />
        </Fragment>
      ),
    })
    .addColumn({
      title: <TypographyWithTooltip anchorText={t('status')} tooltipText={t('statusTooltip')} />,
      exportTransformer: () => t('active'),
      field: 'dismissed',
      render: () => <BlipChip label={t('active')} size="small" color="error" variant="outlined" />,
    })
    .addColumn({
      title: t('actions'),
      field: 'actions',
      render: (alarm) => (
        <Box display="flex" gap={1}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => typeof handleDetail === 'function' && handleDetail(alarm.id)}
          >
            {t('view')}
          </Button>
        </Box>
      ),
      sorting: false,
      searchable: false,
    });

  return builder.columns;
};

const buildFleetOverviewAlarmsColumns = (
  t: TFunction<'translation', undefined, 'translation'>,
  handleNavigateDevice: (deviceId: string) => void,
  handleDetail?: (id: FleetAlarm['id']) => void
): Column<FleetOverviewAlarm>[] => {
  const builder = columnsBuilder<FleetOverviewAlarm>();
  builder
    .addColumn({
      title: <TypographyWithTooltip anchorText={t('severity')} tooltipText={t('severityTooltip')} />,
      field: 'severity',
      exportTransformer: (alarm: FleetOverviewAlarm) => t(alarm.severity),
      customExport: (alarm: FleetOverviewAlarm) => {
        return t(alarm.severity);
      },
      customFilterAndSearch: (filter: string, alarm: FleetOverviewAlarm) => {
        return alarm.severity.includes(filter);
      },
      render: (alarm) => {
        return (
          <Chip
            avatar={
              <Avatar sx={{ bgcolor: 'transparent' }}>
                <Box
                  sx={(theme) => ({
                    height: '80%',
                    bgcolor:
                      alarm.severity === SEVERITY.CRITICAL ? theme.palette.warning.dark : theme.palette.caution.dark,
                    width: '80%',
                    borderRadius: '50%',
                  })}
                />
              </Avatar>
            }
            label={t(alarm.severity)}
            size="small"
            variant="filled"
            color={alarm.severity === SEVERITY.CRITICAL ? 'warning' : 'caution'}
          />
        );
      },
    })
    .addColumn({
      title: t('date'),
      customFilterAndSearch: (filter: string, rowData: FleetItem['alarms'][number]) =>
        defaultSearchByDate(filter, dayjs(rowData.time)),
      field: 'time',
      exportTransformer: (alarm: FleetItem['alarms'][number]) => dayjs(alarm.time).format('L'),
      render: (alarm: FleetItem['alarms'][number]) => (
        <>
          <Typography>{dayjs(alarm.time).format('L')}</Typography>
          <Typography variant="caption">{dayjs(alarm.time).fromNow()}</Typography>
        </>
      ),
    })
    .addColumn({
      title: t('name'),
      field: 'name',
      exportTransformer: (alarm: FleetItem['alarms'][number]) => alarm.name,
      render: (alarm: FleetItem['alarms'][number]) => {
        return (
          <>
            <Typography>{alarm.name}</Typography>
            <Chip icon={<TagIcon />} label={alarm.name} size="small" />
          </>
        );
      },
    })
    .addColumn({
      title: t('device'),
      field: 'deviceId',
      exportTransformer: (alarm: FleetItem['alarms'][number]) => alarm.deviceId,
      render: (alarm: FleetItem['alarms'][number]) => (
        <RelatedEntityLabel
          text={alarm.deviceId || (t('device') as string)}
          onEntityClick={() => handleNavigateDevice(alarm.deviceId)}
        />
      ),
    })
    .addColumn({
      title: <TypographyWithTooltip anchorText={t('status')} tooltipText={t('statusTooltip')} />,
      exportTransformer: (_: FleetItem['alarms'][number]): string => t('active'),
      field: 'dismissed',
      render: () => <BlipChip label={t('active')} size="small" color="error" variant="outlined" />,
    })
    .addColumn({
      title: t('actions'),
      render: (alarm: FleetItem['alarms'][number]) => (
        <Box display="flex" gap={1}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => typeof handleDetail === 'function' && handleDetail(alarm.id)}
          >
            {t('view')}
          </Button>
        </Box>
      ),
      sorting: false,
      searchable: false,
    });
  return builder.columns;
};
