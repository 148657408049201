import { ApiResult } from '@typings';
import apiSlice, { API_VERSION } from '../apiSlice';
import { DeviceConsumable } from '@/components/Fleet/Devices/DevicesPanel/typings';
import { dispatchPendingToOperationsSlice } from '../dispatchPendingToOperations';
import i18next from 'i18next';
import { getNumberTh } from '@/redux/utils';

const URL_PREFIX = `${API_VERSION.v2}/admin/device/consumable`;

const consumablesApiSlice = apiSlice.enhanceEndpoints({ addTagTypes: ['FleetConsumables', 'Fleet'] }).injectEndpoints({
  endpoints: (builder) => ({
    patchSetConsumable: builder.mutation<
      ApiResult<DeviceConsumable>,
      { serialNumber: string; body: Partial<DeviceConsumable> }
    >({
      query: ({ serialNumber, body }) => ({
        url: `${URL_PREFIX}/set`,
        method: 'PATCH',
        body: body,
        params: { serialNumber },
      }),
      onQueryStarted: (arg, api) => {
        dispatchPendingToOperationsSlice(api, {
          url_prefix: `${URL_PREFIX}/set`,
          method: 'PATCH',
          subject: i18next.t('consumableSlotN', { number: getNumberTh(Number(arg.body.index) + 1) }),
          entity: arg.serialNumber,
        });
      },
      invalidatesTags: ['FleetConsumables', 'Fleet'],
    }),
    patchRemoveConsumable: builder.mutation<ApiResult<DeviceConsumable>, { serialNumber: string; index: number }>({
      query: ({ serialNumber, index }) => ({
        url: `${URL_PREFIX}/remove`,
        method: 'PATCH',
        body: { index },
        params: { serialNumber },
      }),
      onQueryStarted: (arg, api) => {
        dispatchPendingToOperationsSlice(api, {
          url_prefix: `${URL_PREFIX}/remove`,
          method: 'PATCH',
          subject: arg.serialNumber,
          entity: i18next.t('consumableSlotN', { number: getNumberTh(Number(arg.index) + 1) }),
        });
      },
      invalidatesTags: ['FleetConsumables', 'Fleet'],
    }),
    patchDepleteConsumable: builder.mutation<
      ApiResult<DeviceConsumable>,
      { serialNumber: string; body: Partial<DeviceConsumable> }
    >({
      query: ({ serialNumber, body }) => ({
        url: `${URL_PREFIX}/deplete`,
        method: 'PATCH',
        body: body,
        params: { serialNumber },
      }),
      onQueryStarted: (arg, api) => {
        dispatchPendingToOperationsSlice(api, {
          url_prefix: `${URL_PREFIX}/deplete`,
          method: 'PATCH',
          subject: i18next.t('consumableSlotN', { number: getNumberTh(Number(arg.body.index) + 1) }),
          entity: arg.serialNumber,
        });
      },
      invalidatesTags: ['FleetConsumables', 'Fleet'],
    }),
  }),
  overrideExisting: 'throw',
});

export default consumablesApiSlice;
export const { usePatchSetConsumableMutation, usePatchRemoveConsumableMutation, usePatchDepleteConsumableMutation } =
  consumablesApiSlice;
