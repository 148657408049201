import AreaContainer, { AreaBody, AreaHeader } from '@/components/Shared/Card/Area';
import { Chip, Typography } from '@mui/material';
import React from 'react';
import { Tag } from '@mui/icons-material';
import { Subtitle, Title } from '@/components/Shared/Card/Area/Text';
import { Device } from '@culligan-iot/domain/culligan/device/class/index';
import { useTranslation } from 'react-i18next';
export default function Identity({ consumable }: { consumable: NonNullable<Device['consumables'][number]> }) {
  const { t } = useTranslation();
  return (
    <AreaContainer>
      <AreaHeader>
        <Subtitle>{t('subset')}</Subtitle>
        {consumable?.subset && (
          <Chip
            icon={<Tag />}
            sx={(theme) => ({
              backgroundColor: theme.palette.background.grayShades[0],
            })}
            label={consumable.subset.id}
            size="small"
            color="default"
            variant="outlined"
          />
        )}
      </AreaHeader>
      <AreaBody>
        {consumable?.subset.name && <Title>{consumable.subset.name}</Title>}
        {consumable?.subset && 'description' in consumable.subset && (
          <Typography variant="body2" color="text.primary">
            {consumable.subset.description}
          </Typography>
        )}

        {consumable?.state && 'batch' in consumable.state && (
          <>
            <Typography variant="body2" color="text.secondary" sx={{ marginTop: 2 }}>
              {t('batch')}
            </Typography>
            <Typography variant="body2" color="text.primary">
              {consumable.state.batch}
            </Typography>
          </>
        )}
      </AreaBody>
    </AreaContainer>
  );
}
