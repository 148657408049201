import DeviceTelemetryPanel from '@/components/Fleet/Devices/DevicePanel/TelemetryPanel';
import { useContext, useMemo } from 'react';
import { DeviceContext } from './DeviceContext';
import { parseDecodedTelemetries } from '@/shared/domain/telemetry';

export default function Telemetry() {
  const context = useContext(DeviceContext);
  const sensors = useMemo(() => {
    if (!context.data || !context.data.latestTelemetry) {
      return [];
    }
    return parseDecodedTelemetries(context.data);
  }, [context.data]);

  return (
    <DeviceTelemetryPanel
      isLoading={context.isLoading}
      isError={context.isError}
      error={context.error}
      sensors={sensors}
      lastUpdate={context.data?.lastTelemetryUpdate ? context.data.lastTelemetryUpdate.getTime() : undefined}
    />
  );
}
