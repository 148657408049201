import { Box, Typography, useTheme } from '@mui/material';
import OverviewCard from './OverviewPanel/DeviceCardInfo';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import { useTranslation } from 'react-i18next';
import { DeviceConnectionHistoryItem, DeviceError } from '../DevicesPanel/typings';
import { OverviewCardData } from './typings';
import { useContext, useMemo } from 'react';
import { useNavigate } from 'react-router';
import ConnectionsGraph from './ConnectionsGraph';
import CustomerServiceCard from './OverviewPanel/CustomerServiceCard';
import useIsTablet from '@/hooks/useIsTablet';
import { ROLES, ROUTES } from '@/shared/constants';
import { User } from '@typings';
import dayjs from 'dayjs';
import DeviceConsumableCardOverview from './OverviewPanel/ConsumableCard/index';
import { getPath } from '@/shared/utils';
import AlarmsTable from '../../AlarmsAndErrors/Alarms/AlarmsTable';
import ErrorsTable from '../../AlarmsAndErrors/Errors/ErrorsTable';
import DeviceInfoCard from './OverviewPanel/InfoCard';
import ProfileCard from './OverviewPanel/ProfileCard';
import {
  usePostLogLevelCommandMutation,
  usePostOperatingModeCommandMutation,
  usePostPowerProfileCommandMutation,
  usePostRebootCommandMutation,
} from '@/redux/api/admin/deviceCommandsApiSlice';

import { usePostOTAJobMutation } from '@/redux/api/admin/otaApiSlice';
import LoyaltyProgramTable from './OverviewPanel/LoyaltyProgramTable';
import useDeviceInfoPollings from './OverviewPanel/ProfileCard/useDeviceInfoPollings';
import { Device } from '@culligan-iot/domain/culligan/device/class/index';
import { useGetLoyaltyProgramsQuery } from '@/redux/api/system/loyaltyProgramsApiSlice';
import { DeviceContext } from '@/router/pages/Fleet/Devices/Device/DeviceContext';

export type ParsedInfo = {
  deviceInfo?: {
    id: Device['id'];
    name: Device['constructor']['label'];
    model: Device['model'];
    status: Device['connectionStatus'];
    firmwareVersion: Device['swVersion'];
    operatingMode: Device['state']['operatingMode'];
    connectivity: Device['constructor']['connectivity'];
  };
  customer?: OverviewCardData;
  locationInfo: {
    [key in string]: string;
  };
};

function DevicePanel({
  device,
  customer,
  connectionHistory,
}: {
  device: Device;
  customer: User;
  connectionHistory: DeviceConnectionHistoryItem[];
}) {
  const { t } = useTranslation();
  const context = useContext(DeviceContext);
  const navigate = useNavigate();
  const isTablet = useIsTablet();
  const theme = useTheme();
  const [postOperatingMode, operatingModeFlags] = usePostOperatingModeCommandMutation();
  const [postPowerProfile, powerProfileFlags] = usePostPowerProfileCommandMutation();
  const [postLogLevel, logLevelFlags] = usePostLogLevelCommandMutation();
  const [postReboot, rebootFlags] = usePostRebootCommandMutation();
  const [postOTAJob, otaJobFlags] = usePostOTAJobMutation();
  const _flags = {
    postOperatingModeCommand: operatingModeFlags,
    postPowerProfileCommand: powerProfileFlags,
    postLogLevel: logLevelFlags,
    postOTAJob: otaJobFlags,
    postRebootCommand: rebootFlags,
  };
  const { otaPollingRequestedAt } = useDeviceInfoPollings({ flags: _flags, serialNumber: device?.id || '' });
  const { data: loyaltyProgramsData } = useGetLoyaltyProgramsQuery();
  const deviceLoyaltyPrograms =
    loyaltyProgramsData?._tag === 'Right' &&
    loyaltyProgramsData.right.success &&
    loyaltyProgramsData.right.data.items.length > 0
      ? loyaltyProgramsData.right.data.items
          .filter((lp) => device?.loyaltyPrograms?.includes(lp.id))
          .map((lp) => ({ ...lp, createdAt: new Date(lp.createdAt), updatedAt: new Date(lp.updatedAt) }))
      : [];
  const isLoyaltyProgramActive = device && device?.loyaltyPrograms && device?.loyaltyPrograms?.length > 0;
  const activeConsumables = device && device.consumables && device.consumables.filter((c) => !!c);

  const info = useMemo(() => {
    if (device) {
      const status = device?.connectionStatus.online ? t('connected') : t('disconnected');
      const lastUpdate = dayjs(device.lastStateUpdate).format('YYYY/MM/DD') || t('notAvailable');
      let c: OverviewCardData;
      if (customer) {
        c = {
          [t('fullName')]: `${customer.firstName} ${customer.lastName}`,
          [t('mobile')]: customer.mobilePhoneNumber,
          [t('email')]: customer.email,
          [t('location')]: customer.city,
          [t('address')]: customer.address,
          [t('company')]: customer.company,
        };
      } else {
        c = { [t('noCustomerKey')]: t('noCustomerValue')! };
      }
      const deviceAddress = device.installationAddress?.address || t('notAvailable');
      return {
        deviceInfo: {
          [t('id')]: device.id ?? '',
          [t('name')]: device.constructor.name,
          [t('model')]: device.model,
          [t('status')]: `${status}\n(last update: ${lastUpdate})`,
          [t('firmwareVersion')]: device.swVersion || 'N/A',
          [t('operatingMode')]: device.state.operatingMode || 'N/A',
          [t('connectivity')]: device.constructor.connectivity || 'N/A',
        },
        customer: c,
        locationInfo: {
          [t('address')]: deviceAddress,
        },
      };
    }
  }, [device, customer, t]);

  return info?.deviceInfo ? (
    <Box
      display={isTablet ? 'flex' : 'grid'}
      flexDirection={'column'}
      gridTemplateColumns="repeat(auto-fill, minmax(33rem, 1fr))"
      alignItems="stretch"
      justifyItems={'stretch'}
      columnGap="2rem"
      rowGap="2rem"
      sx={{
        [theme.breakpoints.up(1600)]: {
          gridTemplateColumns: 'repeat(2, 1fr)',
        },
        [theme.breakpoints.up(1800)]: {
          gridTemplateColumns: 'repeat(3, 1fr)',
        },
      }}
    >
      <DeviceInfoCard device={device} />

      <ProfileCard
        device={device}
        otaPollingRequestAt={otaPollingRequestedAt}
        postOTAJob={postOTAJob}
        postLogLevel={postLogLevel}
        postReboot={postReboot}
        postOperatingMode={postOperatingMode}
        postPowerProfile={postPowerProfile}
      />

      {customer && customer.globalRoles.length === 1 && customer.globalRoles.includes(ROLES.USER) ? (
        <CustomerServiceCard />
      ) : (
        <OverviewCard
          id="deviceCustomerInfoCard"
          title={t('customer')}
          textAlign={'center'}
          onActionClick={Object.keys(info.customer).length > 1 ? () => undefined : undefined}
        >
          {`${t('noCustomerForDevice')}`}
        </OverviewCard>
      )}

      <OverviewCard
        id="deviceAlarmsAndErrorsCard"
        title={t(ROUTES.FLEET_DEVICE_ALARMS.fragment)}
        onActionClick={() =>
          navigate(getPath('FLEET_DEVICE_ALARMS', { deviceId: device.id }), {
            state: { switchTab: true },
          })
        }
        actionLabel={t('seeAll')!}
        contentContainer="box"
        cardContentProps={{ sx: { p: 0 } }}
        sx={{
          p: 0,
          justifyContent: 'normal',
          '> div div': { justifyContent: 'normal' },
          '& table': { tableLayout: 'fixed !important' },
          '& table td': { overflow: 'hidden' },
        }}
      >
        <AlarmsTable
          items={device.alarms || []}
          isLoading={context.isLoading}
          isError={context.isError}
          kind={'device'}
          options={{ toolbar: false, selection: false, paging: true, pageSize: 4 }}
          excludeColumns={['actions', 'deviceName']}
        />
      </OverviewCard>

      <OverviewCard
        id="deviceErrorsCard"
        title={t('errors')}
        actionLabel={t('seeAll')!}
        cardContentProps={{ sx: { p: 0 } }}
        contentContainer="box"
        onActionClick={() => {
          navigate(getPath('FLEET_DEVICE_ERRORS', { deviceId: device.id }), {
            state: { switchTab: true },
          });
        }}
      >
        <ErrorsTable
          isLoading={context.isLoading}
          isError={context.isError}
          error={context.error}
          items={(device?.errors as DeviceError[]) || []} // todo: remove `as` when modeled in the domain.
          kind={'device'}
          options={{ toolbar: false, selection: false, paging: true, pageSize: 4 }}
        />
      </OverviewCard>

      <OverviewCard
        title="Consumables"
        actionLabel={t('seeAll')!}
        contentContainer="card"
        sx={{
          justifyContent: 'normal',
        }}
        cardContentProps={{ sx: { p: 0 } }}
        onActionClick={() => {
          navigate(getPath('FLEET_DEVICE_CONSUMABLES', { deviceId: device.id }), {
            state: { switchTab: true },
          });
        }}
      >
        {activeConsumables.length > 0 ? (
          activeConsumables.map((consumable) => <DeviceConsumableCardOverview consumable={consumable} />)
        ) : (
          <Typography variant="body1" textAlign={'center'}>
            {t('noConsumablesForDevice')}
          </Typography>
        )}
      </OverviewCard>

      <OverviewCard
        id="loyaltyProgramCard"
        actionLabel={t('seeAll')!}
        title={t('loyalty_programs')}
        cardContentProps={{ sx: { p: 0 } }}
        contentContainer="box"
        onActionClick={() => {
          navigate(getPath('FLEET_DEVICE_LOYALTY_PROGRAMS', { deviceId: device.id }), {
            state: { switchTab: true },
          });
        }}
      >
        {isLoyaltyProgramActive ? (
          <LoyaltyProgramTable
            items={deviceLoyaltyPrograms}
            id={device.id}
            isFleet={false}
            options={{ toolbar: false, selection: false, paging: false, search: false }}
            excludeColumns={['updatedAt', 'createdAt', 'edit', 'delete']}
          />
        ) : (
          <Typography variant="body1" textAlign={'center'}>
            {t('noLoyaltyProgramForDevice')}
          </Typography>
        )}
      </OverviewCard>

      <OverviewCard
        id="deviceConnectionHistoryCard"
        title={t('connectionHistory')}
        sx={{
          gridColumnStart: 1,
          gridColumnEnd: -1,
        }}
        cardContentProps={{
          sx: {
            ':last-child': {
              p: theme.spacing(2),
            },
          },
        }}
      >
        {connectionHistory.length > 0 ? (
          <ConnectionsGraph history={connectionHistory} />
        ) : (
          <Typography variant="body1" textAlign={'center'}>
            {t('noConnectionHistoryForDevice')}
          </Typography>
        )}
      </OverviewCard>
    </Box>
  ) : null;
}

export default withErrorLoadingManagement(DevicePanel);
