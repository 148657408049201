import { Avatar, Chip } from '@mui/material';
import Box from '@mui/material/Box';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useTranslation } from 'react-i18next';
import DialogContentLabel from '@/components/Shared/AlarmDialog/DialogContentLabel';
import TypographyWithTooltip from '@/components/Shared/Tooltip';
import { Device } from '@culligan-iot/domain/culligan/device/class/index';

dayjs.extend(relativeTime);

const DeviceIssueDetails = ({ data }: { data: Device['alarms'][number] }) => {
  const { t } = useTranslation();

  return (
    <>
      <DialogContent sx={{ p: '2rem', py: '2rem', pb: '1rem' }}>
        <Box display="flex" gap={1}>
          <Box>
            <Chip
              avatar={
                <Avatar sx={{ bgcolor: 'transparent' }}>
                  <Box
                    sx={(theme) => ({
                      height: '80%',
                      bgcolor:
                        data.constructor.severity === 'critical'
                          ? theme.palette.warning.light
                          : theme.palette.caution.light,
                      width: '80%',
                      borderRadius: '50%',
                    })}
                  />
                </Avatar>
              }
              label={t(data.constructor.severity)}
              size="small"
              color={data.constructor.severity === 'critical' ? 'warning' : 'caution'}
              variant="filled"
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogContent sx={{ p: 0, px: '2rem', pb: '1rem' }}>
        <DialogContentLabel>{t('name')}:</DialogContentLabel>
        <DialogContentText textAlign="left" variant="body2">
          {data.name}
        </DialogContentText>
      </DialogContent>
      <DialogContent sx={{ p: '2rem', py: '0', pb: '1rem' }}>
        <Box display="flex" justifyContent="space-between" gap={'1rem'}>
          <Box>
            <TypographyWithTooltip
              anchorText={`${t('reportingDate')}: `}
              slots={{
                AnchorTextTypography: DialogContentLabel,
              }}
              tooltipText={t('reportingDateTooltip')}
            />

            <Box display="flex" gap={'1rem'} alignItems="baseline">
              <DialogContentText>{dayjs(data.time).format('YYYY/MM/DD')}</DialogContentText>
              <Box>
                <DialogContentText variant="body2" fontStyle="italic">
                  {dayjs(data.time).fromNow()}
                </DialogContentText>
              </Box>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogContent sx={{ p: '2rem', py: '0', pb: '1rem' }}>
        <DialogContentLabel>{t('description')}:</DialogContentLabel>
        <Box display="flex" gap={'1rem'} alignItems="baseline">
          <DialogContentText>{data.constructor.description}</DialogContentText>
        </Box>
      </DialogContent>
      <DialogContent sx={{ p: '2rem', py: '0', pb: '1rem' }}>
        <DialogContentLabel>{t('id')}:</DialogContentLabel>
        <DialogContentText textAlign="left" variant="caption">
          {data.id}
        </DialogContentText>
      </DialogContent>
    </>
  );
};

export default DeviceIssueDetails;
