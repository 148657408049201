import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { alpha, Box, Typography } from '@mui/material';
import { Map, useMap } from '@vis.gl/react-google-maps';
import { NotEmptyList, OneOf, WithSlots } from '@typings';
import { Marker } from '@googlemaps/markerclusterer/dist/marker-utils';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import InfoPanel, { InfoPanelProps } from './InfoPanel';
import { isListOf } from '@/shared/utils';
import { getBound, isAccuracyLatLngLiteral, isUnknownData } from './utils';
import { AccuracyLatLngLiteral, UnknownData } from './typings';
import CulliganMarker, { MarkerProps } from './CulliganMarker';

function isNotEmptyListOfUnknownData(data: unknown): data is UnknownData[] {
  return Array.isArray(data) && data.every(isUnknownData);
}

type CulliganMapDataArray<TData> = OneOf<
  [
    {
      coordinate: AccuracyLatLngLiteral;
      id: string;
    },
    {
      coordinates: NotEmptyList<AccuracyLatLngLiteral>;
      id: string;
    },
    {
      data: TData[];
      decode: (data: TData) => AccuracyLatLngLiteral | undefined;
      createMarker: (
        data: MarkerProps<TData> & { onClick: (data: ReactNode) => void }
      ) => ReturnType<typeof CulliganMarker> | undefined;
      id: string;
      noDataMessage: string;
    }
  ]
>;

const CulliganMap = <TData,>(
  props: WithSlots<
    CulliganMapDataArray<TData>,
    {
      InfoPanel: (props: InfoPanelProps<TData>) => ReturnType<typeof InfoPanel>;
    }
  >
) => {
  const { data, coordinate, coordinates, id, decode, createMarker } = props;
  const [markers, setMarkers] = useState<{ [key: string]: Marker }>({});

  const map = useMap();
  const clusterer = useMemo(() => {
    if (!map) return null;

    return new MarkerClusterer({ map });
  }, [map]);

  useEffect(() => {
    if (!clusterer) return;

    clusterer.clearMarkers();
    clusterer.addMarkers(Object.values(markers));
  }, [clusterer, markers]);

  // this callback will effectively get passsed as ref to the markers to keep
  // tracks of markers currently on the map
  const setMarkerRef = useCallback((marker: Marker | null, key: string) => {
    setMarkers((markers) => {
      if ((marker && markers[key]) || (!marker && !markers[key])) return markers;

      if (marker) {
        return { ...markers, [key]: marker };
      } else {
        const { [key]: _, ...newMarkers } = markers;

        return newMarkers;
      }
    });
  }, []);

  const defaultMapProps = useMemo(
    () => ({
      mapId: `${id}`,
      disableDefaultUI: true,
      zoomControl: true,
    }),
    [id]
  );

  const [infoPanelContent, setInfoPanelContent] = useState<ReactNode>();

  const renderContainer = useCallback(
    (map: JSX.Element, infoPanelContent: ReactNode, marker?: TData) => {
      const INFO_PANEL = props.slots?.InfoPanel ?? InfoPanel;

      const sx = { position: 'relative', height: '100%' };

      if (!marker) {
        return (
          <Box sx={sx}>
            {map}
            <InfoPanel
              onClose={() => {
                setSelectedMarker(undefined);
                setInfoPanelContent(undefined);
              }}
              marker={undefined}
            >
              {infoPanelContent}
            </InfoPanel>
          </Box>
        );
      }

      return (
        <Box sx={sx}>
          {map}
          {infoPanelContent && (
            <INFO_PANEL
              onClose={() => {
                setSelectedMarker(undefined);
                setInfoPanelContent(undefined);
              }}
              marker={marker}
            >
              {infoPanelContent}
            </INFO_PANEL>
          )}
        </Box>
      );
    },
    [props.slots?.InfoPanel]
  );

  const [selectedMarker, setSelectedMarker] = useState<string | undefined>();

  switch (true) {
    case isAccuracyLatLngLiteral(coordinate):
      return (
        <Map defaultBounds={getBound(coordinate)} {...defaultMapProps}>
          <CulliganMarker data={coordinate} id={id} isSelected={false} />
        </Map>
      );
    case isListOf<AccuracyLatLngLiteral>(coordinates, isAccuracyLatLngLiteral):
      return renderContainer(
        <Map defaultBounds={getBound(coordinates)} {...defaultMapProps}>
          {coordinates.map((accuracyLatLng, i) => (
            <CulliganMarker
              isSelected={`${id}-${i}-marker` === selectedMarker}
              data={accuracyLatLng}
              id={`${id}-${i}-marker`}
              key={`${id}-${i}-marker`}
              setMarkerRef={setMarkerRef}
              onClick={(...params) => {
                setSelectedMarker(`${id}-${i}-marker`);
                return setInfoPanelContent(params);
              }}
            />
          ))}
        </Map>,
        infoPanelContent
      );
    case isNotEmptyListOfUnknownData(data): {
      const coordinates = data.flatMap((marker) => decode!(marker) || []);
      const bounds = (coordinates.length > 0 && getBound([coordinates[0], ...coordinates])) || undefined;

      if (!coordinates.length) {
        return (
          <Box sx={{ position: 'relative', height: '100%' }}>
            <Map disableDefaultUI={true}></Map>
            <Box
              sx={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                bgcolor: (theme) => alpha(theme.palette.background.default, 0.7),
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography>{props.noDataMessage}</Typography>
            </Box>
          </Box>
        );
      }

      return renderContainer(
        <Map defaultBounds={bounds} {...defaultMapProps}>
          {data.map((marker, i) =>
            createMarker!({
              isSelected: `${id}-${i}-marker` === selectedMarker,
              data: marker,
              setMarkerRef,
              id: `${id}-${i}-marker`,
              onClick: (...params) => {
                setSelectedMarker(`${id}-${i}-marker`);
                return setInfoPanelContent(params);
              },
            })
          )}
        </Map>,
        infoPanelContent,
        data[0]
      );
    }
    default:
      return <Map></Map>;
  }
};

export default CulliganMap;
