import { Either, Match, Option } from 'effect';
import { isValidNumber } from '../utils';
import {
  isDepletable,
  isExpendable,
  SlottedConsumable,
} from '@culligan-iot/domain/culligan/device/class/slotted-consumable';
import { withClampedPercentage, withZeroDecimalRound } from './numbers';

/**
 * @description
 * Safely calculates the usage ratio of a consumable.
 * The usage ratio is represented as a number starting from `0` to `1.0` and beyond in case of expendables.
 * In the depletable consumables the ratio should be between `0` and `1.0`.
 * This is because the usage of the expendables is not really finite, like a liquid.
 * They have an expected range exhaust, but they can be used beyond that.
 * The depletable consumables have a finite range, and the usage should be between rangeFullCapacity
 * and the range exhaust.
 *
 */
export const getUsageRatio = Match.type<SlottedConsumable>().pipe(
  Match.when(isDepletable, (c) => {
    return calculateDepleatableUsageRatio({
      rangeFullCapacity: c.subset.rangeFullCapacity.amount,
      level: c.state.level,
      rangeExhaust: c.subset.rangeExhaust.amount,
    });
  }),
  Match.when(isExpendable, (c) => {
    return calculateExpendableUsageRatio({
      usage: c.state.usage,
      rangeExhaust: c.subset.rangeExhaust.amount,
    });
  }),
  Match.exhaustive
);

/**
 * @description Safely calculates the usage percentage of a consumable.
 * @param consumable
 * @returns An Option containing the usage percentage of the consumable, if calculable.
 */
export const getRemainingUsagePercentage = (consumable: SlottedConsumable) =>
  /**
   * Get the usage ratio of the consumable, if calculable.
   */
  getUsageRatio(consumable).pipe(
    /**
     * Convert the ratio to a percentage.
     */
    Option.map((ratio) => 100 - ratio * 100),
    /**
     * Clamp the percentage between 0 and 100 and remove the extra decimals.
     */
    Option.map((percentage) => withClampedPercentage(withZeroDecimalRound(percentage)))
  );

/**
 * @description Calculates the usage ratio of a depletable consumable.
 * @returns An `Option` of a number between 0 and 1.0 representing the usage ratio, in case the computation is possible.
 */
export const calculateDepleatableUsageRatio = ({
  rangeFullCapacity,
  level,
  rangeExhaust,
}: {
  rangeFullCapacity: number;
  level: number;
  rangeExhaust: number;
}) => {
  const denominator = rangeFullCapacity - rangeExhaust;
  if (denominator === 0) {
    return Option.none();
  }
  const numerator = rangeFullCapacity - level;
  const result = numerator / denominator;
  return Number.isFinite(result) ? Option.some(result) : Option.none();
};

/**
 *
 * @description Calculates the usage ratio of an expendable consumable.
 * @returns An `Option` of a number starting from 0 and that can got beyond 1.0 representing the usage ratio,
 * in case the computation is possible.
 */
export const calculateExpendableUsageRatio = ({ usage, rangeExhaust }: { usage: number; rangeExhaust: number }) => {
  const result = usage / rangeExhaust;
  return Number.isFinite(result) ? Option.some(result) : Option.none();
};

/**
 *
 * @description Calculates where the consumable is in its lifecycle, given its expiration date.
 * @returns
 */
const getExpirationProgressPercentage = (
  installedAt: Date,
  expiresAt: Date,
  now: Date
): Either.Either<number, Error> => {
  if (expiresAt <= installedAt) {
    return Either.left(new Error('Expiration date must be after installation date'));
  }
  const installed = installedAt.getTime();
  const expires = expiresAt.getTime();
  const current = now.getTime();

  if (current <= installed) return Either.right(0);
  if (current >= expires) return Either.right(100);

  const totalTime = expires - installed;
  const progress = current - installed;
  const result = Math.floor((progress / totalTime) * 100);
  return isValidNumber(result) && Number.isFinite(result)
    ? Either.right(result)
    : Either.left(new Error('Invalid number'));
};

export { getExpirationProgressPercentage };
