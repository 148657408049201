import Area, { AREA_DESIGN_TOKENS, AreaBody, AreaHeader } from '@/components/Shared/Card/Area';
import { Subtitle, Title } from '@/components/Shared/Card/Area/Text';
import TagIcon from '@mui/icons-material/Tag';
import { Box, Chip, SxProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CopyChip from '@/components/Shared/Chips/CopyChip';
import TypographyWithTooltip from '@/components/Shared/Tooltip';
import { Device } from '@culligan-iot/domain/culligan/device/class/index';
import { Quench } from '@culligan-iot/domain/culligan/device/vendor/index';

function Identity({
  id,
  code,
  model,
  name,
  sx,
  manufacturingSerialNumber,
  qNumber,
}: {
  id: Device['id'];
  model: Device['model'];
  code: Device['vendor']['code'];
  name: string;
  sx?: SxProps;
  manufacturingSerialNumber: Device['manufacturingSerialNumber'];
  qNumber?: Quench.DeviceInfo['qNumber'];
}) {
  const { t } = useTranslation();

  return (
    <Area sx={sx ? sx : {}}>
      <AreaHeader>
        <TypographyWithTooltip
          anchorText={t('identity')}
          slots={{
            AnchorTextTypography: Subtitle,
          }}
          tooltipText={t('deviceIdentityTooltip')}
        />
      </AreaHeader>
      <AreaBody>
        <Title>{`${name || '--'}`}</Title>
        <Box
          sx={{ fontWeight: 'bold', paddingTop: '0.5rem' }}
          display="flex"
          flexWrap="wrap"
          gap={AREA_DESIGN_TOKENS.gap}
          alignItems="flex-end"
        >
          <CopyChip
            icon={<TagIcon color="primary" />}
            sx={{
              color: '#2968fe',
              backgroundColor: '#e9eff6',
              border: 'solid 1px',
              borderColor: '#2968fe',
            }}
            label={id}
          />
          <CopyChip label={code + model || '--'} icon={<TagIcon />} />
          {qNumber ? (
            <CopyChip
              sx={{ fontWeight: 'bold' }}
              label={qNumber}
              icon={<TagIcon sx={{ rotate: '270deg', width: '1rem' }} />}
            />
          ) : (
            <Chip icon={<TagIcon sx={{ rotate: '270deg', width: '1rem' }} />} label={t('qUnassigned')} size="small" />
          )}
          {manufacturingSerialNumber ? (
            <CopyChip
              sx={{ fontWeight: 'bold' }}
              label={manufacturingSerialNumber}
              icon={<TagIcon sx={{ rotate: '270deg', width: '1rem' }} />}
            />
          ) : (
            <Chip
              icon={<TagIcon sx={{ rotate: '270deg', width: '1rem' }} />}
              label={t('manufacturingSerialNumberUnassigned')}
              size="small"
            />
          )}
        </Box>
      </AreaBody>
    </Area>
  );
}

export default Identity;
