import { SensorWithTelemetry } from './typings';

export const parseDeviceTelemetry = (sensors: SensorWithTelemetry[]) => {
  const sensorsWithGroup = sensors?.filter(({ sensor }) => sensor.type.tags.length > 0);
  const sensorWithoutGroup = sensors?.filter(({ sensor }) => sensor.type.tags.length === 0);
  const telemetriesTags = [...new Set((sensorsWithGroup || []).flatMap(({ sensor }) => sensor.type.tags))];

  const sensorGroups = telemetriesTags
    .filter((tag) => tag)
    .map((tag) => ({
      tag: tag,
      items: (sensorsWithGroup || []).filter(({ sensor }) => sensor.type.tags.findIndex((t) => t === tag) !== -1),
    }))
    .filter((group) => group && group.items.length)
    .sort((curr, next) => (next?.items.length || 0) - (curr?.items.length || 0));

  const getMergedSensors = () => {
    const merged = [];
    merged.push(
      ...(sensorWithoutGroup?.map(({ id, sensor, telemetry }) => {
        return {
          id: id,
          name: sensor.name,
          sensor: sensor,
          type: 'single' as const,
          telemetry: telemetry,
          items: undefined,
        };
      }) || []),
      ...(sensorGroups?.map((_group) => ({
        id: _group?.tag,
        name: _group?.tag,
        type: 'group' as const,
        telemetry: undefined,
        sensor: undefined,
        tag: _group?.tag,
        items: _group?.items.map(({ id, sensor, telemetry }) => {
          return {
            id: id,
            sensor: sensor,
            name: sensor.name,
            type: 'single' as const,
            telemetry: telemetry,
            items: undefined,
          };
        }),
      })) || [])
    );

    return merged;
  };

  return {
    sensorWithoutGroup,
    sensorGroups,
    mergedSensors: getMergedSensors(),
  } as const;
};
