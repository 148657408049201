import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import useAddEditDialog from '@/hooks/useAddEditDialog';
import useConfirmDialog from '@/hooks/useConfirmDialog';
import { useParams } from 'react-router';
import {
  usePatchDepleteConsumableMutation,
  usePatchRemoveConsumableMutation,
  usePatchSetConsumableMutation,
} from '@/redux/api/fleet/consumablesApiSlice';
import { useLazyGetConsumableSubsetsQuery } from '@/redux/api/system/consumableSubsetsApiSlice';
import { AddEditDialogConfigs } from '@/components/Shared/AddEditDialog/typings';
import VacantSlot from './Slots/VacantSlot';
import { ACTION_STATUS } from './constants';
import OccupiedSlot from './Slots/OccupiedSlot';
import { Device } from '@culligan-iot/domain/culligan/device/class/index';

const ACTIONS = {
  None: 'none',
  Fill: 'fill',
  Set: 'set',
  Deplete: 'deplete',
  Remove: 'remove',
} as const;

export default function DeviceConsumableCard({
  consumable,
  slot,
  slotCount,
  isDeviceConnected,
}: {
  consumable: Device['consumables'][number];
  slot: Device['constructor']['slots'][number];
  slotCount: number;
  isDeviceConnected: boolean;
}) {
  const { t } = useTranslation();
  const [patchSetConsumable, patchSetConsumableFlags] = usePatchSetConsumableMutation();
  const [patchRemoveConsumable, patchRemoveConsumableFlags] = usePatchRemoveConsumableMutation();
  const [patchDepleteConsumable, patchDepleteConsumableFlags] = usePatchDepleteConsumableMutation();

  const { deviceId } = useParams();

  const [getConsumableSubsets] = useLazyGetConsumableSubsetsQuery();

  const [performedAction, setPerformedAction] = useState<string | undefined>();

  const [actionStatus, setActionStatus] = useState<(typeof ACTION_STATUS)[keyof typeof ACTION_STATUS]>(
    ACTION_STATUS.Uninitialized
  );
  const [lastAction, setLastAction] = useState<(typeof ACTIONS)[keyof typeof ACTIONS]>(ACTIONS.None);

  useEffect(() => {
    if (lastAction === ACTIONS.Deplete) {
      setActionStatus(
        (patchDepleteConsumableFlags.isLoading && ACTION_STATUS.Loading) ||
          (patchDepleteConsumableFlags.isError && ACTION_STATUS.Error) ||
          (patchDepleteConsumableFlags.isSuccess && ACTION_STATUS.Success) ||
          ACTION_STATUS.Uninitialized
      );
    }
    if (lastAction === ACTIONS.Fill || lastAction === ACTIONS.Set) {
      setActionStatus(
        (patchSetConsumableFlags.isLoading && ACTION_STATUS.Loading) ||
          (patchSetConsumableFlags.isError && ACTION_STATUS.Error) ||
          (patchSetConsumableFlags.isSuccess && ACTION_STATUS.Success) ||
          ACTION_STATUS.Uninitialized
      );
    }
    if (lastAction === ACTIONS.Remove) {
      setActionStatus(
        (patchRemoveConsumableFlags.isLoading && ACTION_STATUS.Loading) ||
          (patchRemoveConsumableFlags.isError && ACTION_STATUS.Error) ||
          (patchRemoveConsumableFlags.isSuccess && ACTION_STATUS.Success) ||
          ACTION_STATUS.Uninitialized
      );
    }
  }, [patchSetConsumableFlags, patchRemoveConsumableFlags, patchDepleteConsumableFlags, lastAction]);

  const { AddEditDialog, openDialog } = useAddEditDialog({
    title: t('consumableSubsets'),
    baseConfig: [
      {
        name: 'subsetId',
        placeholder: t('consumableSubset'),
        options: { required: t('fieldRequiredError') as string },
        type: 'autocomplete',
        mutations: {
          onChange: {
            impacts: {
              fields: [
                {
                  name: 'monthsUntilExpiration',
                  handler: async ({ getValues, setValue }) => {
                    const value = getValues('subsetId');
                    const subsets = await getConsumableSubsets(null, true);
                    const subset = subsets.data?.data?.items.find((s: any) => s.id === value.value);

                    setValue('monthsUntilExpiration', subset?.lifespan ? Math.floor(subset?.lifespan / 30) : undefined);
                    setValue(
                      'litersUntilExpiration',
                      subset?.rangeFullCapacity ? Math.floor(subset?.rangeFullCapacity) : undefined
                    );
                  },
                },
              ],
            },
          },
        },
        selectConfig: {
          options: () => {
            return getConsumableSubsets(null, true).then((subsets) => {
              return (
                subsets.data?.data?.items
                  .filter((data) => {
                    return slot.filter((s) => s.id === data.consumable).length > 0;
                  })
                  .map((s) => ({
                    label: s.name,
                    value: s.id,
                  })) || []
              );
            });
          },
        },
      },
      {
        name: 'monthsUntilExpiration',
        type: 'number',
        placeholder: t('monthsUntilExpiration'),
        options: {
          required: t('fieldRequiredError') as string,
        },
        domain: {
          min: 0,
        },
      },
      {
        name: 'litersUntilExpiration',
        type: 'number',
        placeholder: t('litersUntilExpiration'),
        options: {
          required: t('fieldRequiredError') as string,
        },
        domain: {
          min: 0,
        },
      },
      {
        type: 'date',
        name: 'expiresAt',
        options: { required: t('fieldRequiredError') as string },
        helperText: t('selectDate') as string,
      },
      {
        name: 'batch',
        placeholder: t('batch'),
        options: {
          required: (t('fieldRequiredError') as string)
            ? consumable?.subset.constructor.category === 'Enhancement'
            : false,
        },
      },
    ],
    mutations: {
      onMount: {
        impacts: {
          form: {
            handler: (_, initialConfig) => {
              if (consumable?.subset.constructor.category === 'Filter') {
                const updatedConfig = initialConfig.reduce<AddEditDialogConfigs>((acc, curr) => {
                  if (curr.name === 'expiresAt') {
                    return acc;
                  }

                  return [...acc, curr];
                }, []);

                return updatedConfig;
              }

              const updatedConfig = initialConfig.reduce<AddEditDialogConfigs>((acc, curr) => {
                if (curr.name === 'monthsUntilExpiration' || curr.name === 'litersUntilExpiration') {
                  return acc;
                }

                return [...acc, curr];
              }, []);

              return updatedConfig;
            },
          },
        },
      },
    },
    onSubmit: async (_, data: any) => {
      const submitData = { ...data };

      submitData.expiresAt = dayjs(submitData.expiresAt)
        .add(submitData.monthsUntilExpiration || 0, 'months')
        .valueOf();
      submitData.index = slotCount - 1;
      submitData.rangeFullCapacity = submitData.litersUntilExpiration;

      submitData.serialNumber = deviceId;

      const subsets = await getConsumableSubsets(null, true);
      const currentSubset = subsets.data?.data?.items.find((s) => s.id === submitData.subsetId);
      const name = currentSubset?.name;
      const currentLevel = currentSubset?.rangeFullCapacity;

      delete submitData.monthsUntilExpiration;
      delete submitData.litersUntilExpiration;

      setLastAction(ACTIONS.Set);
      setActionStatus(ACTION_STATUS.Loading);
      setPerformedAction(t('settingConsumableXXX', { number: submitData.index, name }) as string);
      await patchSetConsumable({
        serialNumber: deviceId!,
        body: { ...submitData, installedAt: dayjs().valueOf(), current: currentLevel },
      });
    },
  });

  const { AddEditDialog: ReplenishDialog, openDialog: openReplenishDialog } = useAddEditDialog({
    title: t('consumableSubsets'),
    baseConfig: [
      {
        type: 'date',
        name: 'expiresAt',
        options: { required: t('fieldRequiredError') as string },
        helperText: t('selectDate') as string,
      },
      {
        name: 'batch',
        placeholder: t('batch'),
        options: {
          required: (t('fieldRequiredError') as string)
            ? consumable?.subset.constructor.category === 'Enhancement'
            : false,
        },
      },
    ],
    getEditData: async () => {
      /**
       * TODO: Implement setConsumables with domain model.
       */

      return {};
    },
    onSubmit: async (dialogId: string | boolean, data: any) => {
      const submitData = { ...data };

      submitData.expiresAt = dayjs(submitData.expiresAt).valueOf();
      submitData.index = slotCount - 1;

      submitData.serialNumber = deviceId;

      submitData.subsetId = consumable?.subset.id;

      const subsets = await getConsumableSubsets(null, true);
      const currentSubset = subsets.data?.data?.items.find((s) => s.id === submitData.subsetId);
      const name = currentSubset?.name;
      const currentLevel = currentSubset?.rangeFullCapacity;

      setLastAction(ACTIONS.Fill);
      setActionStatus(ACTION_STATUS.Loading);
      setPerformedAction(t('replenishingConsumableXXX', { number: submitData.index, name }) as string);
      await patchSetConsumable({
        serialNumber: deviceId!,
        body: { ...submitData, installedAt: dayjs().valueOf(), current: currentLevel },
      });
    },
  });

  const { ConfirmDialog, confirm } = useConfirmDialog({
    title: t('empty') + ' ' + t('consumableSubsets'),
    message: (extra: any) =>
      t('deleteMessage', { entity: t('consumableSubsets'), name: consumable?.subset?.name }) as string,
    onConfirm: async (extra: any) => {
      setLastAction(ACTIONS.Remove);
      setActionStatus('loading');
      setPerformedAction(t('removingConsumable', { number: extra.index, name: consumable?.subset?.name }) as string);
      await patchRemoveConsumable({ serialNumber: extra.serialNumber, index: extra.index });
    },
  });

  const { ConfirmDialog: ConfirmDepleteDialog, confirm: confirmDepleteDialog } = useConfirmDialog({
    title: t('empty') + ' ' + t('consumableSubsets'),
    message: (extra: any) =>
      t('depleteMessage', { entity: t('consumableSubsets'), name: consumable?.subset?.name }) as string,
    onConfirm: async (extra: any) => {
      setLastAction(ACTIONS.Deplete);
      setActionStatus(ACTION_STATUS.Loading);
      setPerformedAction(
        t('depletingConsumableXXX', { number: slotCount - 1, name: consumable?.subset?.name }) as string
      );
      await patchDepleteConsumable({ serialNumber: deviceId!, body: { index: slotCount - 1 } });
    },
  });

  const handleClickEdit = () => {
    isDeviceConnected === true && openDialog(consumable?.subset?.id);
  };

  const handleClickRemove = () => {
    confirm({ serialNumber: deviceId!, index: slotCount - 1 });
  };

  const handleClickSetMax = async (subsetId: string) => {
    openReplenishDialog(subsetId);
  };

  const handleClickSetMin = () => {
    confirmDepleteDialog({ serialNumber: deviceId!, index: slotCount - 1 });
  };

  useEffect(() => {
    if (actionStatus === ACTION_STATUS.Error) {
      setDismissErrorAnimation(false);
    }
  }, [actionStatus]);

  const [dismissErrorAnimation, setDismissErrorAnimation] = useState(false);

  return (
    <>
      {consumable?.subset?.id ? (
        <OccupiedSlot
          actionStatus={actionStatus}
          dismissErrorAnimation={dismissErrorAnimation}
          handleClickEdit={handleClickEdit}
          handleClickRemove={handleClickRemove}
          handleClickSetMax={handleClickSetMax}
          handleClickSetMin={handleClickSetMin}
          index={slotCount}
          isDeviceConnected={isDeviceConnected}
          setDismissErrorAnimation={setDismissErrorAnimation}
          consumable={consumable}
          performedAction={performedAction}
          slot={slot}
        />
      ) : (
        <VacantSlot
          index={slotCount}
          handleClickEdit={handleClickEdit}
          isDeviceConnected={isDeviceConnected}
          actionStatus={actionStatus}
          performedAction={performedAction}
          consumable={consumable}
          slot={slot}
        />
      )}
      <ReplenishDialog />
      <AddEditDialog />
      <ConfirmDialog />
      <ConfirmDepleteDialog />
    </>
  );
}
