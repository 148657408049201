import { Device } from '@culligan-iot/domain/culligan/device/class/index';
import { createContext } from 'react';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

export type DeviceContextT = {
  isLoading: boolean;
  isError: boolean;
  consumables: Device['consumables'] | undefined;
  data: Device | undefined;
  slots: Device['constructor']['slots'] | undefined;
  error: SerializedError | FetchBaseQueryError | undefined;
};

export const DeviceContext = createContext<DeviceContextT>({
  isLoading: true,
  isError: false,
  consumables: undefined,
  data: undefined,
  slots: undefined,
  error: undefined,
});
