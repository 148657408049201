import { ApiResult } from '@typings';
import { AlarmRes } from '@/components/OneTools/Channels/Alarms/typings';
import apiSlice, { API_VERSION } from '../apiSlice';
import { CommonIsEntityUniqueBase, CommonIsEntityUniqueRes } from '@/components/OneTools/typings';

const URL_PREFIX = `${API_VERSION.v2}/system/alarms`;

export const AlarmsApiSlice = apiSlice.enhanceEndpoints({ addTagTypes: ['Alarms'] }).injectEndpoints({
  endpoints: (builder) => ({
    getAlarms: builder.query<ApiResult<{ items: AlarmRes[] }>, void>({
      query: () => URL_PREFIX + '/list',
      providesTags: ['Alarms'],
    }),
    getAlarm: builder.query<ApiResult<AlarmRes>, string>({
      query: (id: string) => ({
        url: URL_PREFIX,
        params: { id },
      }),
      providesTags: ['Alarms'],
    }),
    isAlarmUnique: builder.query<ApiResult<CommonIsEntityUniqueRes>, CommonIsEntityUniqueBase>({
      query: (params: CommonIsEntityUniqueBase) => ({
        url: `${URL_PREFIX}/unique`,
        params,
      }),
    }),
  }),
  overrideExisting: 'throw',
});

export const { useLazyGetAlarmsQuery, useGetAlarmsQuery, useLazyGetAlarmQuery, useLazyIsAlarmUniqueQuery } =
  AlarmsApiSlice;
