import Slot from '../../Base/Slot';
import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { Device } from '@culligan-iot/domain/culligan/device/class/index';
import { getExpirationProgressPercentage } from '@/shared/domain/consumables';
import { isExpirable } from '@culligan-iot/domain/culligan/device/capability/consumable';
import ExpirationInfo from './ExpirationInfo';
import { Either, Option, pipe } from 'effect';
import { isSome } from 'effect/Option';
import { Fragment, useState } from 'react';
import ExpirationProgress from './ExpirationProgress';

export default function Expiration({ consumable }: { consumable: NonNullable<Device['consumables'][number]> }) {
  const [error, setError] = useState({ error: false, message: '' });

  const expirationPercentage = pipe(
    consumable.state,
    Option.liftPredicate((state) => isExpirable(state)),
    Option.flatMap(({ installedAt, expiresAt }) =>
      pipe(
        getExpirationProgressPercentage(installedAt, expiresAt, new Date()),
        Either.match({
          onLeft: (left) => {
            if (!error.error) setError({ error: true, message: left.message });
            return Option.none();
          },
          onRight: (right) => {
            if (error.error) setError({ error: false, message: '' });
            return Option.some(right);
          },
        })
      )
    )
  );

  const expirationPercentageAsString = expirationPercentage.pipe(
    Option.match({
      onSome: (percentage) => `${percentage}%`,
      onNone: () => '0%',
    })
  );

  const isExpired = expirationPercentage.pipe(
    Option.map((percentage) => percentage >= 100),
    Option.getOrElse(() => false)
  );

  return (
    <Slot
      sx={(theme) => ({
        position: 'relative',
        overflow: 'hidden',
        '&:before': {
          content: isSome(expirationPercentage) && expirationPercentage.value >= 0 ? '""' : 'none',
          backgroundColor: theme.palette.background.grayShades[1],
          width: '16px',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
        },
        '&:after': {
          content: isExpired ? '""' : 'none',
          backgroundColor: theme.palette.background.grayShades[1],
          width: '16px',
          height: '100%',
          position: 'absolute',
          top: 0,
          right: 0,
        },
        '& > *:not([data-target="consumable-progress"])': {
          zIndex: 1,
        },
      })}
    >
      {!error.error ? (
        <Box
          data-target="consumable-progress"
          sx={{
            position: 'absolute',
            top: 0,
            left: '16px',
            height: '100%',
            width: 'calc(100% - 32px)',
          }}
        >
          <Box
            sx={(theme) => ({
              position: 'relative',
              width: expirationPercentageAsString,
              height: '100%',
              backgroundColor: theme.palette.background.grayShades[1],
              transition: 'all 300ms ease-in-out',
            })}
          ></Box>
        </Box>
      ) : (
        <Fragment>
          <Typography sx={(theme) => ({ color: theme.palette.error['light'] })}>{`Error: ${error.message}`}</Typography>
          <Typography variant="caption" sx={(theme) => ({ color: theme.palette.error['light'] })}>
            Installed at: <span>{dayjs(consumable.state.installedAt).toString()}</span>
          </Typography>
          {isExpirable(consumable.state) && (
            <Typography variant="caption" sx={(theme) => ({ color: theme.palette.error['light'] })}>
              Expires at: <span>{dayjs(consumable.state.expiresAt).toString()}</span>
            </Typography>
          )}
        </Fragment>
      )}

      {isExpirable(consumable.state) && !error.error && (
        <ExpirationInfo expiresAt={consumable.state.expiresAt} isExpired={isExpired} />
      )}
      {isSome(expirationPercentage) && !error.error && Option.isSome(expirationPercentage) && (
        <ExpirationProgress
          expirationPercentage={expirationPercentage.value}
          expirationPercentageAsString={expirationPercentageAsString}
        />
      )}
    </Slot>
  );
}
