import AlarmsTable from '@/components/Fleet/AlarmsAndErrors/Alarms/AlarmsTable';
import { Box } from '@mui/material';
import { useCallback, useContext, useState } from 'react';
import { DeviceContext } from './DeviceContext';
import useCulliganDialog from '@/hooks/useCulliganDialog';
import { useTranslation } from 'react-i18next';
import { Alarm } from '@culligan-iot/domain/culligan/device/capability/alarm';
import { Device } from '@culligan-iot/domain/culligan/device/class/index';
import DeviceIssueDetails from '@/components/Fleet/Devices/DevicePanel/AlarmsPanel/DeviceIssueDetails';

export default function Alarms() {
  const { t } = useTranslation();
  const context = useContext(DeviceContext);
  const [selectedAlarm, setSelectedAlarm] = useState<Device['alarms'][number] | null>(null);
  const [open, setOpen] = useState(false);
  const handleDetailClick = useCallback(
    (id: Alarm['id']) => {
      setSelectedAlarm(context?.data?.alarms.find((a) => a.id === id) || null);
      setOpen(true);
    },
    [context]
  );

  const { dialog } = useCulliganDialog({
    open: open,
    handleClose: () => setOpen(false),
    tabs: [
      {
        id: 'issue',
        label: t('alarm'),
        body: open && selectedAlarm && context.data && <DeviceIssueDetails data={selectedAlarm} />,
      },
    ],
    styles: {
      bodyContainer: { p: 0, width: '25rem' },
    },
  });

  return (
    <Box>
      {dialog}
      <AlarmsTable
        onDetailClick={handleDetailClick}
        items={context.data?.alarms || []}
        kind="device"
        isError={context.isError}
        isLoading={context.isLoading}
        error={context.error}
      />
    </Box>
  );
}
