import Area, { AreaBody, AreaHeader } from '@/components/Shared/Card/Area';
import { Body, Subtitle } from '@/components/Shared/Card/Area/Text';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { clamp } from '@/shared/utils';
import { yellow } from '@mui/material/colors';
import { Option } from 'effect';

export default function ConsumableUsage({ usage }: { usage: Option.Option<number> }) {
  const { t } = useTranslation();
  const _usage = Option.getOrElse(() => 0)(usage);
  return (
    <Area
      sx={{
        position: 'relative',
        overflow: 'hidden',
        height: 'auto',
        flexGrow: 1,
        '&::before': {
          content: '""',
          top: 0,
          left: 0,
          position: 'absolute',
          width: `${clamp(0, 100 - _usage || 0, 100)}%`,
          height: '100%',
          backgroundColor: (theme) => theme.palette.grey[300],
          zIndex: 0,
        },
      }}
    >
      <AreaHeader sx={{ zIndex: 1, position: 'relative' }}>
        <Subtitle>{t('usage')}</Subtitle>
      </AreaHeader>
      <AreaBody sx={{ zIndex: 1, position: 'relative' }}>
        <Body>{`${100 - _usage}%`}</Body>

        <Box
          sx={{
            height: '5px',
            borderRadius: 2,
            backgroundImage: (theme) =>
              `linear-gradient(90deg, ${theme.palette.success.light} 0%, ${yellow[500]} 24.5%, 
				${theme.palette.warning.light} 48.5%, ${theme.palette.error.light} 100%)`,
          }}
        />
      </AreaBody>
    </Area>
  );
}
