import { Match, Number as N } from 'effect';
import * as d3 from 'd3-format';

export type NumberFormat =
  | 'FloatThreeDigits'
  | 'FloatTwoDigits'
  | 'FloatOneDigit'
  | 'Integer'
  | 'Percentage'
  | 'Scientific';

/**
 * @description Rounds a number to the nearest integer
 * @example
 * withZeroDecimalRound(0.1) → 0
 * withZeroDecimalRound(0.5) → 1
 * withZeroDecimalRound(0.9) → 1
 * withZeroDecimalRound(1) → 1
 */
export const withZeroDecimalRound = (value: number) => N.round(value, 0);

/**
 * @description Clamps a number between 0 and 100
 * @example
 * withClampedPercentage(0) → 0
 * withClampedPercentage(100) → 100
 * withClampedPercentage(101) → 100
 */
export const withClampedPercentage = N.clamp({ minimum: 0, maximum: 100 });

/**
 * @description A formatter for numbers with scientific notation
 *
 * @example
 * withScientificFormat(0.000001) → 1µ
 * withScientificFormat(0.001) → 1m
 * withScientificFormat(0) → 0
 * withScientificFormat(10) → 10
 * withScientificFormat(100) → 100
 * withScientificFormat(1000) → 1k
 * withScientificFormat(100000) → 100k
 * withScientificFormat(100000000) → 100M
 * withScientificFormat(100000000000000) → 100T
 */
export const withScientificFormat = d3.format('.3s');

/**
 * @description A formatter for numbers with percentage notation
 *
 * @example
 * withPercentageFormat(0.1) → 10%
 * withPercentageFormat(0.01) → 1%
 * withPercentageFormat(0.001) → 0%
 * withPercentageFormat(0) → 0%
 * withPercentageFormat(1) → 100%
 * withPercentageFormat(0.5) → 50%
 */
export const withPercentageFormat = d3.format('.0%');

/**
 * @description A formatter for with just the integer part of the number
 *
 * @example
 * withFloatToIntegerFormat(0.1) → 0
 * withFloatToIntegerFormat(0.01) → 0
 * withFloatToIntegerFormat(0.001) → 0
 * withFloatToIntegerFormat(0) → 0
 * withFloatToIntegerFormat(1) → 1
 * withFloatToIntegerFormat(0.5) → 0
 * withFloatToIntegerFormat(100.20) → 100
 * withFloatToIntegerFormat(1000.1003230) → 1000
 */
export const withIntegerFormat = d3.format('f');

/**
 * @description A formatter for numbers with one decimal
 *
 * @example
 * withFloatOneDecimalFormat(0.1) → 0.1
 * withFloatOneDecimalFormat(0.01) → 0.0
 * withFloatOneDecimalFormat(0.001) → 0.0
 * withFloatOneDecimalFormat(0) → 0.0
 * withFloatOneDecimalFormat(1) → 1.0
 * withFloatOneDecimalFormat(0.5) → 0.5
 * withFloatOneDecimalFormat(100.20) → 100.2
 */
export const withFloatOneDecimalFormat = d3.format('.1f');

/**
 * @description A formatter for numbers with two decimals
 *
 * @example
 * withFloatTwoDecimalsFormat(0.1) → 0.10
 * withFloatTwoDecimalsFormat(0.01) → 0.01
 * withFloatTwoDecimalsFormat(0.001) → 0.00
 * withFloatTwoDecimalsFormat(0) → 0.00
 * withFloatTwoDecimalsFormat(1) → 1.00
 * withFloatTwoDecimalsFormat(0.5) → 0.50
 * withFloatTwoDecimalsFormat(100.20) → 100.20
 * withFloatTwoDecimalsFormat(1000.1003230) → 1000.10
 */
export const withFloatTwoDecimalsFormat = d3.format('.2f');

/**
 * @description A formatter for numbers with three decimals
 *
 * @example
 * withFloatThreeDecimalsFormat(0.1) → 0.100
 * withFloatThreeDecimalsFormat(0.01) → 0.010
 * withFloatThreeDecimalsFormat(0.001) → 0.001
 * withFloatThreeDecimalsFormat(0) → 0.000
 * withFloatThreeDecimalsFormat(1) → 1.000
 * withFloatThreeDecimalsFormat(0.5) → 0.500
 * withFloatThreeDecimalsFormat(100.20) → 100.200
 * withFloatThreeDecimalsFormat(1000.1003230) → 1000.100
 */
export const withFloatThreeDecimalsFormat = d3.format('.3f');

/**
 * Formats a number based on the choosen format
 */
export const formatNumber = (value: number, format: NumberFormat) =>
  Match.value(format).pipe(
    Match.when('Percentage', () => withPercentageFormat(value)),
    Match.when('FloatThreeDigits', () => withFloatThreeDecimalsFormat(value)),
    Match.when('FloatTwoDigits', () => withFloatTwoDecimalsFormat(value)),
    Match.when('FloatOneDigit', () => withFloatOneDecimalFormat(value)),
    Match.when('Integer', () => withIntegerFormat(value)),
    Match.when('Scientific', () => withScientificFormat(value)),
    Match.orElse(() => withFloatTwoDecimalsFormat(value))
  );
