import { Consumable } from '@culligan-iot/domain/culligan/device/capability/consumable';
import React from 'react';
import Area, { AreaBody, AreaHeader } from '@/components/Shared/Card/Area';
import { Body, Subtitle } from '@/components/Shared/Card/Area/Text';
import CopyChip from '@/components/Shared/Chips/CopyChip';
import { useTranslation } from 'react-i18next';
import TagIcon from '@mui/icons-material/Tag';
import { Box } from '@mui/material';

export default function ConsumableIdentity({ id, name }: { id: Consumable['id']; name: Consumable['name'] }) {
  const { t } = useTranslation();
  return (
    <Area sx={{ flexGrow: 1 }}>
      <AreaHeader>
        <Subtitle>{t('name')}</Subtitle>
      </AreaHeader>
      <AreaBody>
        <Body>{name}</Body>
        <Box display="flex">
          <CopyChip icon={<TagIcon />} label={id} />
        </Box>
      </AreaBody>
    </Area>
  );
}
