import * as Vendor from '@culligan-iot/domain/culligan/device/vendor/index';
import { Schema as S } from 'effect';

const VendorName = S.Literal(Vendor.Adt.name, Vendor.Culligan.name, Vendor.Quench.name, Vendor.Zip.name);

export const brandNameMap = [Vendor.Adt, Vendor.Culligan, Vendor.Quench, Vendor.Zip].reduce<
  Map<string, typeof VendorName.Type>
>((acc, v) => {
  acc.set(v.code, v.name);
  return acc;
}, new Map());
