import { ITEM_TYPE, MergedTelemetry } from '../../../typings';

export type Range = { id: string; min: number; max: number };

export const getRanges = (deviceTelemetries?: MergedTelemetry[]): Range[] => {
  if (!deviceTelemetries) {
    return [];
  }

  return deviceTelemetries.reduce((acc, { id, type, items, telemetry, sensor }) => {
    if (type === ITEM_TYPE.SINGLE) {
      if (sensor.expectedValue._tag === 'Range') {
        /**
         * FIXME: This could be a bug.
         * The ranges object is not guaranteed to have both a min and max property.
         * It was filtered in the MergedTelemeries type, to fit the graph.
         * The expectedValue defined in the sensors can have also just a min or just a max property.
         * The graph should be able to handle this case.
         *  */
        acc.push({
          id,
          min: sensor.expectedValue.min,
          max: sensor.expectedValue.max,
        });

        return acc;
      }
      return acc;
    }

    if (type === ITEM_TYPE.GROUP) {
      if (items && items?.length) {
        const newAcc = [
          ...items.reduce((acc, { id, sensor }) => {
            if (sensor.expectedValue._tag === 'Range') {
              acc.push({
                id,
                min: sensor.expectedValue.min,
                max: sensor.expectedValue.max,
              });
              return acc;
            }
            return acc;
          }, [] as Range[]),
          ...acc,
        ];
        return newAcc;
      }
    }

    return acc;
  }, [] as Range[]);
};
