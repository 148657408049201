import { Device } from '@culligan-iot/domain/culligan/device/class/index';
import { Match, Record } from 'effect';
import { Sensor, Telemetry } from '@culligan-iot/domain/culligan/device/capability/telemetry';

/**
 * Parses the decoded telemetries of a device.
 * Important: this function is the first pass to parse the telemetry of a device.
 * So it's return type is directly coupled with the type of the telemetry.
 * If you need to modify the return type, you should also modify the type of the telemetry.
 * @param device The device to parse.
 * @returns An array of objects containing the sensor and its telemetry.
 */
const parseDecodedTelemetries = (device: Device): Array<{ id: string; sensor: Sensor; telemetry?: Telemetry }> =>
  Match.value(device).pipe(
    Match.when({ model: 'gbfc' }, (gbfc) =>
      Record.toEntries(gbfc.constructor.sensors).map(([key, sensor]) => {
        return {
          id: key,
          sensor: sensor,
          telemetry: gbfc.latestTelemetry[key],
        } as const;
      })
    ),
    Match.when({ model: 'fbfc' }, (pelican2) =>
      Record.toEntries(pelican2.constructor.sensors).map(([key, sensor]) => {
        return {
          id: key,
          sensor: sensor,
          telemetry: pelican2.latestTelemetry[key],
        } as const;
      })
    ),
    Match.when({ model: 'bbfc' }, (blackbox) =>
      Record.toEntries(blackbox.constructor.sensors).map(([key, sensor]) => {
        return {
          id: key,
          sensor: sensor,
          telemetry: blackbox.latestTelemetry[key],
        } as const;
      })
    ),
    Match.exhaustive
  );

export { parseDecodedTelemetries };
