import Area, { AreaBody, AreaHeader } from '@/components/Shared/Card/Area';
import { Subtitle, Title } from '@/components/Shared/Card/Area/Text';
import RenderIf from '@/components/Shared/RenderIf/RenderIf';
import { OperatingModeCommandPayload } from '@/redux/api/admin/deviceCommandsApiSlice';
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { NotEmptyList } from '@typings';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { OperatingMode as OperatingModeT } from '@culligan-iot/domain/culligan/device/class/base';
import CommandsButtonGroup from './CommandsButtonGroup';
import TypographyWithTooltip from '@/components/Shared/Tooltip';
import { Device } from '@culligan-iot/domain/culligan/device/class/index';
function OperatingMode({
  device,
  isAdmin,
  isInLoadingState,
  isLoading,
  isDeviceOnline,
  postOperatingMode,
}: {
  device: Device;
  isAdmin: boolean;
  isLoading: boolean;
  isInLoadingState: boolean;
  isDeviceOnline: boolean;
  postOperatingMode: MutationTrigger<OperatingModeCommandPayload & any>;
}) {
  const { t } = useTranslation();
  const supportedOperatingModes = device?.constructor.operatingModes;
  const getHasSupportedOperatingModes = (
    modes: Array<OperatingModeT> | undefined
  ): modes is NotEmptyList<OperatingModeT> => Boolean(supportedOperatingModes?.length) ?? false;
  const hasSupportedOperatingModes = getHasSupportedOperatingModes(supportedOperatingModes);
  return (
    <Fragment>
      <Area
        sx={{
          width: '100%',
          gridColumnStart: '1',
          gridColumnEnd: hasSupportedOperatingModes ? '3' : '4',
          gridRowStart: '1',
          gridRowEnd: '-1',
          borderRadius: 1,
          overflow: 'hidden',
        }}
      >
        <AreaHeader>
          <TypographyWithTooltip
            slots={{
              AnchorTextTypography: Subtitle,
            }}
            anchorText={t('operatingMode')}
            tooltipText={t('operatingModeTooltip')}
          />
        </AreaHeader>
        <AreaBody>
          <Title>{device.state.operatingMode}</Title>
        </AreaBody>
      </Area>
      <RenderIf condition={isAdmin && hasSupportedOperatingModes}>
        <CommandsButtonGroup
          serialNumber={device.id}
          operatingMode={device.state.operatingMode}
          isDeviceOnline={isDeviceOnline}
          postOperatingMode={postOperatingMode}
          isInLoadingState={isInLoadingState}
          supportedOperatingModes={supportedOperatingModes}
        />
      </RenderIf>
    </Fragment>
  );
}
export default React.memo(OperatingMode);
