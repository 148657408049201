import { Box, Button, Typography } from '@mui/material';
import TelemetryNumberCard from './TelemetryNumberCard';
import TelemetryBooleanCard from './TelemetryBooleanCard';
import { useTranslation } from 'react-i18next';
import { clamp } from '@/shared/utils';
import { TelemetryGroupContext } from './TelemetryGroupContext';
import useScrollWithOffset from '@/hooks/useScrollWithOffset';
import { HISTORYDATASECTION_ID } from '..';
import { CulliganCard } from '@/components/Shared/Card';
import { useTelemetryFiltersHandlerCtx } from '../TelemetryProvider';
import { isBooleanTelemetry, isNumberTelemetry, SensorWithTelemetry } from '../typings';
import { Sensor } from '@culligan-iot/domain/culligan/device/capability/telemetry';

const getTelemetryCard = (
  sensorWithTelemetry: SensorWithTelemetry,
  index: number,
  title: string,
  isEvenGroup: boolean,
  lastIndex: number
) => {
  return Sensor.$match({
    Number: (numberSensor) => (
      <TelemetryNumberCard
        group={true}
        title={title}
        double={index === lastIndex && !isEvenGroup}
        key={sensorWithTelemetry.id}
        sensorWithTelemetry={{
          id: sensorWithTelemetry.id,
          sensor: numberSensor,
          telemetry: isNumberTelemetry(sensorWithTelemetry.telemetry) ? sensorWithTelemetry.telemetry : undefined,
        }}
      />
    ),
    Boolean: (booleanSensor) => (
      <TelemetryBooleanCard
        key={sensorWithTelemetry.id}
        group={true}
        double={index === lastIndex && !isEvenGroup}
        title={title}
        sensorWithTelemetry={{
          id: sensorWithTelemetry.id,
          sensor: booleanSensor,
          telemetry: isBooleanTelemetry(sensorWithTelemetry.telemetry) ? sensorWithTelemetry.telemetry : undefined,
        }}
      />
    ),
  })(sensorWithTelemetry.sensor);
};
export default function TelemetryGroupCard({
  title,
  sensorGroup,
}: {
  title: string;
  sensorGroup: SensorWithTelemetry[];
}) {
  const { t } = useTranslation();
  const { handleAddTelemetryFilter } = useTelemetryFiltersHandlerCtx();
  const lastIndex = sensorGroup.length - 1;
  const isEvenGroup = sensorGroup.length % 2 === 0;
  const columns = clamp(Math.ceil(sensorGroup.length / 2), 2);
  const scrollElementIntoViewWithOffset = useScrollWithOffset(HISTORYDATASECTION_ID);

  return (
    <Box>
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'baseline'}>
        <Typography variant="h6" marginBottom={'1rem'}>
          {title}
        </Typography>
        <Button
          onClick={() => {
            scrollElementIntoViewWithOffset();
          }}
        >
          {t('history')}
        </Button>
      </Box>

      <TelemetryGroupContext.Provider value={{ setKeys: () => handleAddTelemetryFilter({ tagName: title, key: '' }) }}>
        <CulliganCard
          sx={{
            display: 'grid',
            alignItems: 'stretch',
            gridTemplateColumns: `repeat(${columns}, auto)`,
            gridTemplateRows: 'auto',
          }}
        >
          {sensorGroup.map((sg, index) => getTelemetryCard(sg, index, title, isEvenGroup, lastIndex))}
        </CulliganCard>
      </TelemetryGroupContext.Provider>
    </Box>
  );
}
