import StoreFilters from '@/components/Shared/Filters/StoreFilters';
import { StoreFilterConfig } from '@/components/Shared/Filters/typings';
import GenericExportTable from '@/components/Shared/Tables/GenericExportTable';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import withResetNavigationState from '@/components/Shared/withResetNavigationState';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useErrorColumns from './useErrorsColumns';
import { useLazyGetFilteredErrorsQuery } from '@/redux/api/fleet/errorsApiSlice';
import { FilterErrorRequest, FleetError } from '../typings';
import IssueDetails from '@/components/Shared/AlarmDialog/IssueDetails';
import useCulliganDialog from '@/hooks/useCulliganDialog';
import { Box, Paper, Skeleton } from '@mui/material';
import { ErrorsTableFields, useErrorsFilters } from './useErrorsFilters';
import CulliganTable from '@/components/Shared/Tables/CulliganTable';
import { Column, Query, QueryResult } from '@material-table/core';
import { DateRangeDefaultValue, toOneBasedIndex, toZeroBasedIndex } from '@/shared/utils';
import dayjs from 'dayjs';
import { FleetDevices } from '../../Devices/DevicesPanel/typings';

export const ErrorsTable = withErrorLoadingManagement(withResetNavigationState(GenericExportTable<FleetError>));

export default function ErrorsPanel() {
  const tableRef = useRef();
  const {
    filterConfig,
    handleFiltersApplied,
    handleFiltersCleared,
    getQueryFilters,
    handleOrderChange,
    handlePageChange,
    isLoadingFilters,
  } = useErrorsFilters(tableRef);
  const [getFilteredErrors, { isFetching, isLoading, data }] = useLazyGetFilteredErrorsQuery();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [selectedError, setSelectedError] = useState<FleetError | null>(null);
  const { brand, status, start, end, customer, model, page, size, orderBy, direction } = getQueryFilters();

  const { t } = useTranslation();

  const handleOpenDialog = (id: string) => {
    const error = data?.data?.items.find((error) => error.id === id) as FleetError;
    setSelectedError(error);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setSelectedError(null);
    setOpenDialog(false);
  };

  const columns = useErrorColumns({
    kind: 'fleet',
    onDetailClick: handleOpenDialog,
  });

  const getPayload = useCallback(
    (query: Query<FleetError>) => {
      const fallbackDateRange = new DateRangeDefaultValue(
        dayjs().subtract(1, 'month').startOf('day'),
        dayjs().endOf('day')
      );
      const sortCollection = query.orderByCollection.length ? query.orderByCollection[0] : null;
      const field = sortCollection ? columns[sortCollection?.orderBy].field : null;
      const direction = (sortCollection?.orderDirection as 'desc' | 'asc') || null;
      const hasSorting = field != null && direction != null;
      const payload: FilterErrorRequest = {
        start: start || +fallbackDateRange.start,
        end: end || +fallbackDateRange.end,
        status: status,
        customer: customer,
        brand: brand,
        // FIXME - This type coercion should be removed when this part will be refactored
        model: model as FleetDevices.Device['model'],
        page: toOneBasedIndex(query.page),
        size: query.pageSize,
      };

      if (hasSorting && field && direction) {
        payload.orderBy = field as ErrorsTableFields;
        payload.direction = direction as 'asc' | 'desc';
      }

      return payload;
    },
    [brand, columns, customer, end, model, start, status]
  );

  const handleFetchTableData = useCallback(
    (query: Query<FleetError>) =>
      new Promise<QueryResult<FleetError>>((resolve, reject) => {
        getFilteredErrors(getPayload(query), true)
          .unwrap()
          .then((result) => {
            const filteredItems = query.search
              ? result?.data?.items.filter((item) =>
                  Object.values(item).some((value) => String(value).toLowerCase().includes(query.search.toLowerCase()))
                )
              : result?.data?.items;

            return resolve({
              data: filteredItems || [],
              page: toZeroBasedIndex(result?.data?.page || 0),
              totalCount: result.data?.itemsCount || 0,
            });
          })
          .catch((err) => reject(err));
      }),
    [getFilteredErrors, getPayload]
  );

  const { dialog } = useCulliganDialog({
    open: openDialog,
    handleClose: handleCloseDialog,
    tabs: [
      {
        id: 'error',
        label: t('error'),
        body: selectedError && <IssueDetails data={selectedError} isFleet={true} />,
      },
    ],
    styles: {
      bodyContainer: { p: 0, width: '25rem' },
    },
  });
  const FiltersSkeleton = useMemo(
    () => (
      <Paper sx={{ padding: 2, marginBottom: '12px', display: 'flex', gap: '12px', height: 150, flex: 1 }}>
        {[...Array(filterConfig.length)].map((_, index) => (
          <Skeleton key={index} variant="rounded" width={'100%'} height={50} />
        ))}
      </Paper>
    ),
    [filterConfig.length]
  );

  return (
    <Box>
      {isLoadingFilters ? (
        FiltersSkeleton
      ) : (
        <StoreFilters
          filterConfigs={filterConfig as StoreFilterConfig[]}
          onFiltersApplied={handleFiltersApplied}
          onFiltersCleared={handleFiltersCleared}
        />
      )}

      <CulliganTable
        title={t('errors')}
        tableRef={tableRef}
        columns={columns as Column<FleetError>[]}
        data={handleFetchTableData}
        isLoading={isLoading || isFetching}
        onPageChange={handlePageChange}
        onOrderCollectionChange={(collection) => {
          handleOrderChange(
            columns[collection?.[0]?.orderBy]?.field as ErrorsTableFields,
            collection?.[0]?.orderDirection as 'asc' | 'desc'
          );
        }}
        options={{
          pageSize: size || 10,
          showTitle: false,
          initialPage: page,
          searchDebounceDelay: 400,
          defaultOrderByCollection: [
            { orderBy: columns.findIndex((el) => el.field === orderBy), orderDirection: direction || '', sortOrder: 1 },
          ],
        }}
      />

      {openDialog && dialog}
    </Box>
  );
}
