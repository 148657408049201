import { Column } from '@material-table/core';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { LoyaltyProgram } from '@culligan-iot/domain/culligan/user/loyalty';

export default function useLoyaltyProgramsColumns(
  excludeColumns?: string[],
  onDeleteClick?: Function
): Array<Column<LoyaltyProgram>> {
  const { t } = useTranslation();
  const columns_array: Array<Column<LoyaltyProgram>> = [];

  return columns_array.concat([
    {
      title: t('key'),
      field: 'id',
    },
    {
      title: t('name'),
      field: 'name',
    },
    {
      title: t('enabled'),
      field: 'enabled',
      render: (loyaltyProgram) => {
        return <Typography>{loyaltyProgram.enabled ? t('true') : t('false')}</Typography>;
      },
    },
    {
      title: t('type'),
      field: 'type',
      render: (loyaltyProgram) => {
        return <Typography>{t(loyaltyProgram.config?.type)}</Typography>;
      },
    },
    {
      title: t('value'),
      field: 'value',
      render: (loyaltyProgram) => {
        return <Typography>{loyaltyProgram.config?.value}</Typography>;
      },
    },
    {
      title: t('creationDate'),
      field: 'createdAt',
      hidden: excludeColumns?.includes('createdAt'),
      render: (loyaltyProgram) => dayjs(loyaltyProgram.createdAt).format('YYYY/MM/DD HH:mm'),
    },
    {
      title: t('lastUpdated'),
      field: 'updatedAt',
      hidden: excludeColumns?.includes('updatedAt'),
      render: (loyaltyProgram) => dayjs(loyaltyProgram.updatedAt).format('YYYY/MM/DD HH:mm'),
    },
    {
      title: t('actions'),
      width: '10%',
      render: (loyaltyProgram) => (
        <Box display="flex" justifyContent="flex-start" gap="0.5rem">
          <>
            {!excludeColumns?.includes('delete') ? (
              <Button
                variant="outlined"
                color="error"
                onClick={onDeleteClick && (() => onDeleteClick({ id: loyaltyProgram.id, name: loyaltyProgram.name }))}
              >
                {t('delete')}
              </Button>
            ) : (
              <Button
                variant="outlined"
                color="error"
                onClick={onDeleteClick && (() => onDeleteClick({ id: loyaltyProgram.id, name: loyaltyProgram.name }))}
              >
                {t('remove')}
              </Button>
            )}
          </>
        </Box>
      ),
      sorting: false,
    },
  ]);
}
