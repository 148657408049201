import { Box, Theme, Typography, alpha } from '@mui/material';
import TelemetryHeaderCard from './TelemetryHeaderCard';
import { useTranslation } from 'react-i18next';
import { SensorWithBooleanTelemetry } from '../typings';
import RenderIf from '@/components/Shared/RenderIf/RenderIf';
import { useMemo } from 'react';
import { meetsExpectation } from '@culligan-iot/domain/culligan/device/capability/telemetry';

export default function TelemetryBooleanCard({
  sensorWithTelemetry,
  double = false,
  group = false,
  title = '',
}: {
  sensorWithTelemetry: SensorWithBooleanTelemetry;
  double?: boolean;
  group?: boolean;
  title?: string;
}) {
  const { t } = useTranslation();
  const { id, sensor, telemetry } = sensorWithTelemetry;
  const telemetryMeetsExpectations = useMemo(
    () => (typeof telemetry?.value === 'undefined' ? true : meetsExpectation(sensor, telemetry)),
    [sensor, telemetry]
  );
  const hasExpectedValue = sensor.expectedValue._tag === 'Boolean';

  const errorGradient = (theme: Theme) =>
    `radial-gradient(85.17% 85.17% at 0% 0%, ${alpha(theme.palette.error.light, 0.2)} 0%, ${alpha(
      theme.palette.background.grayShades[0],
      0.2
    )} 100%), ${theme.palette.background.grayShades[0]}`;
  return (
    <Box
      className="telemetry-card"
      sx={(theme) => ({
        gridColumn: double ? 'span 2' : 'span 1',
        aspectRatio: !double ? '1/1' : 'unset',
        borderRadius: theme.shape.cardBorderRadius,
        padding: 2,
        height: '250px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        background: !telemetryMeetsExpectations ? errorGradient : theme.palette.background.grayShades[0],
      })}
    >
      <TelemetryHeaderCard name={sensor.name.concat('?')} id={id} group={group} tagName={title} />
      <Box display={'flex'} flexDirection={'column'} justifyContent={'flex-end'} flexGrow={1}>
        <Box>
          <Box
            sx={(theme) => ({
              backgroundColor: 'white',
              height: '60px',
              width: '60px',
              borderRadius: '50%',
              margin: '0 auto',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              '&::before': {
                content: '""',
                height: '70px',
                width: '70px',
                borderRadius: '50%',
                backgroundColor: 'transparent',
                transition: 'all 0.3s ease',
                position: 'absolute',
                border: `3px solid ${telemetryMeetsExpectations ? theme.palette.grey[300] : '#ef5350'}`,
              },
            })}
          >
            <Typography
              fontWeight={'bold'}
              {...(!telemetryMeetsExpectations
                ? {
                    sx: {
                      color: (theme) => theme.palette.warning.light,
                      background: (theme) =>
                        `linear-gradient(140deg, ${theme.palette.warning.light} 0%,` +
                        ` ${theme.palette.error.light} 100%);`,
                      backgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                    },
                  }
                : {})}
            >
              {['true', 'false'].includes(String(telemetry?.value))
                ? String(telemetry?.value)?.toUpperCase()
                : t('notAvailable')}
            </Typography>
          </Box>
          <Typography
            variant="caption"
            fontStyle={'italic'}
            display={'block'}
            marginTop={'20px'}
            textAlign={'center'}
            marginBottom={'2px'}
          >
            {/* {t('lastUpdated')}:
            {telemetry?.lastUpdate ? dayjs(telemetry?.lastUpdate).fromNow() : t('notAvailable')} */}
          </Typography>
        </Box>
        <RenderIf condition={hasExpectedValue}>
          <Box
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              backgroundColor: 'white',
              fontSize: '12px',
              borderRadius: theme.shape.cardBorderRadius,
              maxWidth: '80%',
              padding: 1,
              margin: '0 auto',
            })}
          >
            {t('expectedValue')}{' '}
            <Typography fontWeight={'bold'} fontSize="12px" display={'inline'} ml="10px">
              {hasExpectedValue && String(sensor.expectedValue.value)?.toUpperCase()}
            </Typography>
          </Box>
        </RenderIf>
      </Box>
    </Box>
  );
}
