import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import DeviceConsumableCard from './DeviceConsumableCard';
import { Device } from '@culligan-iot/domain/culligan/device/class/index';

const DeviceConsumablesList = ({
  consumables,
  slots,
  isDeviceConnected,
}: {
  consumables: Device['consumables'];
  slots: Device['constructor']['slots'];
  isDeviceConnected: boolean;
}) => {
  const { t } = useTranslation();

  return consumables && consumables.length ? (
    <Box
      display="flex"
      gap="2rem"
      sx={{
        flexWrap: 'wrap',
      }}
    >
      {consumables.map((consumable, index) => {
        return (
          <DeviceConsumableCard
            isDeviceConnected={isDeviceConnected}
            consumable={consumable}
            slot={slots[index]}
            slotCount={index}
            key={consumable?.subset.id}
          />
        );
      })}
    </Box>
  ) : (
    <Typography variant="h6" textAlign={'center'}>
      {t('noConsumablesForDevice')}
    </Typography>
  );
};

export default withErrorLoadingManagement(DeviceConsumablesList);
