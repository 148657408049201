import { formatNumber, NumberFormat } from '@/shared/domain/numbers';
import { useMemo } from 'react';

export default function CulliganNumber({ value, format }: { value: number; format?: NumberFormat }) {
  const _format: NumberFormat = useMemo(() => {
    if (format) {
      return format;
    }
    if (value >= 1000) {
      return 'Scientific';
    }
    if (value >= 100) {
      return 'FloatOneDigit';
    }
    if (value >= 1) {
      return 'FloatTwoDigits';
    }
    if (value < 1) {
      return 'FloatThreeDigits';
    }
    return 'FloatThreeDigits';
  }, [format, value]);

  const displayNumber = useMemo(() => formatNumber(value, _format), [value, _format]);

  return displayNumber;
}
