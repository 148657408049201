import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

export default function ExpirationInfo({ expiresAt, isExpired }: { expiresAt: Date; isExpired: boolean }) {
  const { t } = useTranslation();
  const expirationDate = dayjs(expiresAt).format('DD/MM/YYYY');
  const end = dayjs(expiresAt);
  return (
    <Fragment>
      <Typography variant="body2" color="text.secondary" sx={{ marginBottom: 1 }}>
        {!isExpired ? t('expiresAt') : t('expiredAt')}
      </Typography>
      <Typography variant="h5" color="text.primary" fontWeight={800}>
        {expirationDate}
      </Typography>
      <Typography variant="body2" color="text.primary">
        {!isExpired ? `${t('willExpire')} ${dayjs().to(end)}` : `${t('expired')} ${end.from(dayjs())}`}
      </Typography>
    </Fragment>
  );
}
