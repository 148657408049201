import { Box, Divider, Theme, Typography, alpha } from '@mui/material';
import TelemetryHeaderCard from './TelemetryHeaderCard';
import { useTranslation } from 'react-i18next';
import { Fragment, ReactNode, useMemo } from 'react';
import { SensorWithNumberTelemetry } from '../typings';
import { meetsExpectation } from '@culligan-iot/domain/culligan/device/capability/telemetry';
import { Match } from 'effect';
import { getSymbol, Unit } from '@culligan-iot/domain/unit/index';

function parseNumber(num: number) {
  function _parseNumber(absNum: number, maxAbsValue: number, sign: number) {
    const exceedDomain = absNum > maxAbsValue;
    const decimalsAvailable = maxAbsValue.toString().length - 2 - absNum.toString().split('.')[0].length;
    const num =
      // parse a floating number string to number
      parseFloat(
        // reduce number of decimals
        Math.abs(absNum).toFixed(
          // reduce the number of decimals to
          // "decimalsAvailable" if "decimalsAvailable"
          // greater or equal to 0
          // otherwise reduce the number of decimals to 0
          decimalsAvailable * Number(decimalsAvailable >= 0)
        )
        // keep the parsedFloat number only if it doesn't exceed
        // MAX_VALUE
      ) *
        Number(!exceedDomain) +
      // if num exceed MAX_VALUE then replace it with
      // MAX_VALUE (9_999_999)
      maxAbsValue * Number(exceedDomain);

    return `${sign === 1 && exceedDomain ? '>' : ''}${sign === -1 && exceedDomain ? '<' : ''} ${num * sign}`;
  }

  if (num < 0) {
    const maxAbsValue = 10 ** 6 - 1;
    return _parseNumber(Math.abs(num), maxAbsValue, -1);
  }

  const maxAbsValue = 10 ** 7 - 1;
  return _parseNumber(num, maxAbsValue, 1);
}

export default function TelemetryNumberCard({
  sensorWithTelemetry,
  double = false,
  group = false,
  title = '',
}: {
  sensorWithTelemetry: SensorWithNumberTelemetry;
  double?: boolean;
  group?: boolean;
  title?: string;
}) {
  const { t } = useTranslation();
  const { id, sensor, telemetry } = sensorWithTelemetry;
  const telemetryMeetsExpectations = useMemo(
    () => (typeof telemetry?.value === 'undefined' ? true : meetsExpectation(sensor, telemetry)),
    [sensor, telemetry]
  );
  const expectationSection = useMemo(() => renderExpectedValueSection(sensorWithTelemetry), [sensorWithTelemetry]);

  const errorGradient = (theme: Theme) =>
    `radial-gradient(85.17% 85.17% at 0% 0%, ${alpha(theme.palette.error.light, 0.2)} 0%, ${alpha(
      theme.palette.background.grayShades[0],
      0.2
    )} 100%), ${theme.palette.background.grayShades[0]}`;
  return (
    <Box
      className="telemetry-card"
      sx={(theme) => ({
        gridColumn: double ? 'span 2' : 'span 1',
        aspectRatio: !double ? '1/1' : 'unset',
        padding: 2,
        width: '100%',
        height: '250px',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: theme.shape.cardBorderRadius,
        background: !telemetryMeetsExpectations ? errorGradient : theme.palette.background.grayShades[0],
      })}
    >
      <TelemetryHeaderCard name={sensor.name} id={id} group={group} tagName={title} />

      <Box display={'flex'} flexDirection={'column'} justifyContent={'flex-end'} flexGrow={1}>
        <Box>
          <Box display={'flex'} alignItems={'end'}>
            <Typography
              variant="h3"
              fontWeight={'bold'}
              lineHeight={1}
              {...(!telemetryMeetsExpectations
                ? {
                    sx: {
                      background: (theme) =>
                        `linear-gradient(140deg, ${theme.palette.warning.light} 0%,` +
                        ` ${theme.palette.error.light} 100%);`,
                      backgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                    },
                  }
                : {})}
            >
              {typeof telemetry?.value === 'number' ? parseNumber(telemetry?.value) : t('notAvailable')}
            </Typography>
            <Typography
              variant="body1"
              component="span"
              fontStyle={'italic'}
              fontWeight={'bold'}
              sx={(theme) => ({ color: theme.palette.grey[600] })}
            >
              {telemetry ? t(getSymbol(telemetry.constructor.unit)) : ''}
            </Typography>
          </Box>
        </Box>
        {expectationSection}
      </Box>
    </Box>
  );
}

const renderExpectedValueSection = (sensorWithTelemetry: SensorWithNumberTelemetry) =>
  Match.value(sensorWithTelemetry.sensor.expectedValue).pipe(
    Match.when({ _tag: 'Any' }, () => <Fragment />),
    Match.when({ _tag: 'Range' }, ({ min, max }) => (
      <ExpectedValueContainer>
        <ExpectedMinimumValue min={min} unit={sensorWithTelemetry.sensor.type.unit} />
        <Divider orientation="vertical" flexItem />
        <ExpectedMaximumValue max={max} unit={sensorWithTelemetry.sensor.type.unit} />
      </ExpectedValueContainer>
    )),
    Match.when({ _tag: 'Minimum' }, ({ min }) => (
      <ExpectedValueContainer>
        <ExpectedMinimumValue min={min} unit={sensorWithTelemetry.sensor.type.unit} />
      </ExpectedValueContainer>
    )),
    Match.when({ _tag: 'Maximum' }, ({ max }) => (
      <ExpectedValueContainer>
        <ExpectedMaximumValue max={max} unit={sensorWithTelemetry.sensor.type.unit} />
      </ExpectedValueContainer>
    )),
    Match.exhaustive
  );

const ExpectedValueContainer = (props: { children: ReactNode }) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        backgroundColor: 'white',
        fontSize: '12px',
        borderRadius: theme.shape.cardBorderRadius,
        padding: 1,
      })}
    >
      {props.children}
    </Box>
  );
};

const ExpectedMinimumValue = (props: { min: number; unit: Unit['_tag'] }) => {
  return (
    <Box>
      {'min'}{' '}
      <Typography fontWeight={'bold'} fontSize="12px" display={'inline'}>
        {props.min} {getSymbol(props.unit)}
      </Typography>
    </Box>
  );
};

const ExpectedMaximumValue = (props: { max: number; unit: Unit['_tag'] }) => {
  return (
    <Box>
      {'max'}{' '}
      <Typography fontWeight={'bold'} fontSize="12px" display={'inline'}>
        {props.max} {getSymbol(props.unit)}
      </Typography>
    </Box>
  );
};
