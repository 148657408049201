import LoyaltyProgramTable from '@/components/Fleet/Devices/DevicePanel/OverviewPanel/LoyaltyProgramTable';
import { Box } from '@mui/material';
import { useContext } from 'react';
import { DeviceContext } from './DeviceContext';
import { useGetLoyaltyProgramsQuery } from '@/redux/api/system/loyaltyProgramsApiSlice';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
const SafeLoyaltyProgramsTable = withErrorLoadingManagement(LoyaltyProgramTable);
export default function LoyaltyPrograms() {
  const context = useContext(DeviceContext);
  const { data } = useGetLoyaltyProgramsQuery();
  const deviceLoyaltyPrograms =
    data?._tag === 'Right' && context.data?.loyaltyPrograms?.length
      ? data.right.success
        ? data.right.data.items.filter((program) => context.data?.loyaltyPrograms?.includes(program.id))
        : []
      : [];
  const formattedLoyaltyPrograms = deviceLoyaltyPrograms.map((program) => ({
    ...program,
    createdAt: typeof program.createdAt === 'string' ? new Date(program.createdAt) : program.createdAt,
    updatedAt: typeof program.updatedAt === 'string' ? new Date(program.updatedAt) : program.updatedAt,
  }));

  return (
    <Box>
      <SafeLoyaltyProgramsTable
        isLoading={context.isLoading}
        isError={context.isError}
        error={context.error}
        items={formattedLoyaltyPrograms}
        id={context?.data?.id}
        isFleet={false}
        excludeColumns={['updatedAt', 'createdAt', 'edit', 'delete']}
      />
    </Box>
  );
}
