import AreaContainer, { AreaBody, AreaHeader } from '@/components/Shared/Card/Area';
import { Subtitle, Title } from '@/components/Shared/Card/Area/Text';
import { useTranslation } from 'react-i18next';
import { Device } from '@culligan-iot/domain/culligan/device/class/index';
import dayjs from 'dayjs';

export default function Installation({ consumable }: { consumable: NonNullable<Device['consumables'][number]> }) {
  const { t } = useTranslation();
  return (
    <AreaContainer>
      <AreaHeader>
        <Subtitle>{t('installedOn')}</Subtitle>
      </AreaHeader>
      <AreaBody>
        <Title>{dayjs(consumable.state.installedAt).format('DD/MM/YYYY')}</Title>
      </AreaBody>
    </AreaContainer>
  );
}
