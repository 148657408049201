import { REQUESTED_LOCATION } from '@/shared/constants';
import { datadogRum } from '@datadog/browser-rum';
import { Either, identity, ParseResult, pipe } from 'effect';
import { ParseError } from 'effect/ParseResult';
import i18next from 'i18next';

export const getNumberTh = (number: number) => {
  if (number === 1) {
    return i18next.t('first');
  }
  if (number === 2) {
    return i18next.t('second');
  }
  if (number === 3) {
    return i18next.t('third');
  }
  return i18next.t('numberTh', { number });
};

export const setRequestedLocation = (location: Location) => {
  localStorage.setItem(REQUESTED_LOCATION, location.href);
};

export const getRequestedLocation = () => localStorage.getItem(REQUESTED_LOCATION);

export const removeRequestLocation = () => localStorage.removeItem(REQUESTED_LOCATION);

export const handleDecodeUnknownEither = <T>(result: Either.Either<T, ParseError>) =>
  pipe(
    result,
    Either.match({
      onLeft: handleDecodeError,
      onRight: identity,
    })
  );

const handleDecodeError = (error: ParseError) =>
  pipe(error, ParseResult.TreeFormatter.formatErrorSync, makeError, logDatadogError, throwError);

const logDatadogError = (error: Error) => {
  datadogRum.addError(error);
  return error;
};

const makeError = (message: string) => new Error(message);

const throwError = (error: Error) => {
  throw error;
};
