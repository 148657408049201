import dayjs from 'dayjs';
import { HistoryTelemetry, MergedTelemetry } from '../../typings';
import { Telemetry } from '@culligan-iot/domain/culligan/device/capability/telemetry';
import { Record } from 'effect';

export const withWiderType = (
  items: HistoryTelemetry[]
): Array<{ [key: string]: number | { value: Telemetry['value'] }; lastUpdate: number }> =>
  items as Array<{ [key: string]: number | { value: Telemetry['value'] }; lastUpdate: number }>;
export const removeBooleansAndStringsTelemetry = (items: readonly HistoryTelemetry[]) => {
  return items
    .map((item) => {
      const numericItem: { [key: string]: number | { value: Telemetry['value'] } } = { lastUpdate: item.lastUpdate };

      Record.toEntries(item).forEach(([key, value]) => {
        if (typeof value === 'number' && key !== 'lastUpdate') {
          numericItem[key] = value;
        }
        if (value && typeof value !== 'number' && 'value' in value && typeof value.value === 'number') {
          numericItem[key] = value;
        }
      });

      return numericItem;
    })
    .filter((item) => Object.keys(item).length > 1);
};

export const groupItemsByDay = (
  deviceTelemetries: MergedTelemetry[] | undefined,
  items: readonly HistoryTelemetry[] | undefined
) => {
  const keyNameMap = deviceTelemetries
    ?.flatMap((telemetry) => {
      if (telemetry.type === 'group' && telemetry.items) {
        // Mappatura per gli items di tipo 'group'
        return telemetry.items.map((item) => ({ [item.id]: item.name }));
      } else if (telemetry.type === 'single') {
        // Mappatura per gli items di tipo 'single'
        return { [telemetry.id]: telemetry.name };
      }
      return undefined;
    })
    .filter((item) => item)
    .reduce((acc, cur) => ({ ...acc, ...cur }), {});

  const itemsWithNamesAndValues =
    items &&
    items.map((item) => {
      return Object.keys(item).reduce<HistoryTelemetry>((acc, key) => {
        const name = keyNameMap?.[key] || key;
        acc[name] = item[key];
        return acc;
      }, {} as HistoryTelemetry);
    });

  const res = itemsWithNamesAndValues?.reduce((acc, historyTelemetry) => {
    const day = dayjs(historyTelemetry.lastUpdate).startOf('d').valueOf();
    acc.set(day, [...(acc.get(day) || []), historyTelemetry]);

    return acc;
  }, new Map<number, HistoryTelemetry[]>());

  if (res) {
    return [...res.entries()];
  }
  return [];
};
